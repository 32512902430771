import type {
    MediaDeviceRequest,
    MediaDeviceInfoLike,
    InputConstraintSet,
} from '@pexip/media-control';
import {
    getUserMedia,
    hasChangedInput,
    isMediaDeviceInfo,
} from '@pexip/media-control';
import type {AsyncQueueOptions} from '@pexip/utils';
import {createAsyncQueue} from '@pexip/utils';

import type {
    Media,
    Pipeline,
    Unsubscribe,
    MediaSignals,
    ExtendedMediaTrackSettings,
    ExtendedMediaTrackSettingsKey,
    MediaProcessor,
} from './types';
import {UserMediaStatus} from './types';
import {
    buildMedia,
    cloneMedia,
    createMediaPipeline,
    hasSettingsChanged,
    AUDIO_SETTINGS_KEYS,
    VIDEO_SETTINGS_KEYS,
} from './utils';
import {isMedia} from './typeGuard';
import {createModuleLogger} from './logger';
import {isInitial} from './status';
import type {GetUserMedia} from './userMedia';
import {createGetUserMediaProcess} from './userMedia';

const DEFAULT_QUEUE_SIZE = 1;
const DEFAULT_QUEUE_THROTTLE_MS = 500;
const DEFAULT_QUEUE_DELAY_MS = 100;
const DEFAULT_QUEUE_DROP_LAST = false;

type EventCallback<T> = (event: T) => void;
type EventErrorCallback = (error: Error) => void;
export type PreviewInput = MediaDeviceInfoLike | undefined;
export interface PreviewEventHandler {
    audioInput?: EventCallback<PreviewInput>;
    videoInput?: EventCallback<PreviewInput>;
    media?: EventCallback<Media>;

    videoInputError?: EventErrorCallback;
    audioInputError?: EventErrorCallback;
    applyChangesError?: EventErrorCallback;
    revertChangesError?: EventErrorCallback;

    updatingPreview?: EventCallback<boolean>;
    updatingMain?: EventCallback<boolean>;

    unsubscribeMain?: Unsubscribe;
}

export interface PreviewStreamParams {
    getCurrentDevices: () => MediaDeviceInfoLike[];
    getCurrentMedia: () => Media | undefined;
    updateMainStream: (request: MediaDeviceRequest) => Promise<void>;
    mediaSignal: MediaSignals['onMediaChanged'];
    onEnded?: () => void;
    fftSize?: number;
    queueOptions?: Partial<AsyncQueueOptions>;
    processors: MediaProcessor[];
}

export interface PreviewControllerProps {
    media: Media;
    audioInput?: MediaDeviceInfoLike;
    videoInput?: MediaDeviceInfoLike;
    updatingPreview: boolean;
    updatingMain: boolean;
    originalMainAudioInput?: MediaDeviceInfoLike;
    discardMedia: boolean;
    initialized: boolean;
}

export interface PreviewStreamController {
    // Props
    media: Media;
    audioInputChanged: boolean;
    videoInputChanged: boolean;
    inputChanged: boolean;
    audioInput: PreviewInput;
    videoInput: PreviewInput;
    updatingPreview: boolean;
    updatingMain: boolean;

    // Methods
    updateAudioInput(input: PreviewInput): void;
    updateVideoInput(input: PreviewInput): void;
    applyChanges(force?: boolean): Promise<void>;
    revertChanges(): Promise<void>;

    // Events
    onMediaChanged(callback: EventCallback<Media>): Unsubscribe;
    onAudioInputChanged(callback: EventCallback<PreviewInput>): Unsubscribe;
    onVideoInputChanged(callback: EventCallback<PreviewInput>): Unsubscribe;
    onUpdatingPreview(callback: EventCallback<boolean>): Unsubscribe;
    onUpdatingMain(callback: EventCallback<boolean>): Unsubscribe;
    onAudioInputError(callback: EventErrorCallback): Unsubscribe;
    onVideoInputError(callback: EventErrorCallback): Unsubscribe;
    onApplyChangesError(callback: EventErrorCallback): Unsubscribe;
    onRevertChangesError(callback: EventErrorCallback): Unsubscribe;
}

const isPreviewInput = (value: unknown): value is PreviewInput => {
    if (isMediaDeviceInfo(value) || value === undefined) {
        return true;
    }
    return false;
};

const extractFeaturesToConstraints = (
    keysToLookFor: ExtendedMediaTrackSettingsKey[],
    settings: ExtendedMediaTrackSettings | undefined,
) => {
    if (!settings) {
        return {};
    }
    return keysToLookFor.reduce((set, key) => {
        if (settings[key] !== undefined) {
            return {...set, [key]: settings[key]};
        }
        return set;
    }, {} as InputConstraintSet);
};

const createEventHandler =
    (eventHandlers: PreviewEventHandler) =>
    <T extends keyof PreviewEventHandler>(key: T) =>
    (callback: PreviewEventHandler[T]): Unsubscribe => {
        eventHandlers[key] = callback;
        return () => {
            eventHandlers[key] = undefined;
        };
    };

const getUM: GetUserMedia = async constraints => [
    await getUserMedia(constraints),
    UserMediaStatus.PermissionsGranted,
];

type GetMedia<T = Media> = () => T;
const createAudioVideoProcessingSettingsChangeDetector = (
    getCurrentMedia: GetMedia<Media | undefined>,
    getPreviewMedia: GetMedia,
) => {
    const hasAudioSettingsChanged = hasSettingsChanged(AUDIO_SETTINGS_KEYS);
    const hasVideoSettingsChanged = hasSettingsChanged(VIDEO_SETTINGS_KEYS);
    return () => {
        const currentMedia = getCurrentMedia?.();
        const previewMedia = getPreviewMedia();

        const {
            audio: [mainAudio],
            video: [mainVideo],
        } = currentMedia?.getSettings() ?? {audio: [], video: []};
        const {
            audio: [previewAudio],
            video: [previewVideo],
        } = previewMedia.getSettings();
        const changed =
            hasAudioSettingsChanged(mainAudio, previewAudio) ||
            hasVideoSettingsChanged(mainVideo, previewVideo);
        return changed;
    };
};

export const createPreviewStreamController = ({
    getCurrentDevices,
    getCurrentMedia,
    updateMainStream,
    onEnded,
    mediaSignal,
    queueOptions = {
        size: DEFAULT_QUEUE_SIZE,
        throttleInMS: DEFAULT_QUEUE_THROTTLE_MS,
        delayInMS: DEFAULT_QUEUE_DELAY_MS,
        dropLast: DEFAULT_QUEUE_DROP_LAST,
    },
    processors,
}: PreviewStreamParams): PreviewStreamController => {
    const queue = createAsyncQueue(queueOptions);
    const eventHandlers: PreviewEventHandler = {};
    const release = () => {
        if (isInitial(props.media.status)) {
            props.discardMedia = true;
        }
        props.media = buildMedia(() => ({release}));
        return Promise.resolve();
    };
    const internalProps: PreviewControllerProps = {
        media: buildMedia(() => ({release})),
        updatingPreview: false,
        updatingMain: false,
        discardMedia: false,
        initialized: false,
    };

    const logger = createModuleLogger({
        module: 'PreviewStreamController',
        eventHandlers,
        props: internalProps,
    });

    const cleanUpMainSubscription = () => {
        if (eventHandlers.unsubscribeMain) {
            eventHandlers.unsubscribeMain();
            eventHandlers.unsubscribeMain = undefined;
        }
    };

    type InternalProps = typeof internalProps;
    const props = new Proxy(internalProps, {
        get: (target, p: keyof InternalProps) => {
            return target[p];
        },
        set: (target, p: keyof InternalProps, value) => {
            if (target[p] === value) {
                return true;
            }
            logger.debug(
                {oldValue: target[p], newValue: value as unknown},
                `Update Props[${p}]`,
            );
            switch (p) {
                case 'media': {
                    if (!isMedia(value)) {
                        return false;
                    }
                    target[p] = value;
                    eventHandlers[p]?.(value);
                    // Unsubscribe main stream signal on change of inputs
                    cleanUpMainSubscription();
                    return true;
                }
                case 'audioInput':
                case 'videoInput': {
                    if (!isPreviewInput(value)) {
                        return false;
                    }
                    target[p] = value;
                    eventHandlers[p]?.(value);
                    return true;
                }
                case 'updatingMain':
                case 'updatingPreview': {
                    if (typeof value !== 'boolean') {
                        return false;
                    }
                    target[p] = value;
                    eventHandlers[p]?.(value);
                    return true;
                }
                default:
                    Reflect.set(target, p, value);
                    return true;
            }
        },
    });

    const postMediaPipeline = createMediaPipeline<Promise<Media>>(
        // Ignore the type conversion between tuple and array
        processors as Pipeline<Promise<Media>>,
    );

    const initFromMain = (mainMedia: Media) => {
        if (!mainMedia.stream) {
            return;
        }
        postMediaPipeline
            .execute(cloneMedia(mainMedia))
            .then(media => {
                props.media = media;
            })
            .catch((error: unknown) => {
                logger.error({error}, 'Failed to post process media');
            })
            .finally(() => {
                props.initialized = true;
                if (props.discardMedia) {
                    void props.media.release();
                }
            });
        props.audioInput = mainMedia.audioInput;
        props.videoInput = mainMedia.videoInput;
        props.originalMainAudioInput = mainMedia.audioInput;
    };

    const mainMedia = getCurrentMedia();
    if (mainMedia?.stream) {
        initFromMain(mainMedia);
    } else {
        eventHandlers.unsubscribeMain = mediaSignal.add(initFromMain);
    }

    const replaceMainStream = async (constraints: MediaDeviceRequest) => {
        logger.debug({constraints}, 'Replacing main stream');
        props.updatingMain = true;
        await updateMainStream(constraints);
        props.updatingMain = false;
    };
    const mediaPipeline = createMediaPipeline(() => [
        createGetUserMediaProcess(getUM, getCurrentDevices, {
            initialMedia: props.media,
            scope: 'PreviewStreamController',
        }),
        ...processors,
    ]);

    const updatePreviewMedia = async (constraints: MediaDeviceRequest) => {
        logger.debug({constraints}, 'Requesting a new preview stream');
        await props.media?.release();
        const media = await mediaPipeline.execute(constraints);
        if (props.discardMedia) {
            logger.debug('Discard media');
            return await media.release();
        }
        props.media = media;
    };

    const updateAudioInput = async (input: PreviewInput) => {
        props.audioInput = input;
        const request = {
            audio: {device: {exact: input}},
            video: props.videoInput,
        };
        try {
            props.updatingPreview = true;
            await updatePreviewMedia(request);
        } catch (error: unknown) {
            if (error instanceof Error) {
                const errorName =
                    error.name === 'Error' ? error.message : error.name;
                if (errorName === 'NotReadableError') {
                    try {
                        logger.debug(
                            {input},
                            'NotReadableError, trying to sync main stream AudioInput',
                        );
                        await replaceMainStream({
                            audio: input,
                            video: getCurrentMedia()?.videoInput,
                        });
                        await updatePreviewMedia(request);
                    } catch (err: unknown) {
                        logger.warn(
                            {error: err, input},
                            'Unable to recover NotReadableError AudioInput for preview',
                        );
                        if (err instanceof Error) {
                            return eventHandlers.audioInputError?.(err);
                        }
                        throw err;
                    }
                } else {
                    logger.warn(
                        {error, input},
                        'Unable to update AudioInput for preview',
                    );
                    eventHandlers.audioInputError?.(error);
                }
            } else {
                logger.error(
                    {error, input},
                    'Unable to update AudioInput for preview',
                );
                throw error;
            }
        } finally {
            props.updatingPreview = false;
        }
    };

    const updateVideoInput = async (input: PreviewInput) => {
        props.videoInput = input;
        try {
            props.updatingPreview = true;
            await updatePreviewMedia({
                audio: props.audioInput,
                video: {device: {exact: input}},
            });
        } catch (error: unknown) {
            if (error instanceof Error) {
                logger.warn(
                    {error, input},
                    'Unable to update VideoInput for preview',
                );
                return eventHandlers.videoInputError?.(error);
            }
            throw error;
        } finally {
            props.updatingPreview = false;
        }
    };

    const cleanup = () => {
        logger.debug('Cleanup preview controller');
        props.audioInput = undefined;
        props.videoInput = undefined;
        onEnded?.();
    };

    const hasChangedAudioInput = () =>
        hasChangedInput(props.originalMainAudioInput, props.audioInput);

    const hasChangedVideoInput = () =>
        hasChangedInput(getCurrentMedia()?.videoInput, props.videoInput);

    const hasAudioVideoProcessingSettingsChanged =
        createAudioVideoProcessingSettingsChangeDetector(
            getCurrentMedia,
            () => props.media,
        );

    const hasChanges = () =>
        hasChangedAudioInput() ||
        hasChangedVideoInput() ||
        hasAudioVideoProcessingSettingsChanged();

    const applyChanges = async (force = false) => {
        const {audioInput, videoInput} = props;
        const {
            audio: [previewAudio],
            video: [previewVideo],
        } = props.media.getSettings();
        await props.media.release();
        cleanUpMainSubscription();

        if (force || hasChanges()) {
            const audioSettings = extractFeaturesToConstraints(
                AUDIO_SETTINGS_KEYS,
                previewAudio,
            );
            const videoSettings = extractFeaturesToConstraints(
                VIDEO_SETTINGS_KEYS,
                previewVideo,
            );
            // Omit the width & height settings from the preview so that the one
            // from the main can be applied
            delete videoSettings.width;
            delete videoSettings.height;
            logger.info(
                {audioInput, videoInput, audioSettings, videoSettings},
                'Apply changes to main',
            );
            try {
                await replaceMainStream({
                    audio: {device: audioInput, ...audioSettings},
                    video: {device: videoInput, ...videoSettings},
                });
                props.originalMainAudioInput = props.audioInput;
            } catch (error: unknown) {
                if (error instanceof Error) {
                    logger.warn({error}, 'Unable to apply changes to main');
                    return eventHandlers.applyChangesError?.(error);
                }
                throw error;
            }
        }

        cleanup();
    };

    const revertChanges = async () => {
        await props.media.release();
        cleanUpMainSubscription();

        const mainMedia = getCurrentMedia();
        const mainStream = mainMedia?.stream;
        if (mainMedia && mainStream) {
            // Workaround for iOS Safari bug
            // https://bugs.webkit.org/show_bug.cgi?id=179363
            const mainMuted = !!mainStream.getTracks().some(t => t.muted);
            const current = mainMedia.audioInput;
            const prev = props.originalMainAudioInput;
            const audioChanged = hasChangedInput(prev, current);

            if (mainMuted || audioChanged) {
                logger.info(
                    {
                        mainMuted,
                        audioChanged,
                        currentAudioInput: current,
                        prevAudioInput: prev,
                        mainMedia,
                    },
                    'Reverting Changes to main',
                );
                try {
                    await replaceMainStream({
                        audio: prev,
                        video: getCurrentMedia()?.videoInput,
                    });
                } catch (error: unknown) {
                    if (error instanceof Error) {
                        logger.warn(
                            {error},
                            'Unable to revert changes to main',
                        );
                        return eventHandlers.revertChangesError?.(error);
                    }
                    throw error;
                }
            }
        }

        cleanup();
    };

    const updateInput =
        (
            hasChanged: (input: PreviewInput) => boolean,
            updater: (input: PreviewInput) => Promise<void>,
        ) =>
        (input: PreviewInput) => {
            if (hasChanged(input)) {
                queue.enqueue(async () => {
                    await updater(input);
                });
            }
        };

    const toEvenHandler = createEventHandler(eventHandlers);

    return {
        get media() {
            return props.media;
        },

        get audioInputChanged() {
            return hasChangedAudioInput();
        },

        get videoInputChanged() {
            return hasChangedVideoInput();
        },

        get inputChanged() {
            return hasChanges();
        },

        get audioInput() {
            return props.audioInput;
        },

        get videoInput() {
            return props.videoInput;
        },

        get updatingPreview() {
            return props.updatingPreview;
        },

        get updatingMain() {
            return props.updatingMain;
        },

        updateAudioInput: updateInput(
            input =>
                props.initialized && hasChangedInput(props.audioInput, input),
            updateAudioInput,
        ),

        updateVideoInput: updateInput(
            input =>
                props.initialized && hasChangedInput(props.videoInput, input),
            updateVideoInput,
        ),

        onMediaChanged: toEvenHandler('media'),
        onAudioInputChanged: toEvenHandler('audioInput'),
        onVideoInputChanged: toEvenHandler('videoInput'),
        onAudioInputError: toEvenHandler('audioInputError'),
        onVideoInputError: toEvenHandler('videoInputError'),
        onApplyChangesError: toEvenHandler('applyChangesError'),
        onRevertChangesError: toEvenHandler('revertChangesError'),
        onUpdatingPreview: toEvenHandler('updatingPreview'),
        onUpdatingMain: toEvenHandler('updatingMain'),

        applyChanges,
        revertChanges,
    };
};

export type CreatePreviewStreamController =
    typeof createPreviewStreamController;
