import React, {useCallback, useState} from 'react';
import cx from 'classnames';
import {t} from 'i18next';

import {
    Button,
    FontVariant,
    Icon,
    IconTypes,
    Input,
    Row,
    Text,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './BreakoutRoomHeader.module.scss';

const ENTER = 'Enter';
const ESCAPE = 'Escape';

export const BreakoutRoomHeader: React.FC<
    React.ComponentProps<'div'> & {
        name: string;
        showEditButton: boolean;
        onSaveAction?: (name: string) => boolean | undefined;
    }
> = ({name, showEditButton, onSaveAction, className}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(name);

    const onEdit = useCallback(() => {
        setInputValue(name);
        setIsEditing(true);
    }, [name]);

    const onSave = useCallback(() => {
        const trimmedInput = inputValue.trim();
        if (trimmedInput === name) {
            setIsEditing(false);
            return;
        }
        const isSuccess = onSaveAction?.(trimmedInput);
        if (isSuccess) {
            setIsEditing(false);
        }
    }, [inputValue, name, onSaveAction]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === ENTER) {
                onSave();
            }
            if (event.key === ESCAPE) {
                setIsEditing(false);
            }
        },
        [onSave],
    );

    return (
        <Row className={cx(styles.wrapper, className)} spacing="none">
            {isEditing ? (
                <Input
                    autoComplete="off"
                    ref={ref => ref?.focus()}
                    className={styles.input}
                    value={inputValue}
                    label={t(
                        'breakouts.breakout-room.name-label',
                        'Breakout room name',
                    )}
                    labelModifier="hidden"
                    name={'inputValue'}
                    onKeyDown={handleKeyDown}
                    onBlur={onSave}
                    onValueChange={setInputValue}
                    maxLength={36}
                ></Input>
            ) : (
                <Text
                    isTruncated
                    fontVariant={FontVariant.H5}
                    className={styles.text}
                >
                    {name}
                </Text>
            )}
            {showEditButton && !isEditing && (
                <Button
                    data-testid={TestId.ButtonBreakoutRoomHeader}
                    className={styles.editButton}
                    aria-label={t(
                        'breakouts.breakout-room.edit-button-label',
                        'Edit breakout room name',
                    )}
                    onClick={onEdit}
                    size="small"
                    variant="translucent"
                    modifier="square"
                >
                    <Icon source={IconTypes.IconEdit} size="small" />
                </Button>
            )}
        </Row>
    );
};

export type BreakoutRoomHeaderProps = React.ComponentProps<
    typeof BreakoutRoomHeader
>;
