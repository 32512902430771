import {useSyncExternalStore} from 'react';

import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {infinityService} from '../services/InfinityClient.service';

export const useMe = () =>
    useSyncExternalStore(
        infinityClientSignals.onMe.add,
        () => infinityService.me,
    );
