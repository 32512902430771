import React, {useCallback} from 'react';

import {withColorScheme} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {AnalyzerNodeInit} from '@pexip/media-processor';

import type {PreviewControllerHandler} from '../hooks/usePreviewControllerHandler';
import {DevicesList} from '../views/DevicesList/DevicesList.view';
import {MediaControlSettings} from '../views/MediaControlSettings/MediaControlSettings.view';
import {TestId} from '../../test/testIds';
import type {Preview} from '../hooks/usePreview';
import type {PreviewMediaInput, StreamQuality} from '../types';

import {AudioMeter} from './AudioMeter.viewModel';

const SecondaryAudioMeter = withColorScheme(AudioMeter, 'light');

export const MediaControlHandler: React.FC<
    React.PropsWithChildren<{
        audioInput: PreviewMediaInput;
        audioOutput: Preview<MediaDeviceInfoLike | undefined>;
        closeModal: () => void;
        denoise: Preview<boolean>;
        preferPresInMix: Preview<boolean>;
        prioritizeSharingMotion: Preview<boolean>;
        fecc: Preview<boolean>;
        feccSupported: boolean;
        devices: MediaDeviceInfoLike[];
        previewController: PreviewControllerHandler;
        streamQuality: Preview<StreamQuality>;
        videoInput: PreviewMediaInput;
        audioAnalyzer?: AnalyzerNodeInit;
    }>
> = ({
    audioInput,
    audioOutput,
    closeModal,
    children: outputAudioTester,
    denoise,
    preferPresInMix,
    prioritizeSharingMotion,
    fecc,
    feccSupported,
    devices,
    previewController,
    streamQuality,
    videoInput,
    audioAnalyzer,
}) => {
    const handleDenoise = useCallback(() => {
        denoise.setPreview(previewDenoise => !previewDenoise);
    }, [denoise]);

    const handleChangeOfSharingPriority = useCallback(() => {
        prioritizeSharingMotion.setPreview(previewDenoise => !previewDenoise);
    }, [prioritizeSharingMotion]);

    const handleFecc = useCallback(() => {
        fecc.setPreview(prevFecc => !prevFecc);
    }, [fecc]);

    const handlePreferPresInMix = useCallback(() => {
        preferPresInMix.setPreview(prevPreferPresInMix => !prevPreferPresInMix);
    }, [preferPresInMix]);

    const requestedAudio = Boolean(
        previewController.previewMedia.constraints?.audio,
    );

    const requestedVideo = Boolean(
        previewController.previewMedia.constraints?.video,
    );

    const deviceList = (
        <DevicesList
            devices={devices}
            requestedAudio={requestedAudio}
            requestedVideo={requestedVideo}
            videoInputError={videoInput.error}
            audioInputError={audioInput.error}
            videoInput={videoInput.preview}
            audioInput={audioInput.preview}
            audioOutput={audioOutput.preview}
            onAudioInputChange={audioInput.setPreview}
            onAudioOutputChange={audioOutput.setPreview}
            onVideoInputChange={videoInput.setPreview}
            isLoading={previewController.isSaving}
        />
    );

    const audioMeter = !!previewController.previewMedia?.audioInput &&
        !!audioAnalyzer && (
            <SecondaryAudioMeter
                analyzer={audioAnalyzer}
                width={155}
                data-testid={TestId.AudioMeterSettings}
            />
        );

    return (
        <MediaControlSettings
            inputAudioTester={audioMeter}
            allowToSave={
                !previewController.isSaving &&
                /*
                 * Respective device selection is disabled when requestedVideo or requestedAudio
                 * is false, this should not hinder the user from saving other changes.
                 */
                (!videoInput.error.title || !requestedVideo) &&
                (!audioInput.error.title || !requestedAudio)
            }
            deviceList={deviceList}
            outputAudioTester={outputAudioTester}
            handleCancel={closeModal}
            handleNoiseSuppression={handleDenoise}
            handleFecc={handleFecc}
            handlePreferPresInMix={handlePreferPresInMix}
            handleSave={previewController.handleSave}
            isSaving={
                previewController.isSaving || previewController.updatingPreview
            }
            noiseSuppression={denoise.preview}
            handleChangeOfSharingPriority={handleChangeOfSharingPriority}
            preferPresInMix={preferPresInMix.preview}
            prioritizeSharingMotion={prioritizeSharingMotion.preview}
            fecc={fecc.preview}
            feccSupported={feccSupported}
            previewStream={previewController.previewMedia?.stream}
            previewStreamQuality={streamQuality.preview}
            setPreviewStreamQuality={streamQuality.setPreview}
        />
    );
};
