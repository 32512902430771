import type {ReactElement} from 'react';

import type {DeniedDevices} from '@pexip/media';
import type {AutoHideUIInterfacesState} from '@pexip/hooks';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {
    InboundAudioMetrics,
    InboundVideoMetrics,
    OutboundAudioMetrics,
    OutboundVideoMetrics,
} from '@pexip/peer-connection-stats';

import type {PanelAnimationTypes} from './utils/meetingPanels';

export type DeviceError = {
    title: string;
    description?: string | ReactElement;
    deniedDevice?: DeniedDevices;
};

export type DeniedDevice = 'audio' | 'video' | 'audio-video';

export type DeviceErrors = {videoError: DeviceError; audioError: DeviceError};

export interface DeviceStatusInfo {
    controls?: 'media' | 'permissions' | 'permissions-dismiss';
    error?: DeniedDevice;
    message: string | ReactElement;
    title: string;
    warning?: 'audio' | 'video' | 'audio-video';
    distinctiveTitles?: DeviceErrors;
}

export type AudioMeterState = {level: number; peak: number; enabled?: boolean};

export type AutoHideButtonCallbacks = {
    onMouseEnter: () => void;
    onFocus: () => void;
    onMouseLeave: () => void;
    onBlur: () => void;
};

export enum PreflightJoinDetailsToRender {
    BlockedDeviceSelection = 'blocked-device-selection',
    DeviceSelection = 'device-selection',
    Spinner = 'spinner',
}

export enum BlockedBrowserPermissionsInfoType {
    VOID,
    VIDEO,
    RELOAD,
    LINK,
    COMPLETE,
}

export type MeetingPanelType =
    | 'participants'
    | 'chat'
    | 'add'
    | 'breakoutRooms';

export type MeetingPanelsState = {
    openBreakoutRoomsPanel: boolean;
    openChatPanel: boolean;
    openParticipantPanel: boolean;
    openAddParticipant: boolean;
    animationType: PanelAnimationTypes;
};

export type InMeetingUIState = {
    unreadChatMessages: boolean;
    focusedButton: boolean;
    openedInviteModal: boolean;
    openedMenuModal: boolean;
    openedPanel: boolean;
    openedSettingModal: boolean;
    openedUserMenu: boolean;
};

export enum InMeetingUIElements {
    unreadChatMessages = 'unreadChatMessages',
    focusedButton = 'focusedButton',
    inviteModal = 'openedInviteModal',
    openedPanel = 'openedPanel',
    settingModal = 'openedSettingModal',
    userMenu = 'openedUserMenu',
    userMenuModal = 'openedMenuModal',
    statsModal = 'openedStatsModal',
}

export type InMeetingUIStateAPI = {
    setMeetingUIState: (next: InMeetingUIState) => void;
    readonly currentState: InMeetingUIState;
    shouldEnableAutoHide: () => boolean;
    readonly uiVisibilityState: AutoHideUIInterfacesState;
    setUIVisibilityState: (uiState: AutoHideUIInterfacesState) => void;
};

export interface InMeetingUI {
    autoHideProps: AutoHideButtonCallbacks;
    panelsState: MeetingPanelsState;
    showSidePanel: boolean;
    togglePanels: (panel: MeetingPanelType) => void;
    isAutoHideUIVisible: boolean;
}

export enum PresentationType {
    Images = 'images',
    Video = 'video',
}

export enum PresentationEmphasis {
    Primary = 'primary',
    Secondary = 'secondary',
}

export enum PresentationSize {
    Large = 'large',
    Full = 'full',
}

export enum PresentationAction {
    Start = 'start',
    Reset = 'reset',
    Receive = 'receive',
    SetLocalMediaStream = 'setLocalMediaStream',
    SetRemoteMediaStream = 'setRemoteMediaStream',
    SetEmphasis = 'setEmphasis',
    SetExpandPrimary = 'setExpandPrimary',
    SetShowSteal = 'setShowSteal',
    SetSize = 'setSize',
    SetPresenterName = 'setPresenterName',
    ActivityChange = 'ActivityChange',
}

export type PresentationEvent =
    | {type: PresentationAction.SetLocalMediaStream; mediaStream?: MediaStream}
    | {type: PresentationAction.SetRemoteMediaStream; mediaStream?: MediaStream}
    | {type: PresentationAction.SetEmphasis; emphasis: PresentationEmphasis}
    | {type: PresentationAction.SetExpandPrimary; expandPrimary: boolean}
    | {type: PresentationAction.SetShowSteal; showSteal: boolean}
    | {type: PresentationAction.SetSize; size: PresentationSize}
    | {
          type: PresentationAction.SetPresenterName;
          presenterName?: string;
      }
    | {
          type: PresentationAction.ActivityChange;
          activity: PresentationActivityState;
      };

export interface PresentationActivityState {
    send: boolean;
    recv: boolean;
}

export interface PresentationState {
    emphasis?: PresentationEmphasis;
    expandPrimary?: boolean;
    showSteal?: boolean;
    size?: PresentationSize;
    localMediaStream?: MediaStream;
    remoteMediaStream?: MediaStream;
    activity?: PresentationActivityState;
    presenterName?: string;
}

export enum StreamStatus {
    /* BrowserPip - stream is currently within the browser picture-in-picture window */
    BrowserPip = 'BrowserPip',

    /* Emphasized - stream fills as much of the stage as possible */
    Emphasized = 'Emphasized',

    /* External - equivalent to being popped out in a separate window */
    /* note: currently only used by the presentation stream */
    /* note: perhaps utilized in the future by the meeting mix stream */
    External = 'External',

    /* Deemphasized - stream fills any leftover space on the stage */
    Deemphasized = 'Deemphasized',

    /* Expanded - stream is maximized to fill the entire stage */
    Expanded = 'Expanded',

    /* Pip - stream is minimized to occupy a small pip */
    Pip = 'Pip',

    /* None - stream currently has no significant status to report */
    None = 'None',
}

/**
 * indicate call mute state (not to be confused with `member` mute state)
 */
export enum MuteState {
    /**
     * 🔴
     *
     * Participant is muted by the server. They cannot toggle their mute state
     */
    Disabled = 'Disabled',
    /**
     * 🟡
     *
     * Participant is muted and can toggle mute state
     */
    Muted = 'Muted',
    /**
     * 🟢
     *
     * Participant can be muted and can toggle mute state
     */
    Unmuted = 'Unmuted',
}

export type ChatActivityType = 'joined' | 'left' | 'removed' | 'empty-chat';

export type ChatActivityMessageParticipants = {
    identity?: string;
    displayName: string;
    imageUrl?: string;
    timestamp?: string;
};

export type RemovedParticipant = ChatActivityMessageParticipants;

export interface ChatMessage {
    id?: string;
    at?: Date;
    userId?: string;
    displayName: string;
    message: string;
    timestamp?: string;
    topic?: string;
    identity?: string;
    type: 'user-message' | 'activity';
    activityType?: ChatActivityType;
    groupedParticipants?: ChatActivityMessageParticipants[];
    removedParticipant?: RemovedParticipant;
    ref?: string; // From ChatEvent
    pending?: boolean; // Or should we store this in type?
}

export type MeetingParticipantType =
    | 'raise-hand'
    | 'invited'
    | 'admit'
    | 'in-the-meeting'
    | 'external';

export interface MeetingParticipant {
    active: boolean;
    identity: string;
    topic?: string;
    participantType?: MeetingParticipantType;
}

export type CallDisplayName = {
    name: string;
    key: string;
    count?: number;
    isMe?: boolean;
};

export type ParticipantCall = {
    audio: boolean;
    callId: string;
    callDisplayName?: CallDisplayName;
    identity: string;
    identitySession: string;
    presentation: boolean;
    isSpeaking?: boolean;
    isPresenting: boolean;
    video: boolean;
};

export interface InMeetingParticipant extends MeetingParticipant {
    displayName?: string;
    canChangeLayout?: boolean;
    canDisconnect?: boolean;
    canMute?: boolean;
    canTransfer?: boolean;
    canFecc?: boolean;
    canRaiseHand?: boolean;
    canSpotlight?: boolean;
    calls?: ParticipantCall[];
    imageUrl?: string;
    isConnecting?: boolean;
    isExternal?: boolean;
    isIdpAuthenticated?: boolean;
    isHost?: boolean;
    isMuted: boolean;
    isCameraMuted: boolean;
    isEndpoint?: boolean;
    isPresenting?: boolean;
    isSpotlight?: boolean;
    kick?: () => void;
    mute?: () => void;
    muteVideo?: () => void;
    admit?: () => void;
    spotlight?: () => void;
    setRole?: () => void;
    lowerHand?: () => void;
    raisedHand?: boolean;
    startAt?: Date;
    handRaisedTime?: number;
}

export enum StreamQuality {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
    VeryHigh = 'very-high',
    Auto = 'auto',
}

export enum VideoLayoutOptionValues {
    AdaptiveComposition = 'ac',
    Speaker7 = '1:7',
    Speaker21 = '1:21',
    Speaker221 = '2:21',
    Speaker33 = '1:33',
    Highlight = '1:0',
    Equal22 = '2x2',
    Equal33 = '3x3',
    Equal44 = '4x4',
    Equal55 = '5x5',
}

export interface PreviewMediaInput {
    error: DeviceError;
    preview: MediaDeviceInfoLike | undefined;
    applyChanges: () => void;
    setPreview: (device: MediaDeviceInfoLike) => void;
}

export enum NetworkState {
    Connected = 'connected',
    Reconnecting = 'reconnecting',
    Reconnected = 'reconnected',
}

export interface NormalizedStats {
    inbound: {
        audio?: InboundAudioMetrics;
        video?: InboundVideoMetrics;
        preso?: InboundVideoMetrics;
    };
    outbound: {
        audio?: OutboundAudioMetrics;
        video?: OutboundVideoMetrics;
        preso?: OutboundVideoMetrics;
    };
}

export type EnableInMeetingUIAutoHideState = {
    type: InMeetingUIElements;
    isOpen: boolean;
};

export const selfviewAspectRatioValues = [
    'camera-step',
    'in-meeting',
    'ready-to-join-express',
    'ready-to-join-step',
    'force-landscape',
] as const;
export type SelfviewAspectRatios = (typeof selfviewAspectRatioValues)[number];

export enum BreakoutRoomAssignmentMode {
    Automatically = 'Automatically',
    Manually = 'Manually',
}

export enum BreakoutRoomsScreen {
    ModeAssignment,
    InitialConfiguration,
    RoomsOpened,
    EditConfiguration,
}

export enum BreakoutRoomVariant {
    Setup = 'setup',
    Preview = 'preview',
    Edit = 'edit',
}

export type BreakoutRoomName = string;
export type BreakoutRoomId = string;
export type ParticipantUuid = string;
export type BreakoutParticipants = Map<BreakoutRoomId, InMeetingParticipant[]>;
export type BreakoutParticipantsIdentities = Record<
    BreakoutRoomId,
    ParticipantUuid[]
>;
export type BreakoutRoomNames = Record<BreakoutRoomId, BreakoutRoomName>;

export type BreakoutsEditPanelSave = {
    move: Record<BreakoutRoomId, BreakoutParticipantsIdentities>; // {oldRoomId: {newRoom1Id: [], newRoom2Id: []}}
    addRoom: Record<BreakoutRoomName, BreakoutParticipantsIdentities>;
    renameRoom: Record<BreakoutRoomId, BreakoutRoomName>;
    removeRoom: BreakoutRoomId[];
};

export interface BreakoutAskingForHelpStatus {
    roomId: string;
    breakoutName: string;
    askForHelpTime: number;
}

export type EpochTimeStampSeconds = number;

export type ParticipantList = InMeetingParticipant[];
type RoomID = string;
export type RoomTuple = [RoomID, ParticipantList];
