import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    FontVariant,
    TextHeading,
    Button,
    ThemeProvider,
    Text,
    Bar,
    ConfirmationModal,
    Scrollbars,
} from '@pexip/components';

import {
    ImgLayoutThumbnail,
    LayoutThumbnail,
    Thumbnail,
} from '../LayoutThumbnail/LayoutThumbnail.view';
import {TestId} from '../../../test/testIds';
import {VideoLayoutOptionValues} from '../../types';
import {SettingsModalBody} from '../SettingsModalBody/SettingsModalBody.view';

import styles from './MeetingLayout.module.scss';

const LAYOUT_MAPPING = new Map<string, string[]>([
    [
        VideoLayoutOptionValues.AdaptiveComposition,
        [
            '5:7',
            'ac_presentation_in_mix',
            'ac_presentation_in_mix_group',
            'ac_extended',
        ],
    ],
    [VideoLayoutOptionValues.Equal22, ['4:0']],
    [VideoLayoutOptionValues.Equal33, ['9:0']],
    [VideoLayoutOptionValues.Equal44, ['16:0']],
    [VideoLayoutOptionValues.Equal55, ['25:0']],
]);

const isActive = (layout: string, layoutSelection: string | undefined) => {
    if (layoutSelection === layout) {
        return true;
    }

    // FIXME: workaround because of #35453
    return Boolean(
        layoutSelection &&
            LAYOUT_MAPPING.get(layout)?.includes(layoutSelection),
    );
};

export const MeetingLayout: React.FC<{
    currentLayout?: string;
    closeModal: () => void;
    setLayout: (layoutSelection: string) => void;
    setShowLayoutChangeConfirmationModal: (
        showLayoutChangeConfirmationModal: boolean,
    ) => void;
    showLayoutChangeConfirmationModal: boolean;
    isConfirmationOpen: boolean;
    openConfirmation: () => void;
    closeConfirmation: () => void;
    layouts: Map<string, {primary: string; active: string}>;
}> = ({
    currentLayout,
    closeModal,
    setLayout,
    setShowLayoutChangeConfirmationModal,
    showLayoutChangeConfirmationModal,
    isConfirmationOpen,
    openConfirmation,
    closeConfirmation,
    layouts,
}) => {
    const {t} = useTranslation();
    const [isSaving, setIsSaving] = useState(false);
    const [layoutSelection, setLayoutSelection] = useState<string | undefined>(
        currentLayout,
    );

    const handleSave = () => {
        setIsSaving(true);
        if (layoutSelection) {
            setLayout(layoutSelection);
        }
        closeConfirmation();
        closeModal();
    };

    const getLayoutThumbnail = (layout: string) => {
        return (
            <LayoutThumbnail
                active={isActive(layout, layoutSelection)}
                className={styles.layoutOption}
                layout={layout}
                key={layout}
                selectLayout={setLayoutSelection}
            />
        );
    };

    const getDefaultLayouts = () => (
        <>
            <Text fontVariant={FontVariant.BodyBold} htmlTag="p">
                <Trans t={t} i18nKey="settings.layout-speaker-focused">
                    Speaker focused
                </Trans>
            </Text>
            <div className={styles.layoutSettingsContent}>
                {[
                    VideoLayoutOptionValues.AdaptiveComposition,
                    VideoLayoutOptionValues.Speaker7,
                    VideoLayoutOptionValues.Speaker21,
                    VideoLayoutOptionValues.Speaker221,
                    VideoLayoutOptionValues.Speaker33,
                ].map(getLayoutThumbnail)}
            </div>
            <Text fontVariant={FontVariant.BodyBold} htmlTag="p">
                <Trans t={t} i18nKey="settings.layout-equal">
                    Equal
                </Trans>
            </Text>
            <div className={styles.layoutSettingsContent}>
                {[
                    VideoLayoutOptionValues.Highlight,
                    VideoLayoutOptionValues.Equal22,
                    VideoLayoutOptionValues.Equal33,
                    VideoLayoutOptionValues.Equal44,
                    VideoLayoutOptionValues.Equal55,
                ].map(getLayoutThumbnail)}
            </div>
        </>
    );

    const getLayouts = () => (
        <div className={styles.layoutSettingsContent}>
            {Array.from(layouts.entries()).map(([layout, svg]) =>
                svg.primary && svg.active ? (
                    <Thumbnail
                        key={layout}
                        layout={layout}
                        selectLayout={setLayoutSelection}
                    >
                        <ImgLayoutThumbnail
                            name={layout}
                            svg={svg}
                            active={isActive(layout, layoutSelection)}
                        />
                    </Thumbnail>
                ) : (
                    getLayoutThumbnail(layout)
                ),
            )}
        </div>
    );

    useEffect(() => {
        setLayoutSelection(currentLayout);
    }, [currentLayout]);

    return (
        <ThemeProvider colorScheme="light">
            <TextHeading
                htmlTag="h2"
                fontVariant={FontVariant.H4}
                variant="hidden"
                className="mb-1"
            >
                <Trans t={t} i18nKey="settings.settings">
                    Settings
                </Trans>
            </TextHeading>

            <TextHeading
                htmlTag="h3"
                fontVariant={FontVariant.H5}
                className="mb-1"
            >
                <Trans t={t} i18nKey="settings.layout">
                    Layout
                </Trans>
            </TextHeading>

            <Text fontVariant={FontVariant.Small} htmlTag="p">
                <Trans t={t} i18nKey="settings.layout-changes-info">
                    Changes you make to the video layout will be the same for
                    every participant.
                </Trans>
            </Text>

            <Scrollbars className={styles.layoutWrapper} autoHide="never">
                {layouts.size > 0 ? getLayouts() : getDefaultLayouts()}
            </Scrollbars>

            <SettingsModalBody>
                <Bar>
                    <Button
                        onClick={closeModal}
                        variant="tertiary"
                        size="medium"
                        modifier="fullWidth"
                        data-testid={TestId.ButtonSettingsCancel}
                    >
                        <Trans t={t} i18nKey="settings.cancel-changes">
                            Cancel changes
                        </Trans>
                    </Button>

                    <Button
                        onClick={() => {
                            if (!showLayoutChangeConfirmationModal) {
                                handleSave();
                            } else {
                                openConfirmation();
                            }
                        }}
                        type="submit"
                        modifier="fullWidth"
                        className="ml-2"
                        isLoading={isSaving}
                        data-testid={TestId.ButtonSettingsSave}
                    >
                        <Trans t={t} i18nKey="settings.save-changes">
                            Save changes
                        </Trans>
                    </Button>
                </Bar>
            </SettingsModalBody>
            <ConfirmationModal
                closeOnOutsideClick={false}
                cancelButtonTestid={TestId.ConfirmationModalCancelButton}
                cancelButtonText={t('common.cancel', 'Cancel')}
                confirmButtonTestid={TestId.ConfirmationModalConfirmButton}
                confirmButtonText={t('common.change', 'Change')}
                data-testid={TestId.ModalLayoutChangeConfirmation}
                description={t(
                    'settings.layout-confirmation-desc',
                    'You’re about to change the layout for everyone in the meeting, not just yourself.',
                )}
                isOpen={isConfirmationOpen}
                onCancel={closeConfirmation}
                onClose={closeConfirmation}
                onConfirm={() => {
                    setShowLayoutChangeConfirmationModal(false);
                    handleSave();
                }}
                title={t(
                    'settings.layout-confirmation-title',
                    'Changing layouts will affect everyone',
                )}
            />
        </ThemeProvider>
    );
};
