import {useCallback} from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {logger} from '../logger';

import {useSelectAudioOutput} from './useSelectAudioOutput';

export const createPreviewAudioOutputHook =
    (setAudioOutput: (value: MediaDeviceInfoLike) => void) =>
    (devices: MediaDeviceInfoLike[], audioOutput?: MediaDeviceInfoLike) => {
        const {
            selectedAudioOutput: preview,
            setSelectedAudioOutput: setPreview,
        } = useSelectAudioOutput(devices, audioOutput);

        const applyChanges = useCallback(() => {
            if (preview && preview.deviceId !== audioOutput?.deviceId) {
                logger.debug({audioOutput: preview}, 'Save AudioOutput');
                setAudioOutput(preview);
            }
            return false;
        }, [audioOutput?.deviceId, preview]);

        return {
            preview,
            applyChanges,
            setPreview,
        };
    };
