import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    ThemeProvider,
    TextHeading,
    FontVariant,
    Button,
    Bar,
    List,
    ToggleSwitch,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import type {StreamQuality} from '../../types';
import {SettingsModalBody} from '../SettingsModalBody/SettingsModalBody.view';
import {SelfViewSettings} from '../SelfViewSettings/SelfViewSettings.view';
import {QualityList} from '../QualityList/QualityList.view';

import styles from './MediaControlSettings.module.scss';

export const MediaControlSettings: React.FC<{
    inputAudioTester: React.ReactNode;
    outputAudioTester: React.ReactNode;
    handleCancel: () => void;
    handleNoiseSuppression: () => void;
    handleChangeOfSharingPriority: () => void;
    handleFecc: () => void;
    handlePreferPresInMix: () => void;
    handleSave: (e: React.SyntheticEvent<HTMLElement>) => void;
    allowToSave: boolean;
    isSaving: boolean;
    noiseSuppression: boolean;
    preferPresInMix: boolean;
    prioritizeSharingMotion: boolean;
    fecc: boolean;
    feccSupported: boolean;
    previewStream?: MediaStream;
    previewStreamQuality: StreamQuality;
    setPreviewStreamQuality: (streamQuality: StreamQuality) => void;
    deviceList: React.ReactNode;
}> = ({
    inputAudioTester,
    outputAudioTester,
    handleCancel,
    handleNoiseSuppression,
    handleChangeOfSharingPriority,
    handleFecc,
    handlePreferPresInMix,
    handleSave,
    isSaving,
    noiseSuppression,
    preferPresInMix,
    prioritizeSharingMotion,
    fecc,
    feccSupported,
    previewStream,
    previewStreamQuality,
    setPreviewStreamQuality,
    deviceList,
    allowToSave,
}) => {
    const {t} = useTranslation();
    return (
        <ThemeProvider colorScheme="light">
            <TextHeading
                htmlTag="h2"
                fontVariant={FontVariant.H4}
                variant="hidden"
                className="mb-1"
            >
                <Trans t={t} i18nKey="settings.settings">
                    Settings
                </Trans>
            </TextHeading>

            <TextHeading
                htmlTag="h3"
                fontVariant={FontVariant.H5}
                className="mb-1"
            >
                <Trans t={t} i18nKey="settings.devices">
                    Devices
                </Trans>
            </TextHeading>

            <div className={styles.personalSettingsContent}>
                <SettingsModalBody>
                    <div className={styles.options}>
                        <div className="mb-4">
                            {deviceList}
                            <List spacing="none" className="mt-1">
                                {outputAudioTester}
                            </List>
                        </div>

                        <QualityList
                            streamQuality={previewStreamQuality}
                            setStreamQuality={setPreviewStreamQuality}
                        />
                    </div>

                    <Bar>
                        <Button
                            onClick={handleCancel}
                            variant="tertiary"
                            size="medium"
                            modifier="fullWidth"
                            isDisabled={isSaving}
                            data-testid={TestId.ButtonSettingsCancel}
                        >
                            <Trans t={t} i18nKey="settings.cancel-changes">
                                Cancel changes
                            </Trans>
                        </Button>
                        <Button
                            onClick={handleSave}
                            type="submit"
                            modifier="fullWidth"
                            className="ml-2"
                            isLoading={isSaving}
                            isDisabled={!allowToSave}
                            data-testid={TestId.ButtonSettingsSave}
                        >
                            <Trans t={t} i18nKey="settings.save-changes">
                                Save changes
                            </Trans>
                        </Button>
                    </Bar>
                </SettingsModalBody>
                <div className={cx(styles.aside, 'pb-2')}>
                    <SelfViewSettings mediaStream={previewStream}>
                        {inputAudioTester}
                        <ToggleSwitch
                            checked={noiseSuppression}
                            className="mt-4"
                            data-testid={TestId.ToggleNoiseSuppression}
                            isSmall
                            label={t(
                                'settings.noise-suppression',
                                'Noise suppression',
                            )}
                            labelClassName={styles.toggleLabel}
                            labelModifier="spaced"
                            labelPosition="start"
                            name="noise-supression"
                            onChange={handleNoiseSuppression}
                        />
                        <ToggleSwitch
                            checked={prioritizeSharingMotion}
                            className="mt-4"
                            data-testid={TestId.TogglePrioritizeSharingMotion}
                            isSmall
                            label={t(
                                'settings.prioritize-sharing-motion',
                                'Prioritize sharing motion',
                            )}
                            labelClassName={styles.toggleLabel}
                            labelModifier="spaced"
                            labelPosition="start"
                            name="prioritize-sharing-motion"
                            onChange={handleChangeOfSharingPriority}
                        />
                        {feccSupported && (
                            <ToggleSwitch
                                checked={fecc}
                                className="mt-4"
                                data-testid={TestId.ToggleFecc}
                                isSmall
                                label={t(
                                    'settings.fecc',
                                    'Far-end camera control',
                                )}
                                labelClassName={styles.toggleLabel}
                                labelModifier="spaced"
                                labelPosition="start"
                                name="fecc"
                                onChange={handleFecc}
                            />
                        )}
                        <ToggleSwitch
                            checked={preferPresInMix}
                            className="mt-4"
                            data-testid={TestId.TogglePreferPresInMix}
                            isSmall
                            label={t(
                                'settings.prefer-pres-in-mix',
                                'Prefer presentation in mix',
                            )}
                            labelClassName={styles.toggleLabel}
                            labelModifier="spaced"
                            labelPosition="start"
                            name="prefer-pres-in-mix"
                            onChange={handlePreferPresInMix}
                        />
                    </SelfViewSettings>
                </div>
            </div>
        </ThemeProvider>
    );
};
