import type {
    FacingMode,
    InputConstraintSet,
    InputDeviceConstraint,
    MediaConstraintRequirement,
    MediaDeviceInfoLike,
    MediaDeviceRequest,
    NormalizedConstraintDeviceParameters,
} from './types';
import type {
    ConstrainBooleanKeys,
    ConstrainDoubleKeys,
    ConstrainParamKeys,
    ConstrainRangeParamKeys,
    ConstrainStringKeys,
    ConstrainULongKeys,
    ExtendedConstrainBooleanKeys,
    ExtendedConstrainDoubleKeys,
    ExtendedConstrainStringKeys,
    ExtendedConstrainULongKeys,
} from './typeGuards';
import {
    isBoolean,
    isConstrainBooleanKeys,
    isConstrainBooleanParameters,
    isConstrainDOMStringParameters,
    isConstrainDoubleKeys,
    isConstrainDoubleRange,
    isConstrainRange,
    isConstrainStringKeys,
    isConstrainULongKeys,
    isConstrainULongRange,
    isConstraintDOMString,
    isConstraintDeviceParameters,
    isConstraintSetDevice,
    isDeviceConstraint,
    isExtendedConstrainBooleanKeys,
    isExtendedConstrainDoubleKeys,
    isExtendedConstrainStringKeys,
    isExtendedConstrainULongKeys,
    isFacingMode,
    isFloat,
    isInputConstraintSet,
    isInteger,
    isMediaDeviceInfo,
    isMediaDeviceInfoArray,
    isMediaTrackConstraintSetKey,
    isMediaTrackConstraints,
    isNumber,
    isUndefined,
} from './typeGuards';
import {logger} from './logger';
import {findDevice} from './utils';

/**
 * Check if provided constraint is an `exact` device constraint
 */
export const isExactDeviceConstraint = (
    constraint: InputDeviceConstraint | undefined,
) => {
    if (isInputConstraintSet(constraint)) {
        return (
            (!!constraint.device &&
                typeof constraint.device === 'object' &&
                'exact' in constraint.device) ||
            (!!constraint.deviceId &&
                typeof constraint.deviceId === 'object' &&
                'exact' in constraint.deviceId)
        );
    }
    return false;
};

const getConstraintsSetFilter =
    (supportedConstraints: Set<keyof MediaTrackSupportedConstraints>) =>
    (
        trackConstraints?: MediaTrackConstraints | boolean,
    ): MediaTrackConstraints | boolean | undefined => {
        if (!isMediaTrackConstraints(trackConstraints)) {
            return trackConstraints;
        }

        const constraints = Object.entries(trackConstraints).reduce(
            (acc, [key, val]) =>
                key === 'advanced' ||
                supportedConstraints.has(
                    key as keyof MediaTrackSupportedConstraints,
                )
                    ? {...acc, [key]: val as unknown}
                    : acc,
            {} as MediaTrackConstraints,
        );

        // If we don't have any valid constraints it seems sensible to fallback to a boolean constraint -ea
        if (Object.keys(constraints).length === 0) {
            return true;
        }

        return constraints;
    };

const getDefinedOnly = (obj: Record<string, unknown>) => {
    return Object.entries(obj).reduce(
        (acc, [key, val]) =>
            typeof val === 'undefined' ? acc : {...acc, [key]: val},
        {},
    );
};

/**
 * Use navigator.mediaDevices.getSupportedConstraints to remove constraints not supported by agent.
 * Creates a copy of MediaStreamConstraints with only supported properties.
 */
export const removeUnsupportedConstraints = (
    constraints: MediaStreamConstraints,
): MediaStreamConstraints => {
    if (!(navigator && 'getSupportedConstraints' in navigator.mediaDevices)) {
        return constraints;
    }

    const supportedConstraints = Object.entries(
        navigator.mediaDevices.getSupportedConstraints(),
    );
    logger.debug({supportedConstraints}, 'Supported constraints');

    const constraintsSetFilter = getConstraintsSetFilter(
        supportedConstraints.reduce(
            (set, [key, value]) =>
                // FIXME: Cast inserted to unblock typescript upgrade, will have to be verified and properly fixed.
                value && isMediaTrackConstraintSetKey(key)
                    ? set.add(
                          key as unknown as keyof MediaTrackSupportedConstraints,
                      )
                    : set,
            new Set<keyof MediaTrackSupportedConstraints>(),
        ),
    );

    return getDefinedOnly({
        audio: constraintsSetFilter(constraints.audio),
        peerIdentity: constraints.peerIdentity,
        video: constraintsSetFilter(constraints.video),
    });
};

/**
 * Merge base constraints with provided base constraints and another constraints
 */
export const mergeConstraints =
    (baseConstraints: InputConstraintSet | boolean | undefined) =>
    (
        constraints: InputDeviceConstraint | undefined,
    ): InputConstraintSet | boolean => {
        if (!isInputConstraintSet(baseConstraints)) {
            if (isDeviceConstraint(constraints)) {
                return {device: constraints};
            }
            return constraints ?? false;
        }
        if (!constraints) {
            return false;
        }
        if (isDeviceConstraint(constraints)) {
            const result = {
                ...baseConstraints,
                device: constraints,
            };
            delete result.facingMode;
            delete result.deviceId;
            return result;
        }
        if (isInputConstraintSet(constraints)) {
            const combined = {...baseConstraints, ...constraints};
            const hasDeviceId =
                isConstraintDOMString(combined.deviceId) ||
                isConstrainDOMStringParameters(combined.deviceId);
            const hasDevice =
                isDeviceConstraint(combined.device) ||
                isConstraintDeviceParameters(combined.device);
            if (hasDeviceId && hasDevice) {
                const result = {
                    ...combined,
                };
                delete result.facingMode;
                delete result.deviceId;
                return result;
            }
            if (hasDevice || hasDeviceId) {
                delete combined.facingMode;
                return combined;
            }
            return combined;
        }
        return baseConstraints;
    };

/**
 * Normalize Device
 * Convert any valid `MediaDeviceInfoLike` into `MediaDeviceInfoLike[]`
 *
 * Check test cases for the details
 */
export const normalizeDevice = (device: InputConstraintSet['device']) => {
    if (!device) {
        return undefined;
    }
    if (isMediaDeviceInfo(device)) {
        return [device];
    }
    if (Array.isArray(device)) {
        const devices = device.filter(isMediaDeviceInfo);
        if (devices.length) {
            return devices;
        }
    }
    return undefined;
};

/**
 * Normalize Device Constraint
 * Convert Device Constraints into a form of `ConstraintDeviceParameters`
 * e.g.
 * `{ideal?: MediaDeviceInfoLike[], exact?: MediaDeviceInfoLike[]}`
 *
 * Check test cases for the details
 *
 * @returns
 * `undefined` if nothing is meaningful for the constraint, otherwise,
 * a normalized form of `ConstraintDeviceParameters`
 */
export const normalizeDeviceConstraint = (
    constraints: InputConstraintSet['device'],
): NormalizedConstraintDeviceParameters | undefined => {
    if (!constraints) {
        return undefined;
    }
    if (isConstraintDeviceParameters(constraints)) {
        return Object.keys(constraints)
            .filter(k => ['ideal', 'exact'].includes(k))
            .reduce(
                (cs, k) => {
                    const key = k as keyof NormalizedConstraintDeviceParameters;
                    const value = constraints[key];
                    const devices = normalizeDevice(value);
                    if (devices) {
                        return {...(cs ?? {}), [key]: devices};
                    }
                    return cs;
                },
                undefined as NormalizedConstraintDeviceParameters | undefined,
            );
    }
    const normalized = normalizeDevice(constraints);
    return normalized && {ideal: normalized};
};

/**
 * Convert `InputConstraintSet['device']` into
 * `MediaTrackConstraintSet['deviceId']` in a normalized form
 */
export const toDeviceIdConstraintSet = (
    constraint: InputConstraintSet['device'],
): MediaTrackConstraints['deviceId'] => {
    const [devices, param] = extractConstrainDevice({device: constraint});
    return devices && {[param]: devices.map(device => device.deviceId)};
};

export const toArray = <T>(t: T | T[]) => {
    const r = Array.isArray(t) ? t : [t];
    return r.filter(Boolean);
};

export type ConstrainNoneTuple = [undefined, 'ideal'];
export const NONE: ConstrainNoneTuple = [undefined, 'ideal'];

export type ConstrainStringTuple = [string[], ConstrainParamKeys];

export const extractConstrainString =
    (key: ConstrainStringKeys | ExtendedConstrainStringKeys) =>
    (
        constraints: InputConstraintSet,
    ): ConstrainStringTuple | ConstrainNoneTuple => {
        const constraint = constraints[key as keyof InputConstraintSet];

        if (!constraint) {
            return NONE;
        }
        if (isConstraintDOMString(constraint)) {
            return [toArray(constraint), 'ideal'];
        }
        if (isConstrainDOMStringParameters(constraint)) {
            // `exact` takes priority
            if (constraint.exact) {
                const normalized = toArray(constraint.exact);
                if (normalized.length) {
                    return [normalized, 'exact'];
                }
            }
            if (constraint.ideal) {
                return [toArray(constraint.ideal), 'ideal'];
            }
        }
        return NONE;
    };

export type ConstrainBooleanTuple = [boolean, ConstrainParamKeys];

export const extractConstrainBoolean =
    (key: ConstrainBooleanKeys | ExtendedConstrainBooleanKeys) =>
    (
        constraints: InputConstraintSet,
    ): ConstrainBooleanTuple | ConstrainNoneTuple => {
        const constraint = constraints[key];

        if (isUndefined(constraint)) {
            return NONE;
        }
        if (isBoolean(constraint)) {
            return [constraint, 'ideal'];
        }
        if (isConstrainBooleanParameters(constraint)) {
            const {ideal, exact} = constraint;
            // `exact` takes priority
            if (isBoolean(exact)) {
                return [exact, 'exact'];
            }
            if (isBoolean(ideal)) {
                return [ideal, 'ideal'];
            }
        }
        return NONE;
    };
export type ConstrainNumber =
    | number
    | Partial<Record<ConstrainRangeParamKeys, number>>;
export type ConstrainNumberTuple = [
    ConstrainNumber,
    ConstrainParamKeys | 'min-max',
];

export const extractConstrainNumber =
    (
        key:
            | ConstrainULongKeys
            | ConstrainDoubleKeys
            | ExtendedConstrainULongKeys
            | ExtendedConstrainDoubleKeys,
    ) =>
    (
        constraints: InputConstraintSet,
    ): ConstrainNumberTuple | ConstrainNoneTuple => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- @typescript-eslint and Typescript 5 issue.
        const constraint = constraints[key];

        if (constraint === undefined || constraint === null) {
            return NONE;
        }
        if (isFloat(constraint) || isInteger(constraint)) {
            return [constraint, 'ideal'];
        }
        if (
            isConstrainULongRange(constraint) ||
            isConstrainDoubleRange(constraint)
        ) {
            const {exact, ideal, min, max} = constraint;
            // all 'min', 'max', and 'exact' constraints in the basic Constraint
            // structure are together treated as the required constraints
            if (
                isFloat(min) ||
                isFloat(max) ||
                isInteger(min) ||
                isInteger(max)
            ) {
                return [{min, max, ideal, exact}, 'min-max'];
            }
            if (isFloat(exact) || isInteger(exact)) {
                return [exact, 'exact'];
            }
            if (isFloat(ideal) || isInteger(ideal)) {
                return [ideal, 'ideal'];
            }
        }
        return NONE;
    };

export type ConstrainDeviceTuple = [MediaDeviceInfoLike[], ConstrainParamKeys];
export const extractConstrainDevice = (
    constraints: InputDeviceConstraint | undefined,
): ConstrainDeviceTuple | ConstrainNoneTuple => {
    if (
        !constraints ||
        isBoolean(constraints) ||
        (Array.isArray(constraints) && !constraints.length)
    ) {
        return NONE;
    }
    const {device} = isInputConstraintSet(constraints)
        ? constraints
        : {device: constraints};
    if (isMediaDeviceInfo(device) || isMediaDeviceInfoArray(device)) {
        const normalized = normalizeDevice(device);
        if (normalized) {
            return [normalized, 'ideal'];
        }
    }
    if (isConstraintDeviceParameters(device)) {
        const {exact, ideal} = normalizeDeviceConstraint(device) ?? {};
        if (exact) {
            return [exact, 'exact'];
        }
        if (ideal) {
            return [ideal, 'ideal'];
        }
    }
    return NONE;
};

/**
 * Compare float point number a and b to see if they are closed to be considered
 * as having the same value
 *
 * @param a - Floating point number a
 * @param b - Floating point number b
 * @param numDigits - The number of digits to check after the decimal point @defaultValue 5 digits
 */
export const closedTo = (a: number, b: number, numDigits = 5) => {
    const multiplier = Math.pow(10, numDigits);
    return Math.round(a * multiplier) === Math.round(b * multiplier);
};

/**
 * Check if provided num is between min (inclusive) and max (inclusive)
 *
 * @param min - Lower boundary of the checking
 * @param num - The number used for the checking
 * @param max - Upper boundary of the checking
 */
export const between = (
    min: number | undefined,
    num: number,
    max: number | undefined,
) => {
    if (!isUndefined(min)) {
        if (!isUndefined(max)) {
            return num >= min && num <= max;
        }
        return num >= min;
    }
    if (!isUndefined(max)) {
        return num <= max;
    }
    return true;
};

export const getValueFromConstrainNumber = (
    constraint: ConstrainNumber,
): number => {
    if (isNumber(constraint)) {
        return constraint;
    }
    const {min, max, ideal, exact} = constraint;
    if (exact !== undefined) {
        const withinRange = between(min, exact, max);
        if (withinRange) {
            return exact;
        }
    }
    if (ideal !== undefined) {
        const withinRange = between(min, ideal, max);
        if (withinRange) {
            return ideal;
        }
    }
    const value = max ?? min;
    if (value !== undefined) {
        return value;
    }
    throw Error('Constrain Number is undefined');
};

export const getFacingModeFromConstraintString = (
    constraint: string,
): FacingMode | undefined => {
    if (isFacingMode(constraint)) {
        return constraint;
    }
    return undefined;
};

/**
 * Compare the provided constraint and num and see if the num satisfy the
 * constraint
 *
 * @param constraint - The constraint to be used
 * @param num - The num to be used to check
 *
 * @returns `true` means satisfy otherwise `false`
 */
export const satisfyConstrainNumber = (
    constraint: ConstrainNumber | undefined,
    num: number | undefined,
): boolean => {
    if (isUndefined(constraint) || isUndefined(num)) {
        return true;
    }
    if (isInteger(constraint)) {
        return num === constraint;
    }
    if (isFloat(constraint)) {
        return closedTo(constraint, num);
    }
    if (isConstrainRange(constraint)) {
        const {min, max, ideal, exact} = constraint;
        const withinRange = between(min, num, max);
        if (isFloat(exact)) {
            return withinRange && closedTo(exact, num);
        }
        if (isInteger(exact)) {
            return withinRange && exact === num;
        }
        if (isFloat(ideal)) {
            return withinRange && closedTo(ideal, num);
        }
        if (isInteger(ideal)) {
            return withinRange && ideal === num;
        }
        return withinRange;
    }
    return false;
};

/**
 * Resolve *constraints* and mediaTrackConstraints by checking the type and
 * try to return the best possible from mediaTrackConstraints.
 *
 * @internal
 */
export const resolveMediaDeviceConstraints = (
    constraints?: InputDeviceConstraint,
    base?: MediaTrackConstraints | boolean,
): MediaTrackConstraints | boolean => {
    const merge = mergeConstraints(base);
    // Handle InputConstraintSet
    if (isInputConstraintSet(constraints)) {
        if (isConstraintSetDevice(constraints.device)) {
            return merge(
                Object.keys(constraints).reduce((cs, k) => {
                    const key = k as keyof InputConstraintSet;
                    if (key === 'device') {
                        return {
                            ...cs,
                            deviceId: toDeviceIdConstraintSet(
                                constraints.device,
                            ),
                        };
                    }
                    return {...cs, [key]: constraints[key]};
                }, {}),
            );
        }
        return merge(constraints);
    }
    if (isConstraintSetDevice(constraints)) {
        const deviceId = toDeviceIdConstraintSet(constraints);
        return merge({deviceId});
    }
    if (constraints && isMediaTrackConstraints(base)) {
        return base;
    }
    return !!constraints;
};

/**
 * @remarks
 * Constraint multiple (exact) devices is not supported
 *
 * @internal
 */
export const getMediaConstraints = ({
    audio,
    video,
    defaultConstraints,
}: MediaConstraintRequirement): MediaStreamConstraints => {
    return removeUnsupportedConstraints({
        audio: resolveMediaDeviceConstraints(audio, defaultConstraints.audio),
        video: resolveMediaDeviceConstraints(video, defaultConstraints.video),
    });
};

/**
 * Call applyConstraints foreach track accordingly
 *
 * @param tracks - Tracks to be applied
 * @param constraints - constraints to be applied
 */
export const applyConstraints = async (
    tracks: MediaStreamTrack[] | undefined,
    constraints: MediaDeviceRequest,
) => {
    if (
        !tracks ||
        tracks.length === 0 ||
        Object.keys(constraints).length === 0
    ) {
        return Promise.resolve();
    }
    const {audio, video} = removeUnsupportedConstraints({
        audio: resolveMediaDeviceConstraints(constraints.audio),
        video: resolveMediaDeviceConstraints(constraints.video),
    });
    await Promise.all(
        tracks.flatMap(track => {
            if (track.kind === 'audio' && isMediaTrackConstraints(audio)) {
                return [track.applyConstraints(audio)];
            }
            if (track.kind === 'video' && isMediaTrackConstraints(video)) {
                return [track.applyConstraints(video)];
            }
            return [];
        }),
    );
};

export const findDeviceFrom = (
    devicesToFind: MediaDeviceInfoLike[],
    deviceList: MediaDeviceInfoLike[],
) => {
    const devicesFound = devicesToFind.flatMap(device => {
        const found = findDevice(device)(deviceList);
        if (found) {
            return [found];
        }
        return [];
    });
    if (devicesFound.length) {
        return devicesFound;
    }
    return undefined;
};

export const findDeviceFromDeviceConstraints = (
    device: InputConstraintSet['device'],
    devices: MediaDeviceInfoLike[],
) => {
    const normalized = normalizeDeviceConstraint(device);
    if (normalized) {
        const {ideal, exact} = normalized;
        if (exact) {
            return findDeviceFrom(exact, devices);
        }
        if (ideal) {
            return findDeviceFrom(ideal, devices);
        }
    }
};

export const relaxDevice = (
    device: InputConstraintSet['device'],
    devices: MediaDeviceInfoLike[],
) => {
    const found = findDeviceFromDeviceConstraints(device, devices);
    if (found) {
        return found;
    }
    // Return the original with normalized form
    return normalizeDevice(device) as MediaDeviceInfoLike[] | undefined;
};

export const resolveOnlyDevice = (devices: MediaDeviceInfoLike[]) => {
    switch (devices.length) {
        case 1:
            return devices[0];
        case 0:
            return undefined;
        default:
            return true;
    }
};
type InputConstraintSetKey = keyof InputConstraintSet;
type ConstrainNumberKeys =
    | ConstrainULongKeys
    | ConstrainDoubleKeys
    | ExtendedConstrainDoubleKeys
    | ExtendedConstrainULongKeys;
type ConstrainTuples =
    | ConstrainDeviceTuple
    | ConstrainNumberTuple
    | ConstrainStringTuple
    | ConstrainBooleanTuple
    | ConstrainNoneTuple;

type ExtractConstraintFn<R extends ConstrainTuples> = (
    constraints: InputDeviceConstraint | undefined,
) => R;
export function extractConstraints<T extends 'device'>(
    key: T,
): ExtractConstraintFn<ConstrainDeviceTuple>;
export function extractConstraints<
    T extends ConstrainStringKeys | ExtendedConstrainStringKeys,
>(key: T): ExtractConstraintFn<ConstrainStringTuple>;
export function extractConstraints<T extends ConstrainNumberKeys>(
    key: T,
): ExtractConstraintFn<ConstrainNumberTuple>;
export function extractConstraints<
    T extends ConstrainBooleanKeys | ExtendedConstrainBooleanKeys,
>(key: T): ExtractConstraintFn<ConstrainBooleanTuple>;
export function extractConstraints<T extends InputConstraintSetKey>(
    key: T,
): ExtractConstraintFn<ConstrainTuples> {
    return constraints => {
        if (
            !constraints ||
            typeof constraints === 'boolean' ||
            (Array.isArray(constraints) && !constraints.length)
        ) {
            return NONE;
        }
        if (
            key === 'device' &&
            (isMediaDeviceInfo(constraints) ||
                isMediaDeviceInfoArray(constraints))
        ) {
            return extractConstrainDevice(constraints);
        }
        if (isInputConstraintSet(constraints)) {
            if (key === 'device') {
                return extractConstrainDevice(constraints);
            }
            if (
                isConstrainStringKeys(key) ||
                isExtendedConstrainStringKeys(key)
            ) {
                return extractConstrainString(key)(constraints);
            }
            if (
                isConstrainDoubleKeys(key) ||
                isConstrainULongKeys(key) ||
                isExtendedConstrainULongKeys(key) ||
                isExtendedConstrainDoubleKeys(key)
            ) {
                return extractConstrainNumber(key)(constraints);
            }
            if (
                isConstrainBooleanKeys(key) ||
                isExtendedConstrainBooleanKeys(key)
            ) {
                return extractConstrainBoolean(key)(constraints);
            }
        }
        return [undefined, 'ideal'];
    };
}

type ConstrainStrings = Record<
    ConstrainStringKeys | ExtendedConstrainStringKeys,
    ConstrainStringTuple | ConstrainNoneTuple
>;
type ConstrainNumbers = Record<
    ConstrainNumberKeys,
    ConstrainNumberTuple | ConstrainNoneTuple
>;
type ConstrainBooleans = Record<
    ConstrainBooleanKeys | ExtendedConstrainBooleanKeys,
    ConstrainBooleanTuple | ConstrainNoneTuple
>;
type ConstrainDevices = Record<
    'device',
    ConstrainDeviceTuple | ConstrainNoneTuple
>;
type Constraints = ConstrainStrings &
    ConstrainNumbers &
    ConstrainBooleans &
    ConstrainDevices;

/**
 * Extract the constraints with provided keys
 *
 * @param keys - The keys to be used for the extraction
 * @param constraints - The constraints to be used for the extraction
 *
 * @returns an object with the provided key and the value-param tuple
 *
 * @example
 *
 * ```typescript
 * const device = {deviceId: 'xxxx', label: 'abc', kind: 'audioinput'};
 * const constraints = { device, noiseSuppression: true };
 * const extract = extractConstraintsWithKeys(['device', 'noiseSuppression']);
 * const {
 *   device: [devices, deviceParam],
 *   noiseSuppression: [noiseSuppression, noiseSuppressionParam],
 * } = extract(constraints);
 * expect(devices).toEqual([device]);
 * expect(deviceParam).toEqual('ideal');
 * expect(noiseSuppression).toEqual(true);
 * expect(noiseSuppressionParam).toEqual('ideal');
 * ```
 */
export const extractConstraintsWithKeys =
    <T extends keyof Constraints>(keys: T[]) =>
    (constraints: InputDeviceConstraint | undefined) =>
        keys.reduce(
            (result, key) => {
                if (key === 'device') {
                    return {
                        ...result,
                        [key]: extractConstraints(key)(constraints),
                    };
                }
                if (
                    isConstrainULongKeys(key) ||
                    isConstrainDoubleKeys(key) ||
                    isExtendedConstrainULongKeys(key) ||
                    isExtendedConstrainDoubleKeys(key)
                ) {
                    return {
                        ...result,
                        [key]: extractConstraints(key)(constraints),
                    };
                }
                if (
                    isConstrainStringKeys(key) ||
                    isExtendedConstrainStringKeys(key)
                ) {
                    return {
                        ...result,
                        [key]: extractConstraints(key)(constraints),
                    };
                }
                if (
                    isConstrainBooleanKeys(key) ||
                    isExtendedConstrainBooleanKeys(key)
                ) {
                    return {
                        ...result,
                        [key]: extractConstraints(key)(constraints),
                    };
                }
                return result;
            },
            {} as Pick<Constraints, (typeof keys)[number]>,
        );

/**
 * Extract deviceId from constraints
 */
export const extractDeviceId = extractConstrainString('deviceId');

/**
 * Find device from the device list with provided constraints
 *
 * @param constraints - The constraints to be used for the lookup
 * @param devices - The devices to be used for the lookup
 *
 * @returns `true` means it can be any devices, `undefined` means not found,
 * otherwise, the matched device will be returned
 */
export const findDeviceFromConstraints = (
    constraints: InputDeviceConstraint | undefined,
    devices: MediaDeviceInfoLike[],
) => {
    if (typeof constraints === 'boolean') {
        return constraints ? resolveOnlyDevice(devices) : constraints;
    }

    if (
        constraints === undefined ||
        !devices.length ||
        devices.some(device => !device.label) ||
        (Array.isArray(constraints) && !constraints.length)
    ) {
        return undefined;
    }

    const [constrainDevices, deviceParam] =
        extractConstraints('device')(constraints);
    // Device takes priority
    if (constrainDevices) {
        const [device] = findDeviceFrom(constrainDevices, devices) ?? [];
        if (device) {
            return device;
        }
        if (deviceParam === 'exact') {
            return undefined;
        }
    }
    const [ConstrainDeviceIds, deviceIdParam] =
        extractConstraints('deviceId')(constraints);
    if (ConstrainDeviceIds) {
        const deviceFound = devices.find(device => {
            const id = ConstrainDeviceIds.find(
                id => id && device.label && device.deviceId === id,
            );
            return !!id;
        });
        if (deviceFound) {
            return deviceFound;
        }
        if (deviceIdParam === 'exact') {
            return undefined;
        }
    }
    return resolveOnlyDevice(devices);
};

/**
 * Relax input constraints to enable looking up the device by `deviceId` as well
 * as `label` as a fallback as a best effort to get a similar device when
 * possible.
 *
 * @param input - The input constraints to be relaxed
 * @param devices - The current device list
 */
export const relaxInputConstraint = (
    input: InputDeviceConstraint | undefined,
    devices: MediaDeviceInfoLike[],
): InputDeviceConstraint | undefined => {
    if (devices.length === 0 || !input || typeof input === 'boolean') {
        return input;
    }
    const [device, param] = extractConstrainDevice(input);
    const relaxedDevice = relaxDevice(device, devices);
    const otherConstraints = isInputConstraintSet(input) ? input : {};
    return relaxedDevice
        ? {...otherConstraints, device: {[param]: relaxedDevice}}
        : input;
};

export const getConstraintsHandlers = () => {
    let defaultConstraints: MediaStreamConstraints = {
        audio: {
            echoCancellation: {ideal: true},
            noiseSuppression: {ideal: true},
        },
        video: {
            frameRate: {ideal: 30},
        },
    };

    const setDefaultConstraints = (newConstraints: MediaStreamConstraints) => {
        defaultConstraints = {...defaultConstraints, ...newConstraints};
    };

    const getDefaultConstraints = () => {
        return defaultConstraints;
    };

    return {
        getDefaultConstraints,
        setDefaultConstraints,
    };
};
