import React, {useCallback, useRef, useState} from 'react';

import {Button, Panel, useSmUp} from '@pexip/components';

import type {
    BreakoutParticipants,
    BreakoutRoomNames,
    ParticipantUuid,
    BreakoutsEditPanelSave,
} from '../../types';
import {BreakoutRoomVariant} from '../../types';
import {BreakoutRoomsPanelHeader} from '../BreakoutRoomsPanelHeader/BreakoutRoomsPanelHeader.view';
import {BreakoutRoom} from '../BreakoutRoom/BreakoutRoom.view';
import {
    useBreakoutParticipantDrag,
    useBreakoutChangeParticipantRoom,
    useBreakoutRemoveRoom,
    useBreakoutRoomNameChange,
    useBreakoutAddRoom,
    useBreakoutShuffleParticipants,
} from '../../hooks';
import {BreakoutRoomsConfigurationToolbar} from '../BreakoutRoomsConfigurationToolbar/BreakoutRoomsConfigurationToolbar.view';
import {useBreakoutEditDiff} from '../../hooks/useBreakoutEditDiff';

import styles from './BreakoutEditPanel.module.scss';

export const BreakoutEditPanel: React.FC<{
    currentParticipants: BreakoutParticipants;
    myIdentity?: ParticipantUuid;
    breakoutRoomsNames: BreakoutRoomNames;
    saveButtonText: string;
    cancelButtonText: string;
    onSave: (data: BreakoutsEditPanelSave) => void;
    onCancel: () => void;
    onClosePanel: () => void;
    onSettingsButtonClick?: () => void;
    hideAddRoomButtonText?: boolean;
    hideShuffleButtonText?: boolean;
}> = ({
    currentParticipants,
    myIdentity,
    breakoutRoomsNames: breakoutRoomsNamesProp,
    saveButtonText,
    cancelButtonText,
    onSave,
    onCancel,
    onClosePanel,
    onSettingsButtonClick,
    hideAddRoomButtonText,
    hideShuffleButtonText,
}) => {
    const isSmUp = useSmUp();
    const [participants, setParticipants] = useState(currentParticipants);
    const [breakoutRoomsNames, setBreakoutRoomsNames] = useState(
        breakoutRoomsNamesProp,
    );

    const [isDragging, setIsDragging] = useState(false);

    const scrollElementRef = useRef<HTMLElement>(null);

    const changeParticipantRoom = useBreakoutChangeParticipantRoom({
        participants,
        setParticipants,
    });

    const {
        targetRoomId: currentDragTarget,
        onParticipantDragging,
        onParticipantDrag,
        getBreakoutRoomRefCallback,
    } = useBreakoutParticipantDrag({
        participants,
        changeParticipantRoom,
        setIsDragging,
    });

    const onRemoveRoom = useBreakoutRemoveRoom({setParticipants});

    const {handleBreakoutRoomNameChange} = useBreakoutRoomNameChange({
        setParticipants,
        participants,
        breakoutRoomsNames,
        setBreakoutRoomsNames,
    });

    const onAddRoom = useBreakoutAddRoom({
        setParticipants,
        existingBreakoutNames: Object.values(breakoutRoomsNames),
    });

    const {doShuffle, isShuffling} = useBreakoutShuffleParticipants({
        setParticipants,
        myIdentity,
    });

    const reduceToIdentities = (participants: BreakoutParticipants) =>
        Array.from(participants).reduce((acc, [roomId, roomParticipants]) => {
            acc.set(
                roomId,
                roomParticipants.map(p => p.identity),
            );
            return acc;
        }, new Map<string, string[]>());

    const resolveEdit = useBreakoutEditDiff({
        liveBreakoutNames: breakoutRoomsNamesProp,
        localBreakoutNames: breakoutRoomsNames,
        liveParticipants: reduceToIdentities(currentParticipants),
        localParticipants: reduceToIdentities(participants),
    });

    const doSave = useCallback(() => {
        const updateActions = resolveEdit();
        onSave(updateActions);
    }, [resolveEdit, onSave]);

    return (
        <Panel
            scrollElementRef={scrollElementRef}
            overflowHidden={isDragging}
            isRounded={isSmUp}
            headerPadding="none"
            headerContent={
                <BreakoutRoomsPanelHeader onCloseClick={onClosePanel} />
            }
            footerContent={
                <div className={styles.footer}>
                    <Button
                        variant="tertiary"
                        modifier="fullWidth"
                        onClick={onCancel}
                    >
                        {cancelButtonText}
                    </Button>
                    <Button modifier="fullWidth" onClick={doSave}>
                        {saveButtonText}
                    </Button>
                </div>
            }
        >
            <BreakoutRoomsConfigurationToolbar
                onAddRoomButtonClick={onAddRoom}
                onShuffledButtonClick={doShuffle}
                onSettingsButtonClick={onSettingsButtonClick}
                hideAddRoomButtonText={hideAddRoomButtonText}
                hideShuffleButtonText={hideShuffleButtonText}
                isShuffling={isShuffling}
                className="pb-4"
            />
            {Array.from(participants.entries()).map(
                ([roomId, roomParticipants]) => {
                    return (
                        <BreakoutRoom
                            scrollElementRef={scrollElementRef}
                            variant={BreakoutRoomVariant.Edit}
                            id={roomId}
                            key={roomId}
                            name={breakoutRoomsNames[roomId] ?? roomId}
                            rooms={Array.from(participants.keys()).reduce(
                                (acc, current) =>
                                    roomId === current
                                        ? acc
                                        : {
                                              ...acc,
                                              [current]:
                                                  breakoutRoomsNames[current] ??
                                                  current,
                                          },
                                {},
                            )}
                            myIdentity={myIdentity}
                            participants={roomParticipants}
                            isDragTarget={currentDragTarget === roomId}
                            onNameChanged={handleBreakoutRoomNameChange}
                            onParticipantDragging={onParticipantDragging}
                            onParticipantDrag={onParticipantDrag}
                            onParticipantRoomChange={changeParticipantRoom}
                            onRemoveRoom={onRemoveRoom}
                            ref={getBreakoutRoomRefCallback(roomId)}
                        />
                    );
                },
            )}
        </Panel>
    );
};

export type BreakoutEditPanelProps = React.ComponentProps<
    typeof BreakoutEditPanel
>;
