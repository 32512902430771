import React from 'react';
import cx from 'classnames';

import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {
    ButtonModifiersValues,
    ExtendedSizeModifier,
} from '../../../types/sizes';
import type {ButtonVariant, ColorScheme} from '../../../types/variants';
import type {InteractiveElementProps} from '../InteractiveElement/InteractiveElement';
import {InteractiveElement} from '../InteractiveElement/InteractiveElement';
// This module import must come after InteractiveElement for CSS specificity to
// work as expected:
import styles from '../Button/Button.module.scss';

/**
 * SimpleButton looks and acts mostly the same as a normal Button except it has
 * less features. The idea is to make it easy to do use composition (children
 * components) to add desired behaviour.
 */
export const SimpleButton: React.FC<
    InteractiveElementProps & {
        colorScheme?: ColorScheme;
        isActive?: boolean;
        isDisabled?: boolean;
        modifier?: ButtonModifiersValues;
        noBorderRadius?: boolean;
        noHover?: boolean;
        size?: ExtendedSizeModifier;
        variant?: ButtonVariant;
    }
> = ({
    children,
    className,
    colorScheme,
    htmlTag,
    isActive,
    isDisabled,
    modifier,
    noBorderRadius = false,
    noHover = false,
    size = 'medium',
    variant = 'primary',
    ...props
}) => {
    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => {
                const currentColorScheme = colorScheme ?? defaultColorScheme;

                return (
                    <InteractiveElement
                        className={cx(
                            styles.button,
                            styles[currentColorScheme],
                            styles[variant],
                            styles[size],
                            modifier && styles[modifier],
                            {
                                [styles.disabled]: isDisabled,
                                [styles.noBorderRadius]: noBorderRadius,
                                [styles.noHover]: noHover || isDisabled,
                            },
                            className,
                        )}
                        data-active={isActive && !isDisabled}
                        disabled={isDisabled}
                        htmlTag={htmlTag}
                        {...props}
                    >
                        {children}
                    </InteractiveElement>
                );
            }}
        </ThemeConsumer>
    );
};
export type SimpleButtonProps = React.ComponentProps<typeof SimpleButton>;
