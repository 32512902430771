import React from 'react';
import cx from 'classnames';

import {sizeToPadding} from '../../../utils/helpers';
import {Box} from '../Box/Box';
import {Scrollbars} from '../Scrollbars/Scrollbars';

import styles from './Panel.module.scss';

export const Panel: React.FC<
    React.PropsWithChildren & {
        fillHeight?: boolean;
        overflowHidden?: boolean;
        scrollElementRef?: React.RefObject<HTMLElement>;
    } & React.ComponentProps<typeof Box>
> = ({
    fillHeight = true,
    hasFooterShadow = true,
    headerContent,
    footerContent,
    padding = 'small',
    headerPadding = padding,
    footerPadding = padding,
    overflowHidden = false,
    children,
    scrollElementRef,
    ...boxProps
}) => (
    <Box
        hasFooterShadow={hasFooterShadow}
        className={cx(
            styles.box,
            {[styles.fillHeight]: fillHeight},
            {[styles.overflowHidden]: overflowHidden},
        )}
        padding="none"
        headerContent={headerContent}
        footerContent={footerContent}
        footerPadding={footerPadding}
        headerPadding={headerPadding}
        {...boxProps}
    >
        <Scrollbars
            scrollElementRef={scrollElementRef}
            className={cx(styles.scrollbar)}
        >
            <div className={cx(styles[sizeToPadding(padding)])}>{children}</div>
        </Scrollbars>
    </Box>
);

export type PanelProps = React.ComponentProps<typeof Panel>;
