import React from 'react';
import cx from 'classnames';

import {useOnClickOutside} from '@pexip/hooks';

import type {
    ExtendedSizeModifier,
    ModalSizeModifier,
} from '../../../types/sizes';
import type {BoxProps} from '../../elements/Box/Box';
import {sizeToPadding} from '../../../utils/helpers';
import {TestId} from '../../../utils/testIds';
import {Portal} from '../../elements/Portal/Portal';
import {useFocusTrap} from '../../modules/Focus/useFocusTrap';
import {ModalCloseButton} from '../CloseButton/ModalCloseButton.view';
import {Box} from '../../elements/Box/Box';
import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer, ThemeProvider} from '../../../themes/ThemeContext';
import {Scrollbars} from '../../elements/Scrollbars/Scrollbars';

import styles from './Modal.module.scss';

export enum ModalType {
    Overlay = 'overlay',
    Positioned = 'positioned',
}

const Div: React.FC<React.ComponentProps<'div'>> = ({children, ...props}) => (
    <div {...props}>{children}</div>
);

export const Modal: React.FC<
    React.ComponentProps<'div'> & {
        closeButtonAriaLabel?: string;
        closeOnOutsideClick?: boolean;
        colorScheme?: ColorScheme;
        hasBackground?: boolean;
        isOpen: boolean;
        modalType?: ModalType;
        onClose?: () => void;
        padding?: ExtendedSizeModifier;
        sizeModifier?: ModalSizeModifier;
        testIdCloseButton?: string;
        uniqueTitle?: string;
        withCloseButton?: boolean;
        withScrollbars?: boolean;
        wrapperClassName?: string;
    } & Pick<
            BoxProps,
            | 'footerContent'
            | 'footerPadding'
            | 'hasFooterShadow'
            | 'headerContent'
            | 'headerPadding'
        >
> = ({
    children,
    className,
    closeButtonAriaLabel = 'Close tooltip',
    closeOnOutsideClick = true,
    colorScheme = 'light',
    footerContent,
    footerPadding,
    hasBackground,
    hasFooterShadow,
    headerContent,
    headerPadding,
    isOpen = false,
    modalType = ModalType.Overlay,
    onClose,
    padding = 'medium',
    sizeModifier = 'small',
    testIdCloseButton = TestId.ButtonModalClose,
    uniqueTitle,
    withCloseButton = false,
    withScrollbars = true,
    wrapperClassName,
    ...props
}) => {
    const tryToClose = () => {
        if (closeOnOutsideClick) {
            onClose?.();
        }
    };
    const outsideClickRef = useOnClickOutside(tryToClose);
    const focusTrapRef = useFocusTrap(isOpen);

    const Container = withScrollbars ? Scrollbars : Div;

    const modal = (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <ThemeProvider colorScheme={colorScheme ?? defaultColorScheme}>
                    <Box
                        role="complementary"
                        className={cx(
                            styles.modal,
                            styles[modalType],
                            styles[sizeModifier],
                            className,
                        )}
                        ref={
                            modalType === ModalType.Positioned
                                ? null
                                : focusTrapRef
                        }
                        hasBackground={hasBackground}
                        headerContent={headerContent}
                        footerContent={footerContent}
                        headerPadding={headerPadding}
                        footerPadding={footerPadding}
                        hasFooterShadow={hasFooterShadow}
                    >
                        <Container className={styles.scrollbars}>
                            <div
                                role="none"
                                ref={outsideClickRef}
                                className={styles[sizeToPadding(padding)]}
                                {...(modalType === ModalType.Positioned
                                    ? props
                                    : {})}
                            >
                                <div
                                    role="dialog"
                                    aria-modal="true"
                                    aria-label={uniqueTitle}
                                >
                                    {withCloseButton && (
                                        <ModalCloseButton
                                            className={styles.closeButton}
                                            onClose={onClose}
                                            data-testid={testIdCloseButton}
                                            aria-label={closeButtonAriaLabel}
                                        />
                                    )}
                                    {children}
                                </div>
                            </div>
                        </Container>
                    </Box>
                </ThemeProvider>
            )}
        </ThemeConsumer>
    );

    const maskedModal = (
        <div
            role="none"
            className={cx(styles.modalMask, wrapperClassName)}
            data-testid={TestId.ModalMask}
            {...props}
        >
            {modal}
        </div>
    );

    if (!isOpen) {
        return null;
    }

    return (
        <Portal>
            {modalType === ModalType.Positioned ? modal : maskedModal}
        </Portal>
    );
};

export type ModalProps = React.ComponentProps<typeof Modal>;
