import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Box,
    Button,
    ModalCloseButton as CloseButton,
    CustomTooltip,
    FontVariant,
    List,
    Modal,
    Row,
    Text,
    TextLink,
} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';

import {TestId} from '../../../test/testIds';
import {BlockedBrowserPermissionsInfoType} from '../../types';
import {getUserAgentDetails} from '../../utils';

import styles from './DeviceDeniedTooltip.module.scss';

/*
t('media.permission-info.[microphone].title', 'microphone')
t('media.permission-info.[camera].title', 'camera')
t('media.permission-info.[microphone-and-camera].title', 'camera and microphone')
*/

const ToolTipModal: React.FC<{
    content: React.ReactNode;
    children: React.ReactNode;
    visible: boolean;
    onClose: () => void;
}> = ({content, children, onClose, visible, ...props}) => {
    const {t} = useTranslation();

    return (
        <>
            <Modal
                isOpen={visible}
                closeOnOutsideClick={true}
                data-testid={TestId.ModalMoveToRoom}
                withCloseButton={false}
                onClose={onClose}
                {...props}
            >
                {content}
                <Button
                    size="large"
                    className="mt-3"
                    modifier="fullWidth"
                    onClick={onClose}
                >
                    {t('common.ok', 'Ok')}
                </Button>
            </Modal>
            {children}
        </>
    );
};

const ToolTipBalloon: React.FC<{
    content: React.ReactNode;
    children: React.ReactNode;
    visible: boolean;
    mainWrapperClassName?: string;
    wrapperClassName?: string;
}> = ({
    content,
    children,
    visible,
    mainWrapperClassName,
    wrapperClassName,
    ...props
}) => {
    return (
        <CustomTooltip
            controlledIsVisible={visible}
            isHoverEnabled={false}
            mainWrapperClassName={mainWrapperClassName}
            wrapperClassName={wrapperClassName}
            {...props}
            content={<Box className={styles.tooltipWrapper}>{content}</Box>}
            position="topCenter"
        >
            {children}
        </CustomTooltip>
    );
};

export const DeviceDeniedTooltip: React.FC<{
    children: React.ReactNode;
    contentWrapperClassName?: string;
    deniedDevice: DeniedDevices;
    setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    learnHowToGrantAccessURL?: string;
    permissionInfoType?: BlockedBrowserPermissionsInfoType;
    mainWrapperClassName?: string;
    onClose: () => void;
    visible: boolean;
    isTouch?: boolean;
}> = ({
    children,
    contentWrapperClassName,
    deniedDevice,
    setShowHelpVideo,
    learnHowToGrantAccessURL,
    permissionInfoType,
    mainWrapperClassName,
    onClose,
    visible,
    isTouch,
    ...props
}) => {
    const {t} = useTranslation();

    isTouch = isTouch ?? getUserAgentDetails(navigator.userAgent).isTouch;

    const content = (
        <List className={cx([isTouch ? 'pl-0 pr-0' : ''])}>
            <Row className="pt-3 pb-1">
                <Text fontVariant={FontVariant.BodyBold}>
                    {t(
                        'media.you-blocked-access',
                        `You blocked access to your ${deniedDevice.replaceAll(
                            '-',
                            ' ',
                        )}`,
                        {
                            seeOrHearYou: t(
                                `media.permission-info.[${deniedDevice}].title`,
                            ),
                        },
                    )}
                </Text>
                <CloseButton
                    className={styles.close}
                    onClose={onClose}
                    aria-label={t('common.close-tooltip', 'Close tooltip')}
                />
            </Row>
            <Row className="pt-0">
                {permissionInfoType ===
                    BlockedBrowserPermissionsInfoType.COMPLETE && (
                    <Text>
                        <Trans
                            t={t}
                            i18nKey="media.permissions-help-video-link"
                        >
                            To grant access please follow the steps in{' '}
                            <TextLink
                                onClick={() => setShowHelpVideo(true)}
                                target="_blank"
                                data-testid={
                                    TestId.TextLinkLearnRequestPermissions
                                }
                            >
                                this short video
                            </TextLink>
                        </Trans>{' '}
                        <Trans t={t} i18nKey="media.learn-how-to-grant-access">
                            {' '}
                            or go to the{' '}
                            <TextLink
                                href={learnHowToGrantAccessURL}
                                target="_blank"
                                data-testid={
                                    TestId.TextLinkLearnRequestPermissions
                                }
                            >
                                privacy settings
                            </TextLink>{' '}
                            of your browser
                        </Trans>
                    </Text>
                )}
                {permissionInfoType ===
                    BlockedBrowserPermissionsInfoType.VOID && (
                    <Text>
                        {t(
                            'media.other-browsers-privacy-settings',
                            'To grant access please go to the privacy settings of your browser or try to refresh the page.',
                        )}
                    </Text>
                )}
            </Row>
        </List>
    );

    return isTouch ? (
        <ToolTipModal content={content} visible={visible} onClose={onClose}>
            {children}
        </ToolTipModal>
    ) : (
        <ToolTipBalloon
            mainWrapperClassName={mainWrapperClassName}
            wrapperClassName={contentWrapperClassName}
            visible={visible}
            {...props}
            content={content}
        >
            {children}
        </ToolTipBalloon>
    );
};

export type DeviceDeniedToolTipProps = React.ComponentProps<
    typeof DeviceDeniedTooltip
>;
