import type {NotificationToastMessage} from '@pexip/components';
import {IconTypes, notificationToastSignal} from '@pexip/components';
import type {
    FormPayload,
    RPCCall,
    RPCCallReply,
    RPCReply,
    SelectElement,
} from '@pexip/plugin-api';
import {noop} from '@pexip/utils';

import {updatePluginsElements} from './utils';
import type {PluginContext} from './types';

const validateButton = (payload: RPCCall<'ui:button:add'>['payload']) => {
    let status: RPCCallReply<'ui:button:add'>['status'] = 'ok';
    if (
        payload.position === 'toolbar' &&
        typeof payload.icon === 'string' &&
        IconTypes[payload.icon as keyof typeof IconTypes] === undefined
    ) {
        status = 'failed';
        return {status, reason: 'Invalid Icon name' as const};
    }
    return {status};
};

export const handleAddButton = (
    data: RPCCall<'ui:button:add'>,
    setPluginsElements: React.Dispatch<React.SetStateAction<PluginContext>>,
): Omit<RPCReply<'ui:button:add'>, 'chanId'> => {
    const replyMeta = {
        rpc: data.rpc,
        replyTo: data.id,
    };
    const {status, reason} = validateButton(data.payload);
    if (status === 'failed') {
        return {
            ...replyMeta,
            payload: {
                status,
                reason,
                id: data.id,
            },
        };
    }

    setPluginsElements(pluginsElements => {
        const pluginElements = pluginsElements[data.chanId];
        if (!pluginElements) {
            return pluginsElements;
        }

        const button = {
            ...data.payload,
            id: data.id,
            chanId: data.chanId,
        };
        pluginElements.buttons.push(button);
        return updatePluginsElements(pluginsElements, [
            data.chanId,
            pluginElements,
        ]);
    });
    return {
        ...replyMeta,
        payload: {
            status,
            id: data.id,
            data: undefined,
        },
    };
};

export const handleUpdateButton = (
    data: RPCCall<'ui:button:update'>,
    setPluginsElements: React.Dispatch<React.SetStateAction<PluginContext>>,
): Omit<RPCReply<'ui:button:update'>, 'chanId'> => {
    const replyMeta = {
        rpc: data.rpc,
        replyTo: data.id,
    };
    const {status, reason} = validateButton(data.payload);
    if (status === 'failed') {
        return {
            ...replyMeta,
            payload: {
                status,
                reason,
                id: data.id,
            },
        };
    }
    setPluginsElements(pluginsElements => {
        const pluginElements = pluginsElements[data.chanId];
        if (!pluginElements) {
            return pluginsElements;
        }
        const btnIndex = pluginElements.buttons.findIndex(
            btn => btn.id === data.payload.targetId,
        );
        if (btnIndex > -1 && typeof data.id === 'string') {
            pluginElements.buttons[btnIndex] = {
                ...data.payload,
                id: data.payload.targetId,
                chanId: data.chanId,
            };
        }
        return updatePluginsElements(pluginsElements, [
            data.chanId,
            pluginElements,
        ]);
    });
    return {
        ...replyMeta,
        payload: {
            status: 'ok',
            id: data.id,
            data: undefined,
        },
    };
};

const validateForm = (payload: FormPayload) => {
    let status: RPCCallReply<'ui:form:open'>['status'] = 'ok';
    let reason = '';
    const selectElements = Object.values(payload.form.elements).filter(
        element => element.type === 'select',
    ) as SelectElement[];

    if (selectElements.find(element => element.options.length === 0)) {
        status = 'failed';
        reason =
            'The select elements in the form must have at least one option';
    } else if (
        selectElements.find(
            el =>
                el.selected &&
                !el.options.map(el => el.id).includes(el.selected),
        )
    ) {
        status = 'failed';
        reason = `A SelectElement's 'selected' value must be one of its options' IDs`;
    }
    return {
        status,
        reason,
    };
};

export const handleOpenForm = (
    data: RPCCall<'ui:form:open'>,
    setPluginsElements: React.Dispatch<React.SetStateAction<PluginContext>>,
): Omit<RPCReply<'ui:form:open'>, 'chanId'> => {
    const replyMeta = {
        rpc: data.rpc,
        replyTo: data.id,
    };
    const {status, reason} = validateForm(data.payload);
    if (status === 'failed') {
        return {
            ...replyMeta,
            payload: {
                status,
                reason,
                id: data.id,
            },
        };
    }
    setPluginsElements(pluginsElements => {
        const pluginElements = pluginsElements[data.chanId];
        if (!pluginElements) {
            return pluginsElements;
        }

        const form = {
            ...data.payload,
            id: data.id,
            chanId: data.chanId,
        };
        pluginElements.forms.push(form);
        return updatePluginsElements(pluginsElements, [
            data.chanId,
            pluginElements,
        ]);
    });
    return {
        rpc: data.rpc,
        replyTo: data.id,
        payload: {
            status,
            id: data.id,
            data: undefined,
        },
    };
};

export const handleOpenPrompt = (
    data: RPCCall<'ui:prompt:open'>,
    setPluginsElements: React.Dispatch<React.SetStateAction<PluginContext>>,
): Omit<RPCReply<'ui:prompt:open'>, 'chanId'> => {
    setPluginsElements(pluginsElements => {
        const pluginElements = pluginsElements[data.chanId];
        if (!pluginElements) {
            return pluginsElements;
        }

        const prompt = {
            ...data.payload,
            id: data.id,
            chanId: data.chanId,
        };
        pluginElements.prompts.push(prompt);
        return updatePluginsElements(pluginsElements, [
            data.chanId,
            pluginElements,
        ]);
    });
    return {
        rpc: data.rpc,
        replyTo: data.id,
        payload: {
            status: 'ok',
            id: data.id,
            data: undefined,
        },
    };
};

export const handleRemoveElement = (
    data: RPCCall<'ui:removeElement'>,
    setPluginsElements: React.Dispatch<React.SetStateAction<PluginContext>>,
): Omit<RPCReply<'ui:removeElement'>, 'chanId'> => {
    setPluginsElements(pluginsElements => {
        const pluginElements = pluginsElements[data.chanId];
        if (!pluginElements) {
            return pluginsElements;
        }
        Object.values(pluginElements).forEach(group => {
            const elIndex = group.findIndex(el => el.id === data.payload.id);
            if (elIndex > -1) {
                group = group.splice(elIndex, 1);
            }
        });
        return updatePluginsElements(pluginsElements, [
            data.chanId,
            pluginElements,
        ]);
    });
    return {
        rpc: data.rpc,
        replyTo: data.id,
        payload: {
            status: 'ok',
            id: data.id,
            data: undefined,
        },
    };
};

export const handleShowToast = (
    data: RPCCall<'ui:toast:show'>,
): Omit<RPCReply<'ui:toast:show'>, 'chanId'> => {
    const {canDismiss, ...rest} = data.payload;
    const toastData: NotificationToastMessage = {
        ...rest,
        ...(canDismiss && {onDismiss: noop}),
    };
    notificationToastSignal.emit([toastData]);
    return {
        rpc: data.rpc,
        replyTo: data.id,
        payload: {
            status: 'ok',
            id: data.id,
            data: undefined,
        },
    };
};
