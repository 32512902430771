import type {BrowserOptions} from '@sentry/react';

import {FRAME_RATE} from '@pexip/media-processor';
import {INCLUDE_EXCLUDE_CONSTRAINT} from '@pexip/media-control';
import type {IncludeExcludeConstraint} from '@pexip/media-control';

import type {TestIdValue} from './types';
import {logger} from './logger';
import {shouldEnableVideoProcessing} from './config';
import {
    BANDWIDTHS,
    SEGMENTATION_MODEL,
    TERMS_URL,
    BG_IMAGE_URL,
} from './constants';
import type {StringQuadruple} from './constants';

const el = document.querySelector('#ic-config');

export interface DeploymentConfig {
    sentry?: BrowserOptions;
}

export const deploymentConfig = (() => {
    try {
        return el ? (JSON.parse(el.innerHTML) as DeploymentConfig) : {};
    } catch (error: unknown) {
        logger.error(error, 'failed to parse deploymentConfig');
        return {};
    }
})();

export const applicationConfig = {
    ...deploymentConfig,
    audioProcessing: true,
    bandwidths: BANDWIDTHS,
    frameRate: FRAME_RATE,
    node: window.location.host,
    segmentationModel: SEGMENTATION_MODEL,
    showLiveCaptionsFeature: true,
    videoProcessing: shouldEnableVideoProcessing(),
    shouldMaskConference: false,
    directMedia: true,
    termsAndConditions: {
        en: TERMS_URL,
    } as Record<string, string>,
    disconnectDestination: '',
    bgImageAssets: [BG_IMAGE_URL],
    handleOauthRedirects: false,
    showTermsAndConditionsLink: true,
    monitorTypeSurfaces:
        INCLUDE_EXCLUDE_CONSTRAINT.Include as IncludeExcludeConstraint,
    selfBrowserSurface:
        INCLUDE_EXCLUDE_CONSTRAINT.Exclude as IncludeExcludeConstraint,
    systemAudio: INCLUDE_EXCLUDE_CONSTRAINT.Include as IncludeExcludeConstraint,
    hiddenFunctionality: {} as Record<TestIdValue, true>,
};
export type ApplicationConfig = typeof applicationConfig;

export const setApplicationConfig = <K extends keyof ApplicationConfig>(
    key: K,
    value: ApplicationConfig[K],
) => {
    switch (key) {
        case 'bandwidths':
            if (Array.isArray(value)) {
                const accepted = value
                    .flatMap(v => {
                        const n = +v;
                        // 0 is not a valid bandwidth
                        if (n) {
                            return [v];
                        }
                        return [];
                    })
                    .sort((a, b) => +a - +b)
                    .slice(0, BANDWIDTHS.length);
                if (accepted.length === BANDWIDTHS.length) {
                    applicationConfig.bandwidths = accepted as StringQuadruple;
                }
            }
            break;
        default:
            applicationConfig[key] = value;
            break;
    }
};
