import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
    ConfirmationModal,
    Panel,
    Button,
    Icon,
    IconTypes,
    useSmUp,
} from '@pexip/components';

import type {
    BreakoutAskingForHelpStatus,
    BreakoutParticipants,
} from '../../types';
import {BreakoutRoomVariant} from '../../types';
import {BreakoutRoomsPanelHeader} from '../BreakoutRoomsPanelHeader/BreakoutRoomsPanelHeader.view';
import {BreakoutRoom} from '../BreakoutRoom/BreakoutRoom.view';
import {TestId} from '../../../test/testIds';
import {BreakoutRoomsAskingForHelp} from '../BreakoutRoomsAskingForHelp/BreakoutRoomsAskingForHelp.view';

import styles from './BreakoutPreviewPanel.module.scss';

export const BreakoutPreviewPanel: React.FC<{
    breakoutsAskingForHelp: BreakoutAskingForHelpStatus[];
    currentParticipants: BreakoutParticipants;
    showCloseRoomsButton: boolean;
    closeRoomsText: string;
    editRoomsText: string;
    breakoutRoomsNames: Record<string, string>;
    myIdentity?: string;
    onJoinRoom: (roomId: string) => void;
    onJoinRoomViaHelp: (roomId: string) => void;
    onCloseRoom: (roomId: string) => void;
    onCloseRooms: () => void;
    onClosePanel: () => void;
    onEditRooms: () => void;
}> = ({
    breakoutsAskingForHelp,
    currentParticipants,
    showCloseRoomsButton,
    closeRoomsText,
    editRoomsText,
    breakoutRoomsNames,
    myIdentity,
    onJoinRoom,
    onJoinRoomViaHelp,
    onCloseRoom,
    onCloseRooms,
    onClosePanel,
    onEditRooms,
}) => {
    const {t} = useTranslation();
    const [isCloseRoomsModalOpen, setIsCloseRoomsModalOpen] = useState(false);
    const isSmUp = useSmUp();
    return (
        <>
            <Panel
                isRounded={isSmUp}
                headerPadding="none"
                headerContent={
                    <BreakoutRoomsPanelHeader onCloseClick={onClosePanel} />
                }
                footerContent={
                    showCloseRoomsButton ? (
                        <Button
                            onClick={() => setIsCloseRoomsModalOpen(true)}
                            modifier="fullWidth"
                            variant="danger"
                            data-testid={TestId.CloseBreakoutRooms}
                        >
                            {closeRoomsText}
                        </Button>
                    ) : undefined
                }
            >
                <div className={styles.topActions}>
                    <Button
                        enhancerStart={
                            <Icon size="compact" source={IconTypes.IconEdit} />
                        }
                        onClick={onEditRooms}
                        variant="bordered"
                        data-testid={TestId.ButtonBreakoutRoomEdit}
                    >
                        {editRoomsText}
                    </Button>
                </div>
                <BreakoutRoomsAskingForHelp
                    breakoutsAskingForHelp={breakoutsAskingForHelp}
                    onJoinRoom={onJoinRoomViaHelp}
                />
                {Array.from(currentParticipants.entries()).map(
                    ([roomId, participants]) => {
                        return (
                            <BreakoutRoom
                                key={roomId}
                                name={breakoutRoomsNames[roomId] ?? roomId}
                                id={roomId}
                                participants={participants}
                                myIdentity={myIdentity}
                                variant={BreakoutRoomVariant.Preview}
                                onJoinRoom={onJoinRoom}
                                onCloseRoom={onCloseRoom}
                            />
                        );
                    },
                )}
            </Panel>
            <ConfirmationModal
                cancelButtonText={t('common.cancel', 'Cancel')}
                confirmButtonText={t('common.yes', 'Yes')}
                title={t(
                    'breakouts.close-all-rooms-modal.title',
                    'Close all rooms?',
                )}
                description={t(
                    'breakouts.close-all-rooms-modal.description',
                    'All participants will be moved to the main room.',
                )}
                isOpen={isCloseRoomsModalOpen}
                onConfirm={() => {
                    setIsCloseRoomsModalOpen(false);
                    onCloseRooms();
                }}
                onCancel={() => setIsCloseRoomsModalOpen(false)}
            />
        </>
    );
};
