import React, {useEffect} from 'react';

import {Tooltip} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';

import {DeviceDeniedTooltip} from '../DeviceDeniedTooltip/DeviceDeniedTooltip.view';
import {TestId} from '../../../test/testIds';
import type {BlockedBrowserPermissionsInfoType} from '../../types';

import {InputControl} from './InputControl.view';

export const InMeetingInputControl: React.FC<
    React.ComponentProps<typeof InputControl> & {
        deniedDevice?: DeniedDevices;
        learnHowToGrantAccessURL?: string;
        permissionInfoType?: BlockedBrowserPermissionsInfoType;
        setShowDeniedDeviceTooltip?: React.Dispatch<
            React.SetStateAction<boolean>
        >;
        shouldShowTooltip?: boolean;
        showDeniedDeviceTooltip?: boolean;
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
        tooltipText?: React.ReactNode;
        setShowHelpVideo?: React.Dispatch<React.SetStateAction<boolean>>;
    }
> = ({
    deniedDevice,
    learnHowToGrantAccessURL,
    permissionInfoType,
    setShowDeniedDeviceTooltip,
    shouldShowTooltip = true,
    showDeniedDeviceTooltip = false,
    tooltipPosition = 'top',
    tooltipText,
    variant = 'translucent',
    setShowHelpVideo,
    ...props
}) => {
    useEffect(() => {
        if (deniedDevice) {
            setShowDeniedDeviceTooltip?.(true);
        }
    }, [deniedDevice, setShowDeniedDeviceTooltip]);

    const enhancedProps = {
        ...props,
        onClick: () => setShowDeniedDeviceTooltip?.(!showDeniedDeviceTooltip),
    };

    if (
        deniedDevice &&
        shouldShowTooltip &&
        learnHowToGrantAccessURL &&
        setShowHelpVideo
    ) {
        return (
            <DeviceDeniedTooltip
                data-testid={TestId.TooltipDeniedDevice}
                deniedDevice={deniedDevice}
                learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                permissionInfoType={permissionInfoType}
                onClose={() =>
                    setShowDeniedDeviceTooltip?.(!showDeniedDeviceTooltip)
                }
                visible={showDeniedDeviceTooltip}
                setShowHelpVideo={setShowHelpVideo}
            >
                <InputControl variant={variant} {...enhancedProps} />
            </DeviceDeniedTooltip>
        );
    }

    if (!shouldShowTooltip) {
        return <InputControl variant={variant} {...props} />;
    }

    if (!tooltipText) {
        return <InputControl variant={variant} {...enhancedProps} />;
    }

    return (
        <Tooltip text={tooltipText} position={tooltipPosition}>
            <InputControl variant={variant} {...props} />
        </Tooltip>
    );
};
