import React, {useState} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {ModalProps} from '@pexip/components';
import {
    Checkbox,
    NumberStepInput,
    Group,
    IconTypes,
    Icon,
    Color,
    Cell,
    Button,
    Grid,
    Modal,
    ThemeProvider,
    Text,
    TextHeading,
    FontVariant,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './RoomSettingsModal.module.scss';

export interface TimerValues {
    maxHours: number;
    maxMinutes: number;
    defaultHours: number;
    defaultMinutes: number;
}

export const RoomSettingsModalView: React.FC<
    ModalProps & {
        isOpen: boolean;
        onSave: (
            hours: number,
            minutes: number,
            participantsCanLeave: boolean,
        ) => void;
        onCancel: () => void;
        timerValues: TimerValues;
    }
> = ({className, isOpen, onSave, onCancel, timerValues, ...props}) => {
    const {t} = useTranslation();
    const [hours, setHours] = useState(timerValues.defaultHours);
    const [minutes, setMinutes] = useState(timerValues.defaultMinutes);
    const [participantsCanLeave, setParticipantsCanLeave] = useState(false);

    const handleSetHours = (nextHoursValue: number) => {
        if (nextHoursValue < timerValues.maxHours) {
            setHours(nextHoursValue);
        }
    };

    const handleSetMinutes = (nextMinutesValue: number) => {
        if (
            nextMinutesValue > timerValues.maxMinutes &&
            hours < timerValues.maxHours
        ) {
            handleSetHours(hours + 1);
            setMinutes(0);
        } else {
            setMinutes(nextMinutesValue);
        }
    };

    const footerContent = (
        <Grid>
            <Cell xs={6}>
                <Button
                    onClick={onCancel}
                    variant="tertiary"
                    modifier="fullWidth"
                    data-testid={TestId.CancelBreakoutSettings}
                >
                    {t('common.cancel', 'Cancel')}
                </Button>
            </Cell>
            <Cell xs={6}>
                <Button
                    type="submit"
                    onClick={() => onSave(hours, minutes, participantsCanLeave)}
                    variant="primary"
                    modifier="fullWidth"
                    data-testid={TestId.SaveBreakoutSettings}
                >
                    {t('common.save', 'Save')}
                </Button>
            </Cell>
        </Grid>
    );

    return (
        <ThemeProvider colorScheme="light">
            <Modal
                isOpen={isOpen}
                withCloseButton={true}
                onClose={onCancel}
                closeButtonAriaLabel={t(
                    'breakouts.room-settings-modal.close-btn',
                    'Close room settings modal',
                )}
                closeOnOutsideClick={false}
                className={cx(className, styles.modalWrapper)}
                data-testid={TestId.ModalRoomSettings}
                padding="large"
                footerContent={footerContent}
                footerPadding="small"
                hasFooterShadow
                {...props}
            >
                <TextHeading
                    fontVariant={FontVariant.H5}
                    htmlTag="h5"
                    className="mt-2 mb-4"
                >
                    {t('breakouts.room-settings-modal.title', 'Room settings')}
                </TextHeading>
                <div className={cx(styles.modalSectionWrapper, 'mx-2')}>
                    <Group spacing="compact" className="mb-2">
                        <Icon source={IconTypes.IconClock}></Icon>
                        <Text fontVariant={FontVariant.BodyBold} htmlTag="span">
                            {t(
                                'breakouts.room-settings-modal.set-timer',
                                'Do you want to set a timer?',
                            )}
                        </Text>
                    </Group>

                    <Text
                        fontVariant={FontVariant.Body}
                        htmlTag="p"
                        color={Color.Neutral70}
                        className="mb-6"
                    >
                        {t(
                            'breakouts.room-settings-modal.set-timer-description',
                            'When the time is up participants will be moved to join the main room',
                        )}
                    </Text>

                    <Grid className="mb-1">
                        <Cell xs={3} className={styles.timerTextWrapper}>
                            <Text
                                className={styles.timerText}
                                fontVariant={FontVariant.BodyBold}
                                htmlTag="p"
                            >
                                {t(
                                    'breakouts.room-settings-modal.set-timer-hours',
                                    'Hours',
                                )}
                            </Text>
                        </Cell>
                        <Cell xs={9}>
                            <NumberStepInput
                                colorScheme="light"
                                label="Hours"
                                name="hoursInput"
                                value={hours}
                                onChange={handleSetHours}
                            />
                        </Cell>
                    </Grid>

                    <Grid>
                        <Cell xs={3} className={styles.timerTextWrapper}>
                            <Text
                                className={styles.timerText}
                                fontVariant={FontVariant.BodyBold}
                                htmlTag="p"
                            >
                                {t(
                                    'breakouts.room-settings-modal.set-timer-minutes',
                                    'Minutes',
                                )}
                            </Text>
                        </Cell>
                        <Cell xs={9}>
                            <NumberStepInput
                                colorScheme="light"
                                label="Minutes"
                                name="minutesInput"
                                value={minutes}
                                onChange={handleSetMinutes}
                            />
                        </Cell>
                    </Grid>
                </div>
                <div className={cx(styles.line, 'my-6')} />
                <div className={cx(styles.modalSectionWrapper, 'mx-2')}>
                    <Group spacing="compact" className="mb-6">
                        <Icon source={IconTypes.IconLeave}></Icon>
                        <Text fontVariant={FontVariant.BodyBold} htmlTag="span">
                            {t(
                                'breakouts.room-settings-modal.leave-room-options',
                                'Options for leaving the rooms',
                            )}
                        </Text>
                    </Group>
                    <Checkbox
                        checked={participantsCanLeave}
                        label="Allow participants to leave at any time"
                        name="Participants can leave at any time checkbox"
                        onChange={() =>
                            setParticipantsCanLeave(!participantsCanLeave)
                        }
                        className="mb-2"
                    />
                    <Text
                        fontVariant={FontVariant.Body}
                        htmlTag="p"
                        color={Color.Neutral70}
                        className="mb-4"
                    >
                        {t(
                            'breakouts.room-settings-modal.leave-room-options-description',
                            'By default, participants cannot leave a breakout room while it is open. When this is selected, participants can return to the main room at any time.',
                        )}
                    </Text>
                </div>
            </Modal>
        </ThemeProvider>
    );
};

export type RoomSettingsModalViewProps = React.ComponentProps<
    typeof RoomSettingsModalView
>;
