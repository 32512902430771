import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonVariant, MenuContent} from '@pexip/components';
import {AboutModal, Menu, UserMenuDropContent, Text} from '@pexip/components';
import type {AutoHideButtonCallbacks} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';
import {useBranding} from '../../branding/Context';

export const UserMenuView: React.FC<{
    menuContent: MenuContent;
    autoHideProps?: AutoHideButtonCallbacks;
    version: string;
    pluginApiVersion: string;
    variant?: ButtonVariant;
    isAboutOpen: boolean;
    setAboutOpen: React.Dispatch<React.SetStateAction<boolean>>;
    termsOfServiceUrl?: string;
}> = ({
    autoHideProps,
    isAboutOpen,
    menuContent,
    version,
    pluginApiVersion,
    setAboutOpen,
    termsOfServiceUrl,
}) => {
    const {t} = useTranslation();
    const overlay = useBranding('overlay');

    return (
        <>
            <UserMenuDropContent
                position="bottomRight"
                content={
                    <Menu testId={TestId.UserMenu} menuContent={menuContent} />
                }
                data-testid={TestId.UserMenuButton}
                modifier="square"
                colorScheme={overlay}
                variant="translucent"
                {...autoHideProps}
            />
            <AboutModal
                isOpen={isAboutOpen}
                onClose={() => setAboutOpen(false)}
                version={version}
                data-testid={TestId.UserMenuAboutPexipModal}
                headingText={t('common.about-pexip', 'About Pexip')}
                privacyPolicyUrl=""
                privacyPolicyLinkText=""
                termsOfServiceUrl={termsOfServiceUrl}
                termsOfServiceLinkText={t(
                    'common.terms-of-service',
                    'Terms of Service',
                )}
                aboutTextEnhancerEnd={
                    <Text>Plugin API Version: {pluginApiVersion}</Text>
                }
            />
        </>
    );
};

export type UserMenuViewProps = React.ComponentProps<typeof UserMenuView>;
