import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {Button, onEnter} from '@pexip/components';

import styles from './JoinMeetingButton.module.scss';

export const JoinMeetingButton: React.FC<
    ButtonProps & {
        isDisabled?: boolean;
        onClick: () => void;
    }
> = ({isDisabled, onClick, className, ...props}) => {
    const {t} = useTranslation();

    return (
        // FIXME: Avoid block Typescript upgrade because of this ref incompatibility issue
        // @ts-expect-error --- fix later
        <Button
            className={cx(styles.joinMeetingButtonWrapper, className)}
            isDisabled={isDisabled}
            onClick={onClick}
            onKeyDown={onEnter(onClick)}
            size="large"
            type="submit"
            variant="success"
            {...props}
        >
            {t('common.join-now', 'Join now')}
        </Button>
    );
};
