import type {Dispatch, SetStateAction} from 'react';
import {useCallback, useState} from 'react';

export interface Preview<T> {
    preview: T;
    setPreview: Dispatch<SetStateAction<T>>;
    applyChanges: () => boolean;
}

export const createPreviewHook =
    <T>({get, set}: {get: () => T; set: (value: T) => boolean}) =>
    (): Preview<T> => {
        const [preview, setPreview] = useState(get);

        const applyChanges = useCallback(() => {
            if (get() !== preview) {
                return set(preview);
            }
            return false;
        }, [preview]);

        return {preview, setPreview, applyChanges};
    };
