import React from 'react';
import cx from 'classnames';

import {Text} from '../Text/Text';
import {FontVariant} from '../../../../design-tokens/generated/constants';
import {TestId} from '../../../utils/testIds';

import {useBadgeCounterAnimation} from './useBadgeCounterAnimation';
import {useDisplayedNumber} from './useDisplayedNumber';

import styles from './BadgeCounter.module.scss';

export const BadgeCounter: React.FC<
    React.ComponentProps<'div'> & {
        number: number;
        children: React.ReactElement;
        isVisible: boolean;
        shouldAnimate?: boolean;
        isTruncated?: boolean;
    }
> = ({
    children,
    number,
    isVisible,
    shouldAnimate = true,
    isTruncated = false,
    ...props
}) => {
    const isAnimating = useBadgeCounterAnimation(number, shouldAnimate);
    const displayedNumber = useDisplayedNumber(number);

    return (
        <div
            className={cx(styles.wrapper, {
                [styles.truncated]: isTruncated,
            })}
            {...props}
        >
            <div>{children}</div>
            <div
                data-testid={TestId.BadgeCounterNumber}
                className={cx(styles.counter, {
                    [styles.animated]: isAnimating,
                    [styles.visible]: isVisible,
                })}
            >
                <Text
                    fontVariant={FontVariant.BodyBold}
                    className={styles.number}
                >
                    {displayedNumber}
                </Text>
            </div>
        </div>
    );
};
