import React from 'react';

import {BadgeCounter, IconTypes} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {ResponsiveButton} from '../ResponsiveButton/ResponsiveButton.view';

export const BreakoutRoomsPanelToggleView: React.FC<
    React.ComponentProps<'button'> & {
        buttonAriaLabel: string;
        buttonText: string;
        hideButtonText: boolean;
        isPanelOpen: boolean;
        breakoutActionsCount: number;
    }
> = ({
    breakoutActionsCount,
    isPanelOpen,
    hideButtonText,
    buttonAriaLabel,
    buttonText,
    ...props
}) => {
    return (
        <BadgeCounter
            data-testid={TestId.BadgeCounterBreakoutRoomsPanelButton}
            number={breakoutActionsCount}
            isVisible={!isPanelOpen && breakoutActionsCount > 0}
            isTruncated={breakoutActionsCount > 9}
        >
            <ResponsiveButton
                aria-label={buttonAriaLabel}
                data-testid={TestId.ButtonBreakoutRooms}
                iconSource={IconTypes.IconBreakoutRooms}
                isActive={isPanelOpen}
                hideChildren={hideButtonText}
                {...props}
            >
                {buttonText}
            </ResponsiveButton>
        </BadgeCounter>
    );
};

export type BreakoutRoomsPanelToggleViewProps = React.ComponentProps<
    typeof BreakoutRoomsPanelToggleView
>;
