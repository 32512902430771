import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Box,
    Text,
    FontVariant,
    Button,
    Icon,
    IconTypes,
    ConfirmationModal,
    Divider,
} from '@pexip/components';

import type {
    BreakoutRoomId,
    BreakoutRoomName,
    InMeetingParticipant,
} from '../../types';
import {BreakoutRoomVariant} from '../../types';
import {BreakoutRoomParticipantList} from '../BreakoutRoomParticipantList/BreakoutRoomParticipantList.view';
import {BreakoutRoomHeader} from '../BreakoutRoomHeader/BreakoutRoomHeader.view';
import {MainBreakoutRoomId} from '../../constants';
import {TestId} from '../../../test/testIds';
import {ResponsiveButton} from '../ResponsiveButton/ResponsiveButton.view';

import styles from './BreakoutRoom.module.scss';

export const BreakoutRoom = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<typeof Box> & {
        id: BreakoutRoomId;
        name: BreakoutRoomName;
        participants: InMeetingParticipant[];
        myIdentity?: string;
        rooms?: Record<BreakoutRoomId, BreakoutRoomName>;
        variant?: BreakoutRoomVariant;
        isDragTarget?: boolean;
        scrollElementRef?: React.RefObject<HTMLElement>;
        onNameChanged?: (id: string, name: string) => boolean | undefined;
        onParticipantDragging?: (participantDiv: HTMLDivElement) => void;
        onParticipantDrag?: (participantDiv: HTMLDivElement) => void;
        onParticipantRoomChange?: (
            targetParticipantUuid: string,
            targetRoomId: string,
        ) => void;
        _onAssignParticipants?: () => void;
        onJoinRoom?: (id: string) => void;
        onCloseRoom?: (id: string) => void;
        onRemoveRoom?: (id: string) => void;
    }
>(
    (
        {
            id,
            name,
            participants,
            myIdentity,
            rooms,
            variant = BreakoutRoomVariant.Setup,
            onNameChanged,
            isDragTarget,
            scrollElementRef,
            onParticipantDragging,
            onParticipantDrag,
            onParticipantRoomChange,
            _onAssignParticipants,
            onJoinRoom,
            onCloseRoom,
            onRemoveRoom,
            ...props
        },
        ref,
    ) => {
        const {t} = useTranslation();

        const [showingParticipants, setShowingParticipants] = useState(true);
        const [isCloseRoomModalOpen, setIsCloseRoomModalOpen] = useState(false);

        const isMainRoom = id === MainBreakoutRoomId;
        const shouldShowNameEditButton =
            !isMainRoom && variant === BreakoutRoomVariant.Setup;
        const iAmInThisRoom = participants.some(
            ({identity}) => identity === myIdentity,
        );

        const handleNameChange = useCallback(
            (name: string) => onNameChanged?.(id, name),
            [id, onNameChanged],
        );

        const closeRoom = useCallback(() => {
            setIsCloseRoomModalOpen(false);
            onCloseRoom?.(id);
        }, [id, onCloseRoom]);

        const ParticipantsToggleBtn = () => (
            <Button
                variant="text"
                size="compact"
                enhancerStart={
                    <Icon
                        className="mr-1"
                        source={
                            showingParticipants
                                ? IconTypes.IconChevronDown
                                : IconTypes.IconChevronRight
                        }
                        size="mini"
                    />
                }
                aria-label={
                    id === MainBreakoutRoomId
                        ? t(
                              'breakouts.breakout-room.not-assigned-aria-label',
                              'Participants not assigned to a breakout room',
                          )
                        : t(
                              'breakouts.breakout-room.assigned-aria-label',
                              'Participants assigned to breakout room {{name}}',
                          )
                }
                onClick={() =>
                    setShowingParticipants(
                        currentlyShowing => !currentlyShowing,
                    )
                }
                underlineText={false}
            >
                <Text fontVariant={FontVariant.SmallBold} className="mr-2">
                    {id === MainBreakoutRoomId
                        ? t(
                              'breakouts.breakout-room.not-assigned',
                              'Not assigned',
                          )
                        : t('breakouts.breakout-room.assigned', 'Assigned')}
                </Text>
                <Text>{participants.length}</Text>
            </Button>
        );

        const FooterStartButton = () => {
            switch (variant) {
                case BreakoutRoomVariant.Setup:
                    return null;
                case BreakoutRoomVariant.Preview:
                    return !isMainRoom ? (
                        <Button
                            variant="dangerBordered"
                            enhancerStart={
                                <Icon
                                    source={IconTypes.IconClose}
                                    size="compact"
                                />
                            }
                            onClick={() => setIsCloseRoomModalOpen(true)}
                            aria-label={t(
                                'breakouts.breakout-room.close-room-aria-label',
                                'Close breakout room',
                            )}
                            data-testid={TestId.CloseBreakoutRoom}
                        >
                            {t('common.close', 'Close')}
                        </Button>
                    ) : null;
                case BreakoutRoomVariant.Edit:
                    return null;
                // return !isMainRoom ? (
                //     <Button
                //         enhancerStart={
                //             <Icon
                //                 source={IconTypes.IconAddSomeone}
                //                 size="compact"
                //             />
                //         }
                //         onClick={onAssignParticipants}
                //         aria-label={t(
                //             'breakouts.breakout-room.assign-participants-aria-label',
                //             'Assign participants to breakout room {{name}}',
                //         )}
                //     >
                //         {t(
                //             'breakouts.breakout-room.assign-participants',
                //             'Assign Participants',
                //         )}
                //     </Button>
                // ) : null;
            }
        };

        const FooterEndButton = () => {
            switch (variant) {
                case BreakoutRoomVariant.Setup:
                case BreakoutRoomVariant.Edit:
                    return !isMainRoom ? (
                        <ResponsiveButton
                            variant="bordered"
                            iconSource={IconTypes.IconTrash}
                            hideChildren={true}
                            onClick={() => onRemoveRoom?.(id)}
                            aria-label={t(
                                'breakouts.breakout-room.remove-room-aria-label',
                                'Remove breakout room',
                            )}
                            data-testid={TestId.RemoveBreakoutRoom}
                        />
                    ) : null;
                case BreakoutRoomVariant.Preview:
                    return (
                        <Button
                            variant="success"
                            isDisabled={iAmInThisRoom}
                            aria-label={t(
                                'common.join.aria-label',
                                'Join breakout room',
                            )}
                            onClick={() => onJoinRoom?.(id)}
                            data-testid={TestId.JoinBreakoutRoom}
                        >
                            {t('common.join', 'Join')}
                        </Button>
                    );
            }
        };

        return (
            <Box
                padding="small"
                {...props}
                id={id}
                ref={ref}
                className={cx(styles.wrapper, {
                    [styles.mainRoom]: id === MainBreakoutRoomId,
                    [styles.dragTarget]: isDragTarget,
                })}
            >
                <BreakoutRoomHeader
                    className="mb-4"
                    onSaveAction={handleNameChange}
                    name={
                        isMainRoom
                            ? t(
                                  'breakouts.breakout-room.main-name',
                                  'Main Room',
                              )
                            : name
                    }
                    showEditButton={shouldShowNameEditButton}
                />
                <ParticipantsToggleBtn />
                {showingParticipants && (
                    <>
                        <BreakoutRoomParticipantList
                            scrollElementRef={scrollElementRef}
                            className="mt-2"
                            participants={participants}
                            myIdentity={myIdentity}
                            rooms={rooms}
                            onParticipantDragging={onParticipantDragging}
                            onParticipantDrag={onParticipantDrag}
                            onParticipantRoomChange={onParticipantRoomChange}
                            variant={variant}
                        />
                        {isDragTarget && (
                            <Divider
                                className={cx('mt-2', styles.dragDivider)}
                            />
                        )}
                    </>
                )}
                <div className={styles.footer}>
                    <FooterStartButton />
                    <FooterEndButton />
                </div>
                <ConfirmationModal
                    cancelButtonText={t('common.cancel', 'Cancel')}
                    onCancel={() => setIsCloseRoomModalOpen(false)}
                    confirmButtonText={t('common.yes', 'Yes')}
                    onConfirm={closeRoom}
                    title={t('breakouts.close-room-modal.title', {
                        defaultValue: 'Close {{name}}?',
                        name,
                    })}
                    description={t(
                        'breakouts.close-room-modal.text',
                        'The participants in this room will be moved to the main room.',
                    )}
                    isOpen={isCloseRoomModalOpen}
                />
            </Box>
        );
    },
);
BreakoutRoom.displayName = 'BreakoutRoom';
