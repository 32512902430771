'use strict';
export const validateDialBody = validate11;
const schema12 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                role: {
                    type: 'string',
                    enum: ['HOST', 'GUEST'],
                    description:
                        'The level of privileges the participant has in the conference.',
                },
                destination: {
                    type: 'string',
                    description: 'The target address to call.',
                },
                protocol: {
                    type: 'string',
                    enum: ['sip', 'h323', 'rtmp', 'mssip', 'auto'],
                    description:
                        'The protocol to use to place the outgoing call',
                },
                presentation_url: {
                    type: 'string',
                    description:
                        'This additional parameter can be specified for RTMP calls to send the presentation stream to a separate RTMP destination.',
                },
                streaming: {
                    type: 'string',
                    enum: ['yes', 'no'],
                    default: 'no',
                    description:
                        'Identifies the dialed participant as a streaming or recording device.',
                },
                dtmf_sequence: {
                    type: 'string',
                    description:
                        'An optional DTMF sequence to transmit after the call to the dialed participant starts.',
                },
                source_display_name: {
                    type: 'string',
                    description:
                        'Optional field that specifies what the calling display name should be.',
                },
                source: {
                    type: 'string',
                    description:
                        'Optional field that specifies the source URI (must be a valid URI for the conference).',
                },
                call_type: {
                    type: 'string',
                    enum: ['video', 'video-only', 'audio'],
                    default: ['video'],
                    description:
                        'Optional field that limits the media content of the call.',
                },
                keep_conference_alive: {
                    type: 'string',
                    enum: [
                        'keep_conference_alive',
                        'keep_conference_alive_if_multiple',
                        'keep_conference_alive_never',
                    ],
                    description:
                        'Determines whether the conference continues when all other non-ADP participants have disconnected. Default: `keep_conference_alive` for non-streaming participants, and `keep_conference_alive_never` for streaming participants.',
                },
                remote_display_name: {
                    type: 'string',
                    description:
                        "An optional friendly name for this participant. This may be used instead of the participant's alias in participant lists and as a text overlay in some layout configurations.",
                },
                text: {
                    type: 'string',
                    description:
                        'Optional text to use instead of remote_display_name as the participant name overlay text.',
                },
            },
            required: ['role', 'destination', 'protocol'],
            title: 'TopLevel',
        },
    },
    oneOf: [{$ref: '#/definitions/dial/properties/Body/definitions/TopLevel'}],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        role: {
            type: 'string',
            enum: ['HOST', 'GUEST'],
            description:
                'The level of privileges the participant has in the conference.',
        },
        destination: {
            type: 'string',
            description: 'The target address to call.',
        },
        protocol: {
            type: 'string',
            enum: ['sip', 'h323', 'rtmp', 'mssip', 'auto'],
            description: 'The protocol to use to place the outgoing call',
        },
        presentation_url: {
            type: 'string',
            description:
                'This additional parameter can be specified for RTMP calls to send the presentation stream to a separate RTMP destination.',
        },
        streaming: {
            type: 'string',
            enum: ['yes', 'no'],
            default: 'no',
            description:
                'Identifies the dialed participant as a streaming or recording device.',
        },
        dtmf_sequence: {
            type: 'string',
            description:
                'An optional DTMF sequence to transmit after the call to the dialed participant starts.',
        },
        source_display_name: {
            type: 'string',
            description:
                'Optional field that specifies what the calling display name should be.',
        },
        source: {
            type: 'string',
            description:
                'Optional field that specifies the source URI (must be a valid URI for the conference).',
        },
        call_type: {
            type: 'string',
            enum: ['video', 'video-only', 'audio'],
            default: ['video'],
            description:
                'Optional field that limits the media content of the call.',
        },
        keep_conference_alive: {
            type: 'string',
            enum: [
                'keep_conference_alive',
                'keep_conference_alive_if_multiple',
                'keep_conference_alive_never',
            ],
            description:
                'Determines whether the conference continues when all other non-ADP participants have disconnected. Default: `keep_conference_alive` for non-streaming participants, and `keep_conference_alive_never` for streaming participants.',
        },
        remote_display_name: {
            type: 'string',
            description:
                "An optional friendly name for this participant. This may be used instead of the participant's alias in participant lists and as a text overlay in some layout configurations.",
        },
        text: {
            type: 'string',
            description:
                'Optional text to use instead of remote_display_name as the participant name overlay text.',
        },
    },
    required: ['role', 'destination', 'protocol'],
    title: 'TopLevel',
};
const func2 = Object.prototype.hasOwnProperty;
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.role === undefined && (missing0 = 'role')) ||
                (data.destination === undefined &&
                    (missing0 = 'destination')) ||
                (data.protocol === undefined && (missing0 = 'protocol'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/dial/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!func2.call(schema13.properties, key0)) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.role !== undefined) {
                        let data0 = data.role;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/dial/properties/Body/definitions/TopLevel/properties/role/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'HOST' || data0 === 'GUEST')) {
                            const err2 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/dial/properties/Body/definitions/TopLevel/properties/role/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema13.properties.role.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.destination !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.destination !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/destination',
                                    schemaPath:
                                        '#/definitions/dial/properties/Body/definitions/TopLevel/properties/destination/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.protocol !== undefined) {
                                let data2 = data.protocol;
                                const _errs9 = errors;
                                if (typeof data2 !== 'string') {
                                    const err4 = {
                                        instancePath:
                                            instancePath + '/protocol',
                                        schemaPath:
                                            '#/definitions/dial/properties/Body/definitions/TopLevel/properties/protocol/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                if (
                                    !(
                                        data2 === 'sip' ||
                                        data2 === 'h323' ||
                                        data2 === 'rtmp' ||
                                        data2 === 'mssip' ||
                                        data2 === 'auto'
                                    )
                                ) {
                                    const err5 = {
                                        instancePath:
                                            instancePath + '/protocol',
                                        schemaPath:
                                            '#/definitions/dial/properties/Body/definitions/TopLevel/properties/protocol/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema13.properties.protocol
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.presentation_url !== undefined) {
                                    const _errs11 = errors;
                                    if (
                                        typeof data.presentation_url !==
                                        'string'
                                    ) {
                                        const err6 = {
                                            instancePath:
                                                instancePath +
                                                '/presentation_url',
                                            schemaPath:
                                                '#/definitions/dial/properties/Body/definitions/TopLevel/properties/presentation_url/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.streaming !== undefined) {
                                        let data4 = data.streaming;
                                        const _errs13 = errors;
                                        if (typeof data4 !== 'string') {
                                            const err7 = {
                                                instancePath:
                                                    instancePath + '/streaming',
                                                schemaPath:
                                                    '#/definitions/dial/properties/Body/definitions/TopLevel/properties/streaming/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err7];
                                            } else {
                                                vErrors.push(err7);
                                            }
                                            errors++;
                                        }
                                        if (
                                            !(data4 === 'yes' || data4 === 'no')
                                        ) {
                                            const err8 = {
                                                instancePath:
                                                    instancePath + '/streaming',
                                                schemaPath:
                                                    '#/definitions/dial/properties/Body/definitions/TopLevel/properties/streaming/enum',
                                                keyword: 'enum',
                                                params: {
                                                    allowedValues:
                                                        schema13.properties
                                                            .streaming.enum,
                                                },
                                                message:
                                                    'must be equal to one of the allowed values',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err8];
                                            } else {
                                                vErrors.push(err8);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                    if (valid2) {
                                        if (data.dtmf_sequence !== undefined) {
                                            const _errs15 = errors;
                                            if (
                                                typeof data.dtmf_sequence !==
                                                'string'
                                            ) {
                                                const err9 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/dtmf_sequence',
                                                    schemaPath:
                                                        '#/definitions/dial/properties/Body/definitions/TopLevel/properties/dtmf_sequence/type',
                                                    keyword: 'type',
                                                    params: {type: 'string'},
                                                    message: 'must be string',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err9];
                                                } else {
                                                    vErrors.push(err9);
                                                }
                                                errors++;
                                            }
                                            var valid2 = _errs15 === errors;
                                        } else {
                                            var valid2 = true;
                                        }
                                        if (valid2) {
                                            if (
                                                data.source_display_name !==
                                                undefined
                                            ) {
                                                const _errs17 = errors;
                                                if (
                                                    typeof data.source_display_name !==
                                                    'string'
                                                ) {
                                                    const err10 = {
                                                        instancePath:
                                                            instancePath +
                                                            '/source_display_name',
                                                        schemaPath:
                                                            '#/definitions/dial/properties/Body/definitions/TopLevel/properties/source_display_name/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err10];
                                                    } else {
                                                        vErrors.push(err10);
                                                    }
                                                    errors++;
                                                }
                                                var valid2 = _errs17 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data.source !== undefined) {
                                                    const _errs19 = errors;
                                                    if (
                                                        typeof data.source !==
                                                        'string'
                                                    ) {
                                                        const err11 = {
                                                            instancePath:
                                                                instancePath +
                                                                '/source',
                                                            schemaPath:
                                                                '#/definitions/dial/properties/Body/definitions/TopLevel/properties/source/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err11];
                                                        } else {
                                                            vErrors.push(err11);
                                                        }
                                                        errors++;
                                                    }
                                                    var valid2 =
                                                        _errs19 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data.call_type !==
                                                        undefined
                                                    ) {
                                                        let data8 =
                                                            data.call_type;
                                                        const _errs21 = errors;
                                                        if (
                                                            typeof data8 !==
                                                            'string'
                                                        ) {
                                                            const err12 = {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/call_type',
                                                                schemaPath:
                                                                    '#/definitions/dial/properties/Body/definitions/TopLevel/properties/call_type/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            };
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err12,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err12,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                        if (
                                                            !(
                                                                data8 ===
                                                                    'video' ||
                                                                data8 ===
                                                                    'video-only' ||
                                                                data8 ===
                                                                    'audio'
                                                            )
                                                        ) {
                                                            const err13 = {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/call_type',
                                                                schemaPath:
                                                                    '#/definitions/dial/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                                                keyword: 'enum',
                                                                params: {
                                                                    allowedValues:
                                                                        schema13
                                                                            .properties
                                                                            .call_type
                                                                            .enum,
                                                                },
                                                                message:
                                                                    'must be equal to one of the allowed values',
                                                            };
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err13,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err13,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                        var valid2 =
                                                            _errs21 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (
                                                            data.keep_conference_alive !==
                                                            undefined
                                                        ) {
                                                            let data9 =
                                                                data.keep_conference_alive;
                                                            const _errs23 =
                                                                errors;
                                                            if (
                                                                typeof data9 !==
                                                                'string'
                                                            ) {
                                                                const err14 = {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/keep_conference_alive',
                                                                    schemaPath:
                                                                        '#/definitions/dial/properties/Body/definitions/TopLevel/properties/keep_conference_alive/type',
                                                                    keyword:
                                                                        'type',
                                                                    params: {
                                                                        type: 'string',
                                                                    },
                                                                    message:
                                                                        'must be string',
                                                                };
                                                                if (
                                                                    vErrors ===
                                                                    null
                                                                ) {
                                                                    vErrors = [
                                                                        err14,
                                                                    ];
                                                                } else {
                                                                    vErrors.push(
                                                                        err14,
                                                                    );
                                                                }
                                                                errors++;
                                                            }
                                                            if (
                                                                !(
                                                                    data9 ===
                                                                        'keep_conference_alive' ||
                                                                    data9 ===
                                                                        'keep_conference_alive_if_multiple' ||
                                                                    data9 ===
                                                                        'keep_conference_alive_never'
                                                                )
                                                            ) {
                                                                const err15 = {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/keep_conference_alive',
                                                                    schemaPath:
                                                                        '#/definitions/dial/properties/Body/definitions/TopLevel/properties/keep_conference_alive/enum',
                                                                    keyword:
                                                                        'enum',
                                                                    params: {
                                                                        allowedValues:
                                                                            schema13
                                                                                .properties
                                                                                .keep_conference_alive
                                                                                .enum,
                                                                    },
                                                                    message:
                                                                        'must be equal to one of the allowed values',
                                                                };
                                                                if (
                                                                    vErrors ===
                                                                    null
                                                                ) {
                                                                    vErrors = [
                                                                        err15,
                                                                    ];
                                                                } else {
                                                                    vErrors.push(
                                                                        err15,
                                                                    );
                                                                }
                                                                errors++;
                                                            }
                                                            var valid2 =
                                                                _errs23 ===
                                                                errors;
                                                        } else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (
                                                                data.remote_display_name !==
                                                                undefined
                                                            ) {
                                                                const _errs25 =
                                                                    errors;
                                                                if (
                                                                    typeof data.remote_display_name !==
                                                                    'string'
                                                                ) {
                                                                    const err16 =
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/remote_display_name',
                                                                            schemaPath:
                                                                                '#/definitions/dial/properties/Body/definitions/TopLevel/properties/remote_display_name/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'string',
                                                                            },
                                                                            message:
                                                                                'must be string',
                                                                        };
                                                                    if (
                                                                        vErrors ===
                                                                        null
                                                                    ) {
                                                                        vErrors =
                                                                            [
                                                                                err16,
                                                                            ];
                                                                    } else {
                                                                        vErrors.push(
                                                                            err16,
                                                                        );
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid2 =
                                                                    _errs25 ===
                                                                    errors;
                                                            } else {
                                                                var valid2 =
                                                                    true;
                                                            }
                                                            if (valid2) {
                                                                if (
                                                                    data.text !==
                                                                    undefined
                                                                ) {
                                                                    const _errs27 =
                                                                        errors;
                                                                    if (
                                                                        typeof data.text !==
                                                                        'string'
                                                                    ) {
                                                                        const err17 =
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/text',
                                                                                schemaPath:
                                                                                    '#/definitions/dial/properties/Body/definitions/TopLevel/properties/text/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            };
                                                                        if (
                                                                            vErrors ===
                                                                            null
                                                                        ) {
                                                                            vErrors =
                                                                                [
                                                                                    err17,
                                                                                ];
                                                                        } else {
                                                                            vErrors.push(
                                                                                err17,
                                                                            );
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    var valid2 =
                                                                        _errs27 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err18 = {
                instancePath,
                schemaPath:
                    '#/definitions/dial/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err18];
            } else {
                vErrors.push(err18);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err19 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err19];
        } else {
            vErrors.push(err19);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
export const validateDial200 = validate12;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'array',
                    description:
                        'The response is an array of UUIDs of new participants',
                    items: {type: 'string'},
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/definitions/dial/properties/200/definitions/200'}],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'array',
            description:
                'The response is an array of UUIDs of new participants',
            items: {type: 'string'},
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/dial/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/dial/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/dial/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (errors === _errs7) {
                                if (Array.isArray(data1)) {
                                    var valid3 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        const _errs9 = errors;
                                        if (typeof data1[i0] !== 'string') {
                                            const err3 = {
                                                instancePath:
                                                    instancePath +
                                                    '/result/' +
                                                    i0,
                                                schemaPath:
                                                    '#/definitions/dial/properties/200/definitions/200/properties/result/items/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err3];
                                            } else {
                                                vErrors.push(err3);
                                            }
                                            errors++;
                                        }
                                        var valid3 = _errs9 === errors;
                                        if (!valid3) {
                                            break;
                                        }
                                    }
                                } else {
                                    const err4 = {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/definitions/dial/properties/200/definitions/200/properties/result/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/dial/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
export const validateConferenceStatus200 = validate13;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/conference_status/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                breakout: {
                    type: 'boolean',
                    description: 'It is a breakout room or not',
                },
                breakout_name: {
                    type: 'string',
                    description:
                        'The name of the breakout room. Only available when breakout is true',
                },
                breakout_description: {
                    type: 'string',
                    description:
                        'The description of the breakout room. Only available when breakout is true',
                },
                end_action: {
                    type: 'string',
                    description:
                        'The end action when the breakout room is ended triggered by the end time. Only available when breakout is true',
                    enum: ['transfer', 'disconnect'],
                },
                end_time: {
                    type: 'number',
                    description:
                        'The time when breakout ends (in seconds since epoch as floating point number). Only available when breakout is true',
                },
                locked: {
                    type: 'boolean',
                    description: 'Lock status of the conference',
                },
                guests_muted: {
                    type: 'boolean',
                    description: 'Whether Guests are muted',
                },
                started: {
                    type: 'boolean',
                    description: 'If the conference has been started',
                },
                live_captions_available: {
                    type: 'boolean',
                    description:
                        'If live captions is available for the conference',
                },
                direct_media: {type: 'boolean'},
                all_muted: {type: 'boolean'},
                presentation_allowed: {type: 'boolean'},
                breakout_rooms: {
                    type: 'boolean',
                    description:
                        'If breakout rooms is available for the conference',
                },
                classification: {
                    type: 'object',
                    description: 'conference classification',
                },
                breakout_guests_allowed_to_leave: {
                    type: 'boolean',
                    description: 'If guests can leave a breakout at any time',
                },
                breakoutbuzz: {
                    type: 'object',
                    description:
                        'info about if a breakout room has asked for help',
                },
            },
            required: ['locked', 'guests_muted', 'started'],
            title: 'Result',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/conference_status/properties/200/definitions/200',
        },
    ],
};
const schema17 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/conference_status/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema18 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        breakout: {
            type: 'boolean',
            description: 'It is a breakout room or not',
        },
        breakout_name: {
            type: 'string',
            description:
                'The name of the breakout room. Only available when breakout is true',
        },
        breakout_description: {
            type: 'string',
            description:
                'The description of the breakout room. Only available when breakout is true',
        },
        end_action: {
            type: 'string',
            description:
                'The end action when the breakout room is ended triggered by the end time. Only available when breakout is true',
            enum: ['transfer', 'disconnect'],
        },
        end_time: {
            type: 'number',
            description:
                'The time when breakout ends (in seconds since epoch as floating point number). Only available when breakout is true',
        },
        locked: {type: 'boolean', description: 'Lock status of the conference'},
        guests_muted: {
            type: 'boolean',
            description: 'Whether Guests are muted',
        },
        started: {
            type: 'boolean',
            description: 'If the conference has been started',
        },
        live_captions_available: {
            type: 'boolean',
            description: 'If live captions is available for the conference',
        },
        direct_media: {type: 'boolean'},
        all_muted: {type: 'boolean'},
        presentation_allowed: {type: 'boolean'},
        breakout_rooms: {
            type: 'boolean',
            description: 'If breakout rooms is available for the conference',
        },
        classification: {
            type: 'object',
            description: 'conference classification',
        },
        breakout_guests_allowed_to_leave: {
            type: 'boolean',
            description: 'If guests can leave a breakout at any time',
        },
        breakoutbuzz: {
            type: 'object',
            description: 'info about if a breakout room has asked for help',
        },
    },
    required: ['locked', 'guests_muted', 'started'],
    title: 'Result',
};
function validate14(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate14.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate14.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate14.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema17.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.locked === undefined &&
                                            (missing1 = 'locked')) ||
                                        (data1.guests_muted === undefined &&
                                            (missing1 = 'guests_muted')) ||
                                        (data1.started === undefined &&
                                            (missing1 = 'started'))
                                    ) {
                                        validate14.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/definitions/conference_status/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !func2.call(
                                                    schema18.properties,
                                                    key1,
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.breakout !== undefined) {
                                                const _errs8 = errors;
                                                if (
                                                    typeof data1.breakout !==
                                                    'boolean'
                                                ) {
                                                    validate14.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/breakout',
                                                            schemaPath:
                                                                '#/definitions/conference_status/properties/200/definitions/Result/properties/breakout/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs8 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (
                                                    data1.breakout_name !==
                                                    undefined
                                                ) {
                                                    const _errs10 = errors;
                                                    if (
                                                        typeof data1.breakout_name !==
                                                        'string'
                                                    ) {
                                                        validate14.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/breakout_name',
                                                                schemaPath:
                                                                    '#/definitions/conference_status/properties/200/definitions/Result/properties/breakout_name/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs10 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data1.breakout_description !==
                                                        undefined
                                                    ) {
                                                        const _errs12 = errors;
                                                        if (
                                                            typeof data1.breakout_description !==
                                                            'string'
                                                        ) {
                                                            validate14.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/result/breakout_description',
                                                                        schemaPath:
                                                                            '#/definitions/conference_status/properties/200/definitions/Result/properties/breakout_description/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'string',
                                                                        },
                                                                        message:
                                                                            'must be string',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs12 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (
                                                            data1.end_action !==
                                                            undefined
                                                        ) {
                                                            let data5 =
                                                                data1.end_action;
                                                            const _errs14 =
                                                                errors;
                                                            if (
                                                                typeof data5 !==
                                                                'string'
                                                            ) {
                                                                validate14.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/result/end_action',
                                                                            schemaPath:
                                                                                '#/definitions/conference_status/properties/200/definitions/Result/properties/end_action/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'string',
                                                                            },
                                                                            message:
                                                                                'must be string',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            if (
                                                                !(
                                                                    data5 ===
                                                                        'transfer' ||
                                                                    data5 ===
                                                                        'disconnect'
                                                                )
                                                            ) {
                                                                validate14.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/result/end_action',
                                                                            schemaPath:
                                                                                '#/definitions/conference_status/properties/200/definitions/Result/properties/end_action/enum',
                                                                            keyword:
                                                                                'enum',
                                                                            params: {
                                                                                allowedValues:
                                                                                    schema18
                                                                                        .properties
                                                                                        .end_action
                                                                                        .enum,
                                                                            },
                                                                            message:
                                                                                'must be equal to one of the allowed values',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid2 =
                                                                _errs14 ===
                                                                errors;
                                                        } else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (
                                                                data1.end_time !==
                                                                undefined
                                                            ) {
                                                                const _errs16 =
                                                                    errors;
                                                                if (
                                                                    !(
                                                                        typeof data1.end_time ==
                                                                        'number'
                                                                    )
                                                                ) {
                                                                    validate14.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/result/end_time',
                                                                                schemaPath:
                                                                                    '#/definitions/conference_status/properties/200/definitions/Result/properties/end_time/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'number',
                                                                                },
                                                                                message:
                                                                                    'must be number',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid2 =
                                                                    _errs16 ===
                                                                    errors;
                                                            } else {
                                                                var valid2 =
                                                                    true;
                                                            }
                                                            if (valid2) {
                                                                if (
                                                                    data1.locked !==
                                                                    undefined
                                                                ) {
                                                                    const _errs18 =
                                                                        errors;
                                                                    if (
                                                                        typeof data1.locked !==
                                                                        'boolean'
                                                                    ) {
                                                                        validate14.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/result/locked',
                                                                                    schemaPath:
                                                                                        '#/definitions/conference_status/properties/200/definitions/Result/properties/locked/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'boolean',
                                                                                    },
                                                                                    message:
                                                                                        'must be boolean',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid2 =
                                                                        _errs18 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                                if (valid2) {
                                                                    if (
                                                                        data1.guests_muted !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs20 =
                                                                            errors;
                                                                        if (
                                                                            typeof data1.guests_muted !==
                                                                            'boolean'
                                                                        ) {
                                                                            validate14.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/result/guests_muted',
                                                                                        schemaPath:
                                                                                            '#/definitions/conference_status/properties/200/definitions/Result/properties/guests_muted/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'boolean',
                                                                                        },
                                                                                        message:
                                                                                            'must be boolean',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid2 =
                                                                            _errs20 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid2 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid2
                                                                    ) {
                                                                        if (
                                                                            data1.started !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs22 =
                                                                                errors;
                                                                            if (
                                                                                typeof data1.started !==
                                                                                'boolean'
                                                                            ) {
                                                                                validate14.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/result/started',
                                                                                            schemaPath:
                                                                                                '#/definitions/conference_status/properties/200/definitions/Result/properties/started/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'boolean',
                                                                                            },
                                                                                            message:
                                                                                                'must be boolean',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid2 =
                                                                                _errs22 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid2 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid2
                                                                        ) {
                                                                            if (
                                                                                data1.live_captions_available !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs24 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data1.live_captions_available !==
                                                                                    'boolean'
                                                                                ) {
                                                                                    validate14.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/result/live_captions_available',
                                                                                                schemaPath:
                                                                                                    '#/definitions/conference_status/properties/200/definitions/Result/properties/live_captions_available/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'boolean',
                                                                                                },
                                                                                                message:
                                                                                                    'must be boolean',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid2 =
                                                                                    _errs24 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid2 =
                                                                                    true;
                                                                            }
                                                                            if (
                                                                                valid2
                                                                            ) {
                                                                                if (
                                                                                    data1.direct_media !==
                                                                                    undefined
                                                                                ) {
                                                                                    const _errs26 =
                                                                                        errors;
                                                                                    if (
                                                                                        typeof data1.direct_media !==
                                                                                        'boolean'
                                                                                    ) {
                                                                                        validate14.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/result/direct_media',
                                                                                                    schemaPath:
                                                                                                        '#/definitions/conference_status/properties/200/definitions/Result/properties/direct_media/type',
                                                                                                    keyword:
                                                                                                        'type',
                                                                                                    params: {
                                                                                                        type: 'boolean',
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be boolean',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid2 =
                                                                                        _errs26 ===
                                                                                        errors;
                                                                                } else {
                                                                                    var valid2 =
                                                                                        true;
                                                                                }
                                                                                if (
                                                                                    valid2
                                                                                ) {
                                                                                    if (
                                                                                        data1.all_muted !==
                                                                                        undefined
                                                                                    ) {
                                                                                        const _errs28 =
                                                                                            errors;
                                                                                        if (
                                                                                            typeof data1.all_muted !==
                                                                                            'boolean'
                                                                                        ) {
                                                                                            validate14.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/result/all_muted',
                                                                                                        schemaPath:
                                                                                                            '#/definitions/conference_status/properties/200/definitions/Result/properties/all_muted/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'boolean',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be boolean',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid2 =
                                                                                            _errs28 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid2 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid2
                                                                                    ) {
                                                                                        if (
                                                                                            data1.presentation_allowed !==
                                                                                            undefined
                                                                                        ) {
                                                                                            const _errs30 =
                                                                                                errors;
                                                                                            if (
                                                                                                typeof data1.presentation_allowed !==
                                                                                                'boolean'
                                                                                            ) {
                                                                                                validate14.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/result/presentation_allowed',
                                                                                                            schemaPath:
                                                                                                                '#/definitions/conference_status/properties/200/definitions/Result/properties/presentation_allowed/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'boolean',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be boolean',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid2 =
                                                                                                _errs30 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid2 =
                                                                                                true;
                                                                                        }
                                                                                        if (
                                                                                            valid2
                                                                                        ) {
                                                                                            if (
                                                                                                data1.breakout_rooms !==
                                                                                                undefined
                                                                                            ) {
                                                                                                const _errs32 =
                                                                                                    errors;
                                                                                                if (
                                                                                                    typeof data1.breakout_rooms !==
                                                                                                    'boolean'
                                                                                                ) {
                                                                                                    validate14.errors =
                                                                                                        [
                                                                                                            {
                                                                                                                instancePath:
                                                                                                                    instancePath +
                                                                                                                    '/result/breakout_rooms',
                                                                                                                schemaPath:
                                                                                                                    '#/definitions/conference_status/properties/200/definitions/Result/properties/breakout_rooms/type',
                                                                                                                keyword:
                                                                                                                    'type',
                                                                                                                params: {
                                                                                                                    type: 'boolean',
                                                                                                                },
                                                                                                                message:
                                                                                                                    'must be boolean',
                                                                                                            },
                                                                                                        ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid2 =
                                                                                                    _errs32 ===
                                                                                                    errors;
                                                                                            } else {
                                                                                                var valid2 =
                                                                                                    true;
                                                                                            }
                                                                                            if (
                                                                                                valid2
                                                                                            ) {
                                                                                                if (
                                                                                                    data1.classification !==
                                                                                                    undefined
                                                                                                ) {
                                                                                                    let data15 =
                                                                                                        data1.classification;
                                                                                                    const _errs34 =
                                                                                                        errors;
                                                                                                    if (
                                                                                                        !(
                                                                                                            data15 &&
                                                                                                            typeof data15 ==
                                                                                                                'object' &&
                                                                                                            !Array.isArray(
                                                                                                                data15,
                                                                                                            )
                                                                                                        )
                                                                                                    ) {
                                                                                                        validate14.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/result/classification',
                                                                                                                    schemaPath:
                                                                                                                        '#/definitions/conference_status/properties/200/definitions/Result/properties/classification/type',
                                                                                                                    keyword:
                                                                                                                        'type',
                                                                                                                    params: {
                                                                                                                        type: 'object',
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be object',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid2 =
                                                                                                        _errs34 ===
                                                                                                        errors;
                                                                                                } else {
                                                                                                    var valid2 =
                                                                                                        true;
                                                                                                }
                                                                                                if (
                                                                                                    valid2
                                                                                                ) {
                                                                                                    if (
                                                                                                        data1.breakout_guests_allowed_to_leave !==
                                                                                                        undefined
                                                                                                    ) {
                                                                                                        const _errs36 =
                                                                                                            errors;
                                                                                                        if (
                                                                                                            typeof data1.breakout_guests_allowed_to_leave !==
                                                                                                            'boolean'
                                                                                                        ) {
                                                                                                            validate14.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/result/breakout_guests_allowed_to_leave',
                                                                                                                        schemaPath:
                                                                                                                            '#/definitions/conference_status/properties/200/definitions/Result/properties/breakout_guests_allowed_to_leave/type',
                                                                                                                        keyword:
                                                                                                                            'type',
                                                                                                                        params: {
                                                                                                                            type: 'boolean',
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be boolean',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid2 =
                                                                                                            _errs36 ===
                                                                                                            errors;
                                                                                                    } else {
                                                                                                        var valid2 =
                                                                                                            true;
                                                                                                    }
                                                                                                    if (
                                                                                                        valid2
                                                                                                    ) {
                                                                                                        if (
                                                                                                            data1.breakoutbuzz !==
                                                                                                            undefined
                                                                                                        ) {
                                                                                                            let data17 =
                                                                                                                data1.breakoutbuzz;
                                                                                                            const _errs38 =
                                                                                                                errors;
                                                                                                            if (
                                                                                                                !(
                                                                                                                    data17 &&
                                                                                                                    typeof data17 ==
                                                                                                                        'object' &&
                                                                                                                    !Array.isArray(
                                                                                                                        data17,
                                                                                                                    )
                                                                                                                )
                                                                                                            ) {
                                                                                                                validate14.errors =
                                                                                                                    [
                                                                                                                        {
                                                                                                                            instancePath:
                                                                                                                                instancePath +
                                                                                                                                '/result/breakoutbuzz',
                                                                                                                            schemaPath:
                                                                                                                                '#/definitions/conference_status/properties/200/definitions/Result/properties/breakoutbuzz/type',
                                                                                                                            keyword:
                                                                                                                                'type',
                                                                                                                            params: {
                                                                                                                                type: 'object',
                                                                                                                            },
                                                                                                                            message:
                                                                                                                                'must be object',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid2 =
                                                                                                                _errs38 ===
                                                                                                                errors;
                                                                                                        } else {
                                                                                                            var valid2 =
                                                                                                                true;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate14.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/conference_status/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate14.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate14.errors = vErrors;
    return errors === 0;
}
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate14(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate14.errors
                : vErrors.concat(validate14.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
export const validateLock200 = validate16;
const schema19 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/definitions/lock/properties/200/definitions/200'}],
};
const schema20 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate16(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate16.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate16.errors = vErrors;
    return errors === 0;
}
export const validateUnlock200 = validate17;
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate17.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validateStartConference200 = validate18;
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate18.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
export const validateMuteguests200 = validate19;
function validate19(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate19.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate19.errors = vErrors;
    return errors === 0;
}
export const validateUnmuteguests200 = validate20;
function validate20(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate20.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate20.errors = vErrors;
    return errors === 0;
}
export const validateDisconnect200 = validate21;
function validate21(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate21.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate21.errors = vErrors;
    return errors === 0;
}
export const validateMessageConferenceBody = validate22;
const schema31 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: {
                    type: 'string',
                    enum: ['text/plain', 'application/json'],
                    description: 'The MIME Content-Type.',
                },
                payload: {type: 'string', description: 'Message to send.'},
            },
            required: ['type', 'payload'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/message_conference/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema32 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: {
            type: 'string',
            enum: ['text/plain', 'application/json'],
            description: 'The MIME Content-Type.',
        },
        payload: {type: 'string', description: 'Message to send.'},
    },
    required: ['type', 'payload'],
    title: 'TopLevel',
};
function validate22(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.type === undefined && (missing0 = 'type')) ||
                (data.payload === undefined && (missing0 = 'payload'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/message_conference/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'type' || key0 === 'payload')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.type !== undefined) {
                        let data0 = data.type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/message_conference/properties/Body/definitions/TopLevel/properties/type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (
                            !(
                                data0 === 'text/plain' ||
                                data0 === 'application/json'
                            )
                        ) {
                            const err2 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/message_conference/properties/Body/definitions/TopLevel/properties/type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema32.properties.type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.payload !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.payload !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/payload',
                                    schemaPath:
                                        '#/definitions/message_conference/properties/Body/definitions/TopLevel/properties/payload/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/message_conference/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate22.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate22.errors = vErrors;
    return errors === 0;
}
export const validateMessageConference200 = validate23;
function validate23(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate23.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate23.errors = vErrors;
    return errors === 0;
}
export const validateParticipants200 = validate24;
const schema35 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'array',
                    items: {
                        $ref: '#/definitions/participants/properties/200/definitions/Participant',
                    },
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Participant: {
            type: 'object',
            additionalProperties: false,
            properties: {
                api_url: {type: 'string'},
                buzz_time: {
                    type: 'number',
                    description:
                        'A Unix timestamp of when this participant raised their hand, otherwise zero.',
                },
                call_direction: {
                    type: 'string',
                    enum: ['in', 'out'],
                    description:
                        'Either `in` or `out` as to whether this is an inbound or outbound call.',
                },
                call_tag: {
                    type: 'string',
                    description:
                        'An optional call tag that is assigned to this participant.',
                },
                disconnect_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant can be disconnected, `NO` if not.',
                },
                display_name: {
                    type: 'string',
                    description: 'The display name of the participant.',
                },
                encryption: {
                    type: 'string',
                    enum: ['On', 'Off'],
                    description:
                        '`On` or `Off` as to whether this participant is connected via encrypted media.',
                },
                external_node_uuid: {
                    type: 'string',
                    description:
                        'The UUID of an external node e.g. a Skype for Business / Lync meeting associated with an external participant. This allows grouping of external participants as the UUID will be the same for all participants associated with that external node.',
                },
                fecc_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if this participant can be sent FECC messages; `NO` if not.',
                },
                has_media: {
                    type: 'boolean',
                    description:
                        'Boolean indicating whether the user has media capabilities.',
                },
                is_audio_only_call: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description: 'Set to `YES` if the call is audio only.',
                },
                is_external: {
                    type: 'boolean',
                    description:
                        'Boolean indicating if it is an external participant, e.g. coming in from a Skype for Business / Lync meeting.',
                },
                is_idp_authenticated: {
                    type: 'boolean',
                    description:
                        'Boolean indicating if the participant is authenticated by an IDP.',
                },
                is_muted: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant is administratively audio muted.',
                },
                is_presenting: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant is the current presenter.',
                },
                is_streaming_conference: {
                    type: 'boolean',
                    description:
                        'Boolean indicating whether this is a streaming/recording participant.',
                },
                is_video_call: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the call has video capability.',
                },
                is_video_muted: {
                    type: 'boolean',
                    description:
                        'Boolean indicating whether this participant is administratively video muted.',
                },
                is_video_silent: {
                    type: 'boolean',
                    description:
                        'When participant is considered silent. Eg when frozen, black or outside of the frame.',
                },
                is_main_video_dropped_out: {type: 'boolean'},
                local_alias: {
                    type: 'string',
                    description:
                        'The calling or `from` alias. This is the alias that the recipient would use to return the call.',
                },
                mute_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the participant can be muted, `NO` if not.',
                },
                needs_presentation_in_mix: {type: 'boolean'},
                overlay_text: {
                    type: 'string',
                    description:
                        'Text that may be used as an alternative to display_name as the participant name overlay text.',
                },
                presentation_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if the presentation is supported, `NO` if not.',
                },
                protocol: {
                    type: 'string',
                    enum: ['api', 'webrtc', 'sip', 'rtmp', 'h323', 'mssip', ''],
                    description:
                        'The call protocol.Empty for Guest participants.(Note that the protocol is always reported as `api` when an Infinity Connect client dials in to Pexip Infinity.)',
                },
                role: {
                    type: 'string',
                    enum: ['chair', 'guest'],
                    description:
                        'The level of privileges the participant has in the conference',
                },
                rx_presentation_policy: {
                    type: 'string',
                    enum: ['ALLOW', 'DENY'],
                    description:
                        'Set to `ALLOW` if the participant is administratively allowed to receive presentation, or `DENY` if disallowed.',
                },
                service_type: {
                    type: 'string',
                    enum: [
                        'connecting',
                        'waiting_room',
                        'ivr',
                        'conference',
                        'lecture',
                        'gateway',
                        'test_call',
                    ],
                },
                spotlight: {
                    type: 'number',
                    description:
                        'A Unix timestamp of when this participant was spotlighted, if spotlight is used.',
                },
                start_time: {
                    type: 'number',
                    description:
                        'A Unix timestamp of when this participant joined (UTC).',
                },
                transfer_supported: {
                    type: 'string',
                    enum: ['YES', 'NO'],
                    description:
                        'Set to `YES` if this participant can be transferred into another VMR; `NO` if not.',
                },
                uuid: {
                    type: 'string',
                    description:
                        'The UUID of this participant, to use with other operations.',
                },
                uri: {
                    type: 'string',
                    description:
                        'The URI of the participant. Empty for Guest participants.',
                },
                vendor: {
                    type: 'string',
                    description:
                        'The vendor identifier of the browser/endpoint with which the participant is connecting. Empty for Guest participants.',
                },
            },
            required: [
                'buzz_time',
                'call_direction',
                'disconnect_supported',
                'display_name',
                'encryption',
                'external_node_uuid',
                'fecc_supported',
                'has_media',
                'is_audio_only_call',
                'is_external',
                'is_muted',
                'is_presenting',
                'is_streaming_conference',
                'is_video_call',
                'is_video_muted',
                'is_video_silent',
                'local_alias',
                'mute_supported',
                'overlay_text',
                'protocol',
                'role',
                'rx_presentation_policy',
                'spotlight',
                'start_time',
                'transfer_supported',
                'uuid',
            ],
            title: 'Participant',
        },
    },
    oneOf: [
        {$ref: '#/definitions/participants/properties/200/definitions/200'},
    ],
};
const schema36 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'array',
            items: {
                $ref: '#/definitions/participants/properties/200/definitions/Participant',
            },
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema37 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        api_url: {type: 'string'},
        buzz_time: {
            type: 'number',
            description:
                'A Unix timestamp of when this participant raised their hand, otherwise zero.',
        },
        call_direction: {
            type: 'string',
            enum: ['in', 'out'],
            description:
                'Either `in` or `out` as to whether this is an inbound or outbound call.',
        },
        call_tag: {
            type: 'string',
            description:
                'An optional call tag that is assigned to this participant.',
        },
        disconnect_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant can be disconnected, `NO` if not.',
        },
        display_name: {
            type: 'string',
            description: 'The display name of the participant.',
        },
        encryption: {
            type: 'string',
            enum: ['On', 'Off'],
            description:
                '`On` or `Off` as to whether this participant is connected via encrypted media.',
        },
        external_node_uuid: {
            type: 'string',
            description:
                'The UUID of an external node e.g. a Skype for Business / Lync meeting associated with an external participant. This allows grouping of external participants as the UUID will be the same for all participants associated with that external node.',
        },
        fecc_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if this participant can be sent FECC messages; `NO` if not.',
        },
        has_media: {
            type: 'boolean',
            description:
                'Boolean indicating whether the user has media capabilities.',
        },
        is_audio_only_call: {
            type: 'string',
            enum: ['YES', 'NO'],
            description: 'Set to `YES` if the call is audio only.',
        },
        is_external: {
            type: 'boolean',
            description:
                'Boolean indicating if it is an external participant, e.g. coming in from a Skype for Business / Lync meeting.',
        },
        is_idp_authenticated: {
            type: 'boolean',
            description:
                'Boolean indicating if the participant is authenticated by an IDP.',
        },
        is_muted: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant is administratively audio muted.',
        },
        is_presenting: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant is the current presenter.',
        },
        is_streaming_conference: {
            type: 'boolean',
            description:
                'Boolean indicating whether this is a streaming/recording participant.',
        },
        is_video_call: {
            type: 'string',
            enum: ['YES', 'NO'],
            description: 'Set to `YES` if the call has video capability.',
        },
        is_video_muted: {
            type: 'boolean',
            description:
                'Boolean indicating whether this participant is administratively video muted.',
        },
        is_video_silent: {
            type: 'boolean',
            description:
                'When participant is considered silent. Eg when frozen, black or outside of the frame.',
        },
        is_main_video_dropped_out: {type: 'boolean'},
        local_alias: {
            type: 'string',
            description:
                'The calling or `from` alias. This is the alias that the recipient would use to return the call.',
        },
        mute_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the participant can be muted, `NO` if not.',
        },
        needs_presentation_in_mix: {type: 'boolean'},
        overlay_text: {
            type: 'string',
            description:
                'Text that may be used as an alternative to display_name as the participant name overlay text.',
        },
        presentation_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if the presentation is supported, `NO` if not.',
        },
        protocol: {
            type: 'string',
            enum: ['api', 'webrtc', 'sip', 'rtmp', 'h323', 'mssip', ''],
            description:
                'The call protocol.Empty for Guest participants.(Note that the protocol is always reported as `api` when an Infinity Connect client dials in to Pexip Infinity.)',
        },
        role: {
            type: 'string',
            enum: ['chair', 'guest'],
            description:
                'The level of privileges the participant has in the conference',
        },
        rx_presentation_policy: {
            type: 'string',
            enum: ['ALLOW', 'DENY'],
            description:
                'Set to `ALLOW` if the participant is administratively allowed to receive presentation, or `DENY` if disallowed.',
        },
        service_type: {
            type: 'string',
            enum: [
                'connecting',
                'waiting_room',
                'ivr',
                'conference',
                'lecture',
                'gateway',
                'test_call',
            ],
        },
        spotlight: {
            type: 'number',
            description:
                'A Unix timestamp of when this participant was spotlighted, if spotlight is used.',
        },
        start_time: {
            type: 'number',
            description:
                'A Unix timestamp of when this participant joined (UTC).',
        },
        transfer_supported: {
            type: 'string',
            enum: ['YES', 'NO'],
            description:
                'Set to `YES` if this participant can be transferred into another VMR; `NO` if not.',
        },
        uuid: {
            type: 'string',
            description:
                'The UUID of this participant, to use with other operations.',
        },
        uri: {
            type: 'string',
            description:
                'The URI of the participant. Empty for Guest participants.',
        },
        vendor: {
            type: 'string',
            description:
                'The vendor identifier of the browser/endpoint with which the participant is connecting. Empty for Guest participants.',
        },
    },
    required: [
        'buzz_time',
        'call_direction',
        'disconnect_supported',
        'display_name',
        'encryption',
        'external_node_uuid',
        'fecc_supported',
        'has_media',
        'is_audio_only_call',
        'is_external',
        'is_muted',
        'is_presenting',
        'is_streaming_conference',
        'is_video_call',
        'is_video_muted',
        'is_video_silent',
        'local_alias',
        'mute_supported',
        'overlay_text',
        'protocol',
        'role',
        'rx_presentation_policy',
        'spotlight',
        'start_time',
        'transfer_supported',
        'uuid',
    ],
    title: 'Participant',
};
function validate25(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate25.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate25.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate25.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema36.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            if (errors === _errs4) {
                                if (Array.isArray(data1)) {
                                    var valid1 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        let data2 = data1[i0];
                                        const _errs6 = errors;
                                        const _errs7 = errors;
                                        if (errors === _errs7) {
                                            if (
                                                data2 &&
                                                typeof data2 == 'object' &&
                                                !Array.isArray(data2)
                                            ) {
                                                let missing1;
                                                if (
                                                    (data2.buzz_time ===
                                                        undefined &&
                                                        (missing1 =
                                                            'buzz_time')) ||
                                                    (data2.call_direction ===
                                                        undefined &&
                                                        (missing1 =
                                                            'call_direction')) ||
                                                    (data2.disconnect_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'disconnect_supported')) ||
                                                    (data2.display_name ===
                                                        undefined &&
                                                        (missing1 =
                                                            'display_name')) ||
                                                    (data2.encryption ===
                                                        undefined &&
                                                        (missing1 =
                                                            'encryption')) ||
                                                    (data2.external_node_uuid ===
                                                        undefined &&
                                                        (missing1 =
                                                            'external_node_uuid')) ||
                                                    (data2.fecc_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'fecc_supported')) ||
                                                    (data2.has_media ===
                                                        undefined &&
                                                        (missing1 =
                                                            'has_media')) ||
                                                    (data2.is_audio_only_call ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_audio_only_call')) ||
                                                    (data2.is_external ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_external')) ||
                                                    (data2.is_muted ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_muted')) ||
                                                    (data2.is_presenting ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_presenting')) ||
                                                    (data2.is_streaming_conference ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_streaming_conference')) ||
                                                    (data2.is_video_call ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_video_call')) ||
                                                    (data2.is_video_muted ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_video_muted')) ||
                                                    (data2.is_video_silent ===
                                                        undefined &&
                                                        (missing1 =
                                                            'is_video_silent')) ||
                                                    (data2.local_alias ===
                                                        undefined &&
                                                        (missing1 =
                                                            'local_alias')) ||
                                                    (data2.mute_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'mute_supported')) ||
                                                    (data2.overlay_text ===
                                                        undefined &&
                                                        (missing1 =
                                                            'overlay_text')) ||
                                                    (data2.protocol ===
                                                        undefined &&
                                                        (missing1 =
                                                            'protocol')) ||
                                                    (data2.role === undefined &&
                                                        (missing1 = 'role')) ||
                                                    (data2.rx_presentation_policy ===
                                                        undefined &&
                                                        (missing1 =
                                                            'rx_presentation_policy')) ||
                                                    (data2.spotlight ===
                                                        undefined &&
                                                        (missing1 =
                                                            'spotlight')) ||
                                                    (data2.start_time ===
                                                        undefined &&
                                                        (missing1 =
                                                            'start_time')) ||
                                                    (data2.transfer_supported ===
                                                        undefined &&
                                                        (missing1 =
                                                            'transfer_supported')) ||
                                                    (data2.uuid === undefined &&
                                                        (missing1 = 'uuid'))
                                                ) {
                                                    validate25.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/' +
                                                                i0,
                                                            schemaPath:
                                                                '#/definitions/participants/properties/200/definitions/Participant/required',
                                                            keyword: 'required',
                                                            params: {
                                                                missingProperty:
                                                                    missing1,
                                                            },
                                                            message:
                                                                "must have required property '" +
                                                                missing1 +
                                                                "'",
                                                        },
                                                    ];
                                                    return false;
                                                } else {
                                                    const _errs9 = errors;
                                                    for (const key1 in data2) {
                                                        if (
                                                            !func2.call(
                                                                schema37.properties,
                                                                key1,
                                                            )
                                                        ) {
                                                            delete data2[key1];
                                                        }
                                                    }
                                                    if (_errs9 === errors) {
                                                        if (
                                                            data2.api_url !==
                                                            undefined
                                                        ) {
                                                            const _errs10 =
                                                                errors;
                                                            if (
                                                                typeof data2.api_url !==
                                                                'string'
                                                            ) {
                                                                validate25.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/result/' +
                                                                                i0 +
                                                                                '/api_url',
                                                                            schemaPath:
                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/api_url/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'string',
                                                                            },
                                                                            message:
                                                                                'must be string',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid3 =
                                                                _errs10 ===
                                                                errors;
                                                        } else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (
                                                                data2.buzz_time !==
                                                                undefined
                                                            ) {
                                                                const _errs12 =
                                                                    errors;
                                                                if (
                                                                    !(
                                                                        typeof data2.buzz_time ==
                                                                        'number'
                                                                    )
                                                                ) {
                                                                    validate25.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/result/' +
                                                                                    i0 +
                                                                                    '/buzz_time',
                                                                                schemaPath:
                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/buzz_time/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'number',
                                                                                },
                                                                                message:
                                                                                    'must be number',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid3 =
                                                                    _errs12 ===
                                                                    errors;
                                                            } else {
                                                                var valid3 =
                                                                    true;
                                                            }
                                                            if (valid3) {
                                                                if (
                                                                    data2.call_direction !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data2.call_direction;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        typeof data5 !==
                                                                        'string'
                                                                    ) {
                                                                        validate25.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/result/' +
                                                                                        i0 +
                                                                                        '/call_direction',
                                                                                    schemaPath:
                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/call_direction/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    if (
                                                                        !(
                                                                            data5 ===
                                                                                'in' ||
                                                                            data5 ===
                                                                                'out'
                                                                        )
                                                                    ) {
                                                                        validate25.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/result/' +
                                                                                        i0 +
                                                                                        '/call_direction',
                                                                                    schemaPath:
                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/call_direction/enum',
                                                                                    keyword:
                                                                                        'enum',
                                                                                    params: {
                                                                                        allowedValues:
                                                                                            schema37
                                                                                                .properties
                                                                                                .call_direction
                                                                                                .enum,
                                                                                    },
                                                                                    message:
                                                                                        'must be equal to one of the allowed values',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data2.call_tag !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs16 =
                                                                            errors;
                                                                        if (
                                                                            typeof data2.call_tag !==
                                                                            'string'
                                                                        ) {
                                                                            validate25.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/result/' +
                                                                                            i0 +
                                                                                            '/call_tag',
                                                                                        schemaPath:
                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/call_tag/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'string',
                                                                                        },
                                                                                        message:
                                                                                            'must be string',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs16 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid3
                                                                    ) {
                                                                        if (
                                                                            data2.disconnect_supported !==
                                                                            undefined
                                                                        ) {
                                                                            let data7 =
                                                                                data2.disconnect_supported;
                                                                            const _errs18 =
                                                                                errors;
                                                                            if (
                                                                                typeof data7 !==
                                                                                'string'
                                                                            ) {
                                                                                validate25.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/result/' +
                                                                                                i0 +
                                                                                                '/disconnect_supported',
                                                                                            schemaPath:
                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/disconnect_supported/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            if (
                                                                                !(
                                                                                    data7 ===
                                                                                        'YES' ||
                                                                                    data7 ===
                                                                                        'NO'
                                                                                )
                                                                            ) {
                                                                                validate25.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/result/' +
                                                                                                i0 +
                                                                                                '/disconnect_supported',
                                                                                            schemaPath:
                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/disconnect_supported/enum',
                                                                                            keyword:
                                                                                                'enum',
                                                                                            params: {
                                                                                                allowedValues:
                                                                                                    schema37
                                                                                                        .properties
                                                                                                        .disconnect_supported
                                                                                                        .enum,
                                                                                            },
                                                                                            message:
                                                                                                'must be equal to one of the allowed values',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid3 =
                                                                                _errs18 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid3 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid3
                                                                        ) {
                                                                            if (
                                                                                data2.display_name !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs20 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data2.display_name !==
                                                                                    'string'
                                                                                ) {
                                                                                    validate25.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/result/' +
                                                                                                    i0 +
                                                                                                    '/display_name',
                                                                                                schemaPath:
                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/display_name/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'string',
                                                                                                },
                                                                                                message:
                                                                                                    'must be string',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid3 =
                                                                                    _errs20 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid3 =
                                                                                    true;
                                                                            }
                                                                            if (
                                                                                valid3
                                                                            ) {
                                                                                if (
                                                                                    data2.encryption !==
                                                                                    undefined
                                                                                ) {
                                                                                    let data9 =
                                                                                        data2.encryption;
                                                                                    const _errs22 =
                                                                                        errors;
                                                                                    if (
                                                                                        typeof data9 !==
                                                                                        'string'
                                                                                    ) {
                                                                                        validate25.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/result/' +
                                                                                                        i0 +
                                                                                                        '/encryption',
                                                                                                    schemaPath:
                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/encryption/type',
                                                                                                    keyword:
                                                                                                        'type',
                                                                                                    params: {
                                                                                                        type: 'string',
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be string',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    if (
                                                                                        !(
                                                                                            data9 ===
                                                                                                'On' ||
                                                                                            data9 ===
                                                                                                'Off'
                                                                                        )
                                                                                    ) {
                                                                                        validate25.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/result/' +
                                                                                                        i0 +
                                                                                                        '/encryption',
                                                                                                    schemaPath:
                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/encryption/enum',
                                                                                                    keyword:
                                                                                                        'enum',
                                                                                                    params: {
                                                                                                        allowedValues:
                                                                                                            schema37
                                                                                                                .properties
                                                                                                                .encryption
                                                                                                                .enum,
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be equal to one of the allowed values',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid3 =
                                                                                        _errs22 ===
                                                                                        errors;
                                                                                } else {
                                                                                    var valid3 =
                                                                                        true;
                                                                                }
                                                                                if (
                                                                                    valid3
                                                                                ) {
                                                                                    if (
                                                                                        data2.external_node_uuid !==
                                                                                        undefined
                                                                                    ) {
                                                                                        const _errs24 =
                                                                                            errors;
                                                                                        if (
                                                                                            typeof data2.external_node_uuid !==
                                                                                            'string'
                                                                                        ) {
                                                                                            validate25.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/result/' +
                                                                                                            i0 +
                                                                                                            '/external_node_uuid',
                                                                                                        schemaPath:
                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/external_node_uuid/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'string',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be string',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid3 =
                                                                                            _errs24 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid3 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid3
                                                                                    ) {
                                                                                        if (
                                                                                            data2.fecc_supported !==
                                                                                            undefined
                                                                                        ) {
                                                                                            let data11 =
                                                                                                data2.fecc_supported;
                                                                                            const _errs26 =
                                                                                                errors;
                                                                                            if (
                                                                                                typeof data11 !==
                                                                                                'string'
                                                                                            ) {
                                                                                                validate25.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/result/' +
                                                                                                                i0 +
                                                                                                                '/fecc_supported',
                                                                                                            schemaPath:
                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/fecc_supported/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'string',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be string',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            if (
                                                                                                !(
                                                                                                    data11 ===
                                                                                                        'YES' ||
                                                                                                    data11 ===
                                                                                                        'NO'
                                                                                                )
                                                                                            ) {
                                                                                                validate25.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/result/' +
                                                                                                                i0 +
                                                                                                                '/fecc_supported',
                                                                                                            schemaPath:
                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/fecc_supported/enum',
                                                                                                            keyword:
                                                                                                                'enum',
                                                                                                            params: {
                                                                                                                allowedValues:
                                                                                                                    schema37
                                                                                                                        .properties
                                                                                                                        .fecc_supported
                                                                                                                        .enum,
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be equal to one of the allowed values',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid3 =
                                                                                                _errs26 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid3 =
                                                                                                true;
                                                                                        }
                                                                                        if (
                                                                                            valid3
                                                                                        ) {
                                                                                            if (
                                                                                                data2.has_media !==
                                                                                                undefined
                                                                                            ) {
                                                                                                const _errs28 =
                                                                                                    errors;
                                                                                                if (
                                                                                                    typeof data2.has_media !==
                                                                                                    'boolean'
                                                                                                ) {
                                                                                                    validate25.errors =
                                                                                                        [
                                                                                                            {
                                                                                                                instancePath:
                                                                                                                    instancePath +
                                                                                                                    '/result/' +
                                                                                                                    i0 +
                                                                                                                    '/has_media',
                                                                                                                schemaPath:
                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/has_media/type',
                                                                                                                keyword:
                                                                                                                    'type',
                                                                                                                params: {
                                                                                                                    type: 'boolean',
                                                                                                                },
                                                                                                                message:
                                                                                                                    'must be boolean',
                                                                                                            },
                                                                                                        ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid3 =
                                                                                                    _errs28 ===
                                                                                                    errors;
                                                                                            } else {
                                                                                                var valid3 =
                                                                                                    true;
                                                                                            }
                                                                                            if (
                                                                                                valid3
                                                                                            ) {
                                                                                                if (
                                                                                                    data2.is_audio_only_call !==
                                                                                                    undefined
                                                                                                ) {
                                                                                                    let data13 =
                                                                                                        data2.is_audio_only_call;
                                                                                                    const _errs30 =
                                                                                                        errors;
                                                                                                    if (
                                                                                                        typeof data13 !==
                                                                                                        'string'
                                                                                                    ) {
                                                                                                        validate25.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/result/' +
                                                                                                                        i0 +
                                                                                                                        '/is_audio_only_call',
                                                                                                                    schemaPath:
                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/is_audio_only_call/type',
                                                                                                                    keyword:
                                                                                                                        'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    if (
                                                                                                        !(
                                                                                                            data13 ===
                                                                                                                'YES' ||
                                                                                                            data13 ===
                                                                                                                'NO'
                                                                                                        )
                                                                                                    ) {
                                                                                                        validate25.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/result/' +
                                                                                                                        i0 +
                                                                                                                        '/is_audio_only_call',
                                                                                                                    schemaPath:
                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/is_audio_only_call/enum',
                                                                                                                    keyword:
                                                                                                                        'enum',
                                                                                                                    params: {
                                                                                                                        allowedValues:
                                                                                                                            schema37
                                                                                                                                .properties
                                                                                                                                .is_audio_only_call
                                                                                                                                .enum,
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be equal to one of the allowed values',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid3 =
                                                                                                        _errs30 ===
                                                                                                        errors;
                                                                                                } else {
                                                                                                    var valid3 =
                                                                                                        true;
                                                                                                }
                                                                                                if (
                                                                                                    valid3
                                                                                                ) {
                                                                                                    if (
                                                                                                        data2.is_external !==
                                                                                                        undefined
                                                                                                    ) {
                                                                                                        const _errs32 =
                                                                                                            errors;
                                                                                                        if (
                                                                                                            typeof data2.is_external !==
                                                                                                            'boolean'
                                                                                                        ) {
                                                                                                            validate25.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/result/' +
                                                                                                                            i0 +
                                                                                                                            '/is_external',
                                                                                                                        schemaPath:
                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/is_external/type',
                                                                                                                        keyword:
                                                                                                                            'type',
                                                                                                                        params: {
                                                                                                                            type: 'boolean',
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be boolean',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid3 =
                                                                                                            _errs32 ===
                                                                                                            errors;
                                                                                                    } else {
                                                                                                        var valid3 =
                                                                                                            true;
                                                                                                    }
                                                                                                    if (
                                                                                                        valid3
                                                                                                    ) {
                                                                                                        if (
                                                                                                            data2.is_idp_authenticated !==
                                                                                                            undefined
                                                                                                        ) {
                                                                                                            const _errs34 =
                                                                                                                errors;
                                                                                                            if (
                                                                                                                typeof data2.is_idp_authenticated !==
                                                                                                                'boolean'
                                                                                                            ) {
                                                                                                                validate25.errors =
                                                                                                                    [
                                                                                                                        {
                                                                                                                            instancePath:
                                                                                                                                instancePath +
                                                                                                                                '/result/' +
                                                                                                                                i0 +
                                                                                                                                '/is_idp_authenticated',
                                                                                                                            schemaPath:
                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/is_idp_authenticated/type',
                                                                                                                            keyword:
                                                                                                                                'type',
                                                                                                                            params: {
                                                                                                                                type: 'boolean',
                                                                                                                            },
                                                                                                                            message:
                                                                                                                                'must be boolean',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid3 =
                                                                                                                _errs34 ===
                                                                                                                errors;
                                                                                                        } else {
                                                                                                            var valid3 =
                                                                                                                true;
                                                                                                        }
                                                                                                        if (
                                                                                                            valid3
                                                                                                        ) {
                                                                                                            if (
                                                                                                                data2.is_muted !==
                                                                                                                undefined
                                                                                                            ) {
                                                                                                                let data16 =
                                                                                                                    data2.is_muted;
                                                                                                                const _errs36 =
                                                                                                                    errors;
                                                                                                                if (
                                                                                                                    typeof data16 !==
                                                                                                                    'string'
                                                                                                                ) {
                                                                                                                    validate25.errors =
                                                                                                                        [
                                                                                                                            {
                                                                                                                                instancePath:
                                                                                                                                    instancePath +
                                                                                                                                    '/result/' +
                                                                                                                                    i0 +
                                                                                                                                    '/is_muted',
                                                                                                                                schemaPath:
                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/is_muted/type',
                                                                                                                                keyword:
                                                                                                                                    'type',
                                                                                                                                params: {
                                                                                                                                    type: 'string',
                                                                                                                                },
                                                                                                                                message:
                                                                                                                                    'must be string',
                                                                                                                            },
                                                                                                                        ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                if (
                                                                                                                    !(
                                                                                                                        data16 ===
                                                                                                                            'YES' ||
                                                                                                                        data16 ===
                                                                                                                            'NO'
                                                                                                                    )
                                                                                                                ) {
                                                                                                                    validate25.errors =
                                                                                                                        [
                                                                                                                            {
                                                                                                                                instancePath:
                                                                                                                                    instancePath +
                                                                                                                                    '/result/' +
                                                                                                                                    i0 +
                                                                                                                                    '/is_muted',
                                                                                                                                schemaPath:
                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/is_muted/enum',
                                                                                                                                keyword:
                                                                                                                                    'enum',
                                                                                                                                params: {
                                                                                                                                    allowedValues:
                                                                                                                                        schema37
                                                                                                                                            .properties
                                                                                                                                            .is_muted
                                                                                                                                            .enum,
                                                                                                                                },
                                                                                                                                message:
                                                                                                                                    'must be equal to one of the allowed values',
                                                                                                                            },
                                                                                                                        ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid3 =
                                                                                                                    _errs36 ===
                                                                                                                    errors;
                                                                                                            } else {
                                                                                                                var valid3 =
                                                                                                                    true;
                                                                                                            }
                                                                                                            if (
                                                                                                                valid3
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    data2.is_presenting !==
                                                                                                                    undefined
                                                                                                                ) {
                                                                                                                    let data17 =
                                                                                                                        data2.is_presenting;
                                                                                                                    const _errs38 =
                                                                                                                        errors;
                                                                                                                    if (
                                                                                                                        typeof data17 !==
                                                                                                                        'string'
                                                                                                                    ) {
                                                                                                                        validate25.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath:
                                                                                                                                        instancePath +
                                                                                                                                        '/result/' +
                                                                                                                                        i0 +
                                                                                                                                        '/is_presenting',
                                                                                                                                    schemaPath:
                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/is_presenting/type',
                                                                                                                                    keyword:
                                                                                                                                        'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message:
                                                                                                                                        'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        !(
                                                                                                                            data17 ===
                                                                                                                                'YES' ||
                                                                                                                            data17 ===
                                                                                                                                'NO'
                                                                                                                        )
                                                                                                                    ) {
                                                                                                                        validate25.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath:
                                                                                                                                        instancePath +
                                                                                                                                        '/result/' +
                                                                                                                                        i0 +
                                                                                                                                        '/is_presenting',
                                                                                                                                    schemaPath:
                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/is_presenting/enum',
                                                                                                                                    keyword:
                                                                                                                                        'enum',
                                                                                                                                    params: {
                                                                                                                                        allowedValues:
                                                                                                                                            schema37
                                                                                                                                                .properties
                                                                                                                                                .is_presenting
                                                                                                                                                .enum,
                                                                                                                                    },
                                                                                                                                    message:
                                                                                                                                        'must be equal to one of the allowed values',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid3 =
                                                                                                                        _errs38 ===
                                                                                                                        errors;
                                                                                                                } else {
                                                                                                                    var valid3 =
                                                                                                                        true;
                                                                                                                }
                                                                                                                if (
                                                                                                                    valid3
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        data2.is_streaming_conference !==
                                                                                                                        undefined
                                                                                                                    ) {
                                                                                                                        const _errs40 =
                                                                                                                            errors;
                                                                                                                        if (
                                                                                                                            typeof data2.is_streaming_conference !==
                                                                                                                            'boolean'
                                                                                                                        ) {
                                                                                                                            validate25.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath:
                                                                                                                                            instancePath +
                                                                                                                                            '/result/' +
                                                                                                                                            i0 +
                                                                                                                                            '/is_streaming_conference',
                                                                                                                                        schemaPath:
                                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/is_streaming_conference/type',
                                                                                                                                        keyword:
                                                                                                                                            'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'boolean',
                                                                                                                                        },
                                                                                                                                        message:
                                                                                                                                            'must be boolean',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid3 =
                                                                                                                            _errs40 ===
                                                                                                                            errors;
                                                                                                                    } else {
                                                                                                                        var valid3 =
                                                                                                                            true;
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        valid3
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            data2.is_video_call !==
                                                                                                                            undefined
                                                                                                                        ) {
                                                                                                                            let data19 =
                                                                                                                                data2.is_video_call;
                                                                                                                            const _errs42 =
                                                                                                                                errors;
                                                                                                                            if (
                                                                                                                                typeof data19 !==
                                                                                                                                'string'
                                                                                                                            ) {
                                                                                                                                validate25.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath:
                                                                                                                                                instancePath +
                                                                                                                                                '/result/' +
                                                                                                                                                i0 +
                                                                                                                                                '/is_video_call',
                                                                                                                                            schemaPath:
                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/is_video_call/type',
                                                                                                                                            keyword:
                                                                                                                                                'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message:
                                                                                                                                                'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            if (
                                                                                                                                !(
                                                                                                                                    data19 ===
                                                                                                                                        'YES' ||
                                                                                                                                    data19 ===
                                                                                                                                        'NO'
                                                                                                                                )
                                                                                                                            ) {
                                                                                                                                validate25.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath:
                                                                                                                                                instancePath +
                                                                                                                                                '/result/' +
                                                                                                                                                i0 +
                                                                                                                                                '/is_video_call',
                                                                                                                                            schemaPath:
                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/is_video_call/enum',
                                                                                                                                            keyword:
                                                                                                                                                'enum',
                                                                                                                                            params: {
                                                                                                                                                allowedValues:
                                                                                                                                                    schema37
                                                                                                                                                        .properties
                                                                                                                                                        .is_video_call
                                                                                                                                                        .enum,
                                                                                                                                            },
                                                                                                                                            message:
                                                                                                                                                'must be equal to one of the allowed values',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid3 =
                                                                                                                                _errs42 ===
                                                                                                                                errors;
                                                                                                                        } else {
                                                                                                                            var valid3 =
                                                                                                                                true;
                                                                                                                        }
                                                                                                                        if (
                                                                                                                            valid3
                                                                                                                        ) {
                                                                                                                            if (
                                                                                                                                data2.is_video_muted !==
                                                                                                                                undefined
                                                                                                                            ) {
                                                                                                                                const _errs44 =
                                                                                                                                    errors;
                                                                                                                                if (
                                                                                                                                    typeof data2.is_video_muted !==
                                                                                                                                    'boolean'
                                                                                                                                ) {
                                                                                                                                    validate25.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath:
                                                                                                                                                    instancePath +
                                                                                                                                                    '/result/' +
                                                                                                                                                    i0 +
                                                                                                                                                    '/is_video_muted',
                                                                                                                                                schemaPath:
                                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/is_video_muted/type',
                                                                                                                                                keyword:
                                                                                                                                                    'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message:
                                                                                                                                                    'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid3 =
                                                                                                                                    _errs44 ===
                                                                                                                                    errors;
                                                                                                                            } else {
                                                                                                                                var valid3 =
                                                                                                                                    true;
                                                                                                                            }
                                                                                                                            if (
                                                                                                                                valid3
                                                                                                                            ) {
                                                                                                                                if (
                                                                                                                                    data2.is_video_silent !==
                                                                                                                                    undefined
                                                                                                                                ) {
                                                                                                                                    const _errs46 =
                                                                                                                                        errors;
                                                                                                                                    if (
                                                                                                                                        typeof data2.is_video_silent !==
                                                                                                                                        'boolean'
                                                                                                                                    ) {
                                                                                                                                        validate25.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath:
                                                                                                                                                        instancePath +
                                                                                                                                                        '/result/' +
                                                                                                                                                        i0 +
                                                                                                                                                        '/is_video_silent',
                                                                                                                                                    schemaPath:
                                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/is_video_silent/type',
                                                                                                                                                    keyword:
                                                                                                                                                        'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'boolean',
                                                                                                                                                    },
                                                                                                                                                    message:
                                                                                                                                                        'must be boolean',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid3 =
                                                                                                                                        _errs46 ===
                                                                                                                                        errors;
                                                                                                                                } else {
                                                                                                                                    var valid3 =
                                                                                                                                        true;
                                                                                                                                }
                                                                                                                                if (
                                                                                                                                    valid3
                                                                                                                                ) {
                                                                                                                                    if (
                                                                                                                                        data2.is_main_video_dropped_out !==
                                                                                                                                        undefined
                                                                                                                                    ) {
                                                                                                                                        const _errs48 =
                                                                                                                                            errors;
                                                                                                                                        if (
                                                                                                                                            typeof data2.is_main_video_dropped_out !==
                                                                                                                                            'boolean'
                                                                                                                                        ) {
                                                                                                                                            validate25.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath:
                                                                                                                                                            instancePath +
                                                                                                                                                            '/result/' +
                                                                                                                                                            i0 +
                                                                                                                                                            '/is_main_video_dropped_out',
                                                                                                                                                        schemaPath:
                                                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/is_main_video_dropped_out/type',
                                                                                                                                                        keyword:
                                                                                                                                                            'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'boolean',
                                                                                                                                                        },
                                                                                                                                                        message:
                                                                                                                                                            'must be boolean',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid3 =
                                                                                                                                            _errs48 ===
                                                                                                                                            errors;
                                                                                                                                    } else {
                                                                                                                                        var valid3 =
                                                                                                                                            true;
                                                                                                                                    }
                                                                                                                                    if (
                                                                                                                                        valid3
                                                                                                                                    ) {
                                                                                                                                        if (
                                                                                                                                            data2.local_alias !==
                                                                                                                                            undefined
                                                                                                                                        ) {
                                                                                                                                            const _errs50 =
                                                                                                                                                errors;
                                                                                                                                            if (
                                                                                                                                                typeof data2.local_alias !==
                                                                                                                                                'string'
                                                                                                                                            ) {
                                                                                                                                                validate25.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath:
                                                                                                                                                                instancePath +
                                                                                                                                                                '/result/' +
                                                                                                                                                                i0 +
                                                                                                                                                                '/local_alias',
                                                                                                                                                            schemaPath:
                                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/local_alias/type',
                                                                                                                                                            keyword:
                                                                                                                                                                'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'string',
                                                                                                                                                            },
                                                                                                                                                            message:
                                                                                                                                                                'must be string',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid3 =
                                                                                                                                                _errs50 ===
                                                                                                                                                errors;
                                                                                                                                        } else {
                                                                                                                                            var valid3 =
                                                                                                                                                true;
                                                                                                                                        }
                                                                                                                                        if (
                                                                                                                                            valid3
                                                                                                                                        ) {
                                                                                                                                            if (
                                                                                                                                                data2.mute_supported !==
                                                                                                                                                undefined
                                                                                                                                            ) {
                                                                                                                                                let data24 =
                                                                                                                                                    data2.mute_supported;
                                                                                                                                                const _errs52 =
                                                                                                                                                    errors;
                                                                                                                                                if (
                                                                                                                                                    typeof data24 !==
                                                                                                                                                    'string'
                                                                                                                                                ) {
                                                                                                                                                    validate25.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath:
                                                                                                                                                                    instancePath +
                                                                                                                                                                    '/result/' +
                                                                                                                                                                    i0 +
                                                                                                                                                                    '/mute_supported',
                                                                                                                                                                schemaPath:
                                                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/mute_supported/type',
                                                                                                                                                                keyword:
                                                                                                                                                                    'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'string',
                                                                                                                                                                },
                                                                                                                                                                message:
                                                                                                                                                                    'must be string',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                if (
                                                                                                                                                    !(
                                                                                                                                                        data24 ===
                                                                                                                                                            'YES' ||
                                                                                                                                                        data24 ===
                                                                                                                                                            'NO'
                                                                                                                                                    )
                                                                                                                                                ) {
                                                                                                                                                    validate25.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath:
                                                                                                                                                                    instancePath +
                                                                                                                                                                    '/result/' +
                                                                                                                                                                    i0 +
                                                                                                                                                                    '/mute_supported',
                                                                                                                                                                schemaPath:
                                                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/mute_supported/enum',
                                                                                                                                                                keyword:
                                                                                                                                                                    'enum',
                                                                                                                                                                params: {
                                                                                                                                                                    allowedValues:
                                                                                                                                                                        schema37
                                                                                                                                                                            .properties
                                                                                                                                                                            .mute_supported
                                                                                                                                                                            .enum,
                                                                                                                                                                },
                                                                                                                                                                message:
                                                                                                                                                                    'must be equal to one of the allowed values',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid3 =
                                                                                                                                                    _errs52 ===
                                                                                                                                                    errors;
                                                                                                                                            } else {
                                                                                                                                                var valid3 =
                                                                                                                                                    true;
                                                                                                                                            }
                                                                                                                                            if (
                                                                                                                                                valid3
                                                                                                                                            ) {
                                                                                                                                                if (
                                                                                                                                                    data2.needs_presentation_in_mix !==
                                                                                                                                                    undefined
                                                                                                                                                ) {
                                                                                                                                                    const _errs54 =
                                                                                                                                                        errors;
                                                                                                                                                    if (
                                                                                                                                                        typeof data2.needs_presentation_in_mix !==
                                                                                                                                                        'boolean'
                                                                                                                                                    ) {
                                                                                                                                                        validate25.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath:
                                                                                                                                                                        instancePath +
                                                                                                                                                                        '/result/' +
                                                                                                                                                                        i0 +
                                                                                                                                                                        '/needs_presentation_in_mix',
                                                                                                                                                                    schemaPath:
                                                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/needs_presentation_in_mix/type',
                                                                                                                                                                    keyword:
                                                                                                                                                                        'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message:
                                                                                                                                                                        'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid3 =
                                                                                                                                                        _errs54 ===
                                                                                                                                                        errors;
                                                                                                                                                } else {
                                                                                                                                                    var valid3 =
                                                                                                                                                        true;
                                                                                                                                                }
                                                                                                                                                if (
                                                                                                                                                    valid3
                                                                                                                                                ) {
                                                                                                                                                    if (
                                                                                                                                                        data2.overlay_text !==
                                                                                                                                                        undefined
                                                                                                                                                    ) {
                                                                                                                                                        const _errs56 =
                                                                                                                                                            errors;
                                                                                                                                                        if (
                                                                                                                                                            typeof data2.overlay_text !==
                                                                                                                                                            'string'
                                                                                                                                                        ) {
                                                                                                                                                            validate25.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath:
                                                                                                                                                                            instancePath +
                                                                                                                                                                            '/result/' +
                                                                                                                                                                            i0 +
                                                                                                                                                                            '/overlay_text',
                                                                                                                                                                        schemaPath:
                                                                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/overlay_text/type',
                                                                                                                                                                        keyword:
                                                                                                                                                                            'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'string',
                                                                                                                                                                        },
                                                                                                                                                                        message:
                                                                                                                                                                            'must be string',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid3 =
                                                                                                                                                            _errs56 ===
                                                                                                                                                            errors;
                                                                                                                                                    } else {
                                                                                                                                                        var valid3 =
                                                                                                                                                            true;
                                                                                                                                                    }
                                                                                                                                                    if (
                                                                                                                                                        valid3
                                                                                                                                                    ) {
                                                                                                                                                        if (
                                                                                                                                                            data2.presentation_supported !==
                                                                                                                                                            undefined
                                                                                                                                                        ) {
                                                                                                                                                            let data27 =
                                                                                                                                                                data2.presentation_supported;
                                                                                                                                                            const _errs58 =
                                                                                                                                                                errors;
                                                                                                                                                            if (
                                                                                                                                                                typeof data27 !==
                                                                                                                                                                'string'
                                                                                                                                                            ) {
                                                                                                                                                                validate25.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath:
                                                                                                                                                                                instancePath +
                                                                                                                                                                                '/result/' +
                                                                                                                                                                                i0 +
                                                                                                                                                                                '/presentation_supported',
                                                                                                                                                                            schemaPath:
                                                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/presentation_supported/type',
                                                                                                                                                                            keyword:
                                                                                                                                                                                'type',
                                                                                                                                                                            params: {
                                                                                                                                                                                type: 'string',
                                                                                                                                                                            },
                                                                                                                                                                            message:
                                                                                                                                                                                'must be string',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            if (
                                                                                                                                                                !(
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'YES' ||
                                                                                                                                                                    data27 ===
                                                                                                                                                                        'NO'
                                                                                                                                                                )
                                                                                                                                                            ) {
                                                                                                                                                                validate25.errors =
                                                                                                                                                                    [
                                                                                                                                                                        {
                                                                                                                                                                            instancePath:
                                                                                                                                                                                instancePath +
                                                                                                                                                                                '/result/' +
                                                                                                                                                                                i0 +
                                                                                                                                                                                '/presentation_supported',
                                                                                                                                                                            schemaPath:
                                                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/presentation_supported/enum',
                                                                                                                                                                            keyword:
                                                                                                                                                                                'enum',
                                                                                                                                                                            params: {
                                                                                                                                                                                allowedValues:
                                                                                                                                                                                    schema37
                                                                                                                                                                                        .properties
                                                                                                                                                                                        .presentation_supported
                                                                                                                                                                                        .enum,
                                                                                                                                                                            },
                                                                                                                                                                            message:
                                                                                                                                                                                'must be equal to one of the allowed values',
                                                                                                                                                                        },
                                                                                                                                                                    ];
                                                                                                                                                                return false;
                                                                                                                                                            }
                                                                                                                                                            var valid3 =
                                                                                                                                                                _errs58 ===
                                                                                                                                                                errors;
                                                                                                                                                        } else {
                                                                                                                                                            var valid3 =
                                                                                                                                                                true;
                                                                                                                                                        }
                                                                                                                                                        if (
                                                                                                                                                            valid3
                                                                                                                                                        ) {
                                                                                                                                                            if (
                                                                                                                                                                data2.protocol !==
                                                                                                                                                                undefined
                                                                                                                                                            ) {
                                                                                                                                                                let data28 =
                                                                                                                                                                    data2.protocol;
                                                                                                                                                                const _errs60 =
                                                                                                                                                                    errors;
                                                                                                                                                                if (
                                                                                                                                                                    typeof data28 !==
                                                                                                                                                                    'string'
                                                                                                                                                                ) {
                                                                                                                                                                    validate25.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath:
                                                                                                                                                                                    instancePath +
                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                    i0 +
                                                                                                                                                                                    '/protocol',
                                                                                                                                                                                schemaPath:
                                                                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/protocol/type',
                                                                                                                                                                                keyword:
                                                                                                                                                                                    'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                },
                                                                                                                                                                                message:
                                                                                                                                                                                    'must be string',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                if (
                                                                                                                                                                    !(
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'api' ||
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'webrtc' ||
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'sip' ||
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'rtmp' ||
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'h323' ||
                                                                                                                                                                        data28 ===
                                                                                                                                                                            'mssip' ||
                                                                                                                                                                        data28 ===
                                                                                                                                                                            ''
                                                                                                                                                                    )
                                                                                                                                                                ) {
                                                                                                                                                                    validate25.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath:
                                                                                                                                                                                    instancePath +
                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                    i0 +
                                                                                                                                                                                    '/protocol',
                                                                                                                                                                                schemaPath:
                                                                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/protocol/enum',
                                                                                                                                                                                keyword:
                                                                                                                                                                                    'enum',
                                                                                                                                                                                params: {
                                                                                                                                                                                    allowedValues:
                                                                                                                                                                                        schema37
                                                                                                                                                                                            .properties
                                                                                                                                                                                            .protocol
                                                                                                                                                                                            .enum,
                                                                                                                                                                                },
                                                                                                                                                                                message:
                                                                                                                                                                                    'must be equal to one of the allowed values',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid3 =
                                                                                                                                                                    _errs60 ===
                                                                                                                                                                    errors;
                                                                                                                                                            } else {
                                                                                                                                                                var valid3 =
                                                                                                                                                                    true;
                                                                                                                                                            }
                                                                                                                                                            if (
                                                                                                                                                                valid3
                                                                                                                                                            ) {
                                                                                                                                                                if (
                                                                                                                                                                    data2.role !==
                                                                                                                                                                    undefined
                                                                                                                                                                ) {
                                                                                                                                                                    let data29 =
                                                                                                                                                                        data2.role;
                                                                                                                                                                    const _errs62 =
                                                                                                                                                                        errors;
                                                                                                                                                                    if (
                                                                                                                                                                        typeof data29 !==
                                                                                                                                                                        'string'
                                                                                                                                                                    ) {
                                                                                                                                                                        validate25.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath:
                                                                                                                                                                                        instancePath +
                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                        i0 +
                                                                                                                                                                                        '/role',
                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/role/type',
                                                                                                                                                                                    keyword:
                                                                                                                                                                                        'type',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                    },
                                                                                                                                                                                    message:
                                                                                                                                                                                        'must be string',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    if (
                                                                                                                                                                        !(
                                                                                                                                                                            data29 ===
                                                                                                                                                                                'chair' ||
                                                                                                                                                                            data29 ===
                                                                                                                                                                                'guest'
                                                                                                                                                                        )
                                                                                                                                                                    ) {
                                                                                                                                                                        validate25.errors =
                                                                                                                                                                            [
                                                                                                                                                                                {
                                                                                                                                                                                    instancePath:
                                                                                                                                                                                        instancePath +
                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                        i0 +
                                                                                                                                                                                        '/role',
                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/role/enum',
                                                                                                                                                                                    keyword:
                                                                                                                                                                                        'enum',
                                                                                                                                                                                    params: {
                                                                                                                                                                                        allowedValues:
                                                                                                                                                                                            schema37
                                                                                                                                                                                                .properties
                                                                                                                                                                                                .role
                                                                                                                                                                                                .enum,
                                                                                                                                                                                    },
                                                                                                                                                                                    message:
                                                                                                                                                                                        'must be equal to one of the allowed values',
                                                                                                                                                                                },
                                                                                                                                                                            ];
                                                                                                                                                                        return false;
                                                                                                                                                                    }
                                                                                                                                                                    var valid3 =
                                                                                                                                                                        _errs62 ===
                                                                                                                                                                        errors;
                                                                                                                                                                } else {
                                                                                                                                                                    var valid3 =
                                                                                                                                                                        true;
                                                                                                                                                                }
                                                                                                                                                                if (
                                                                                                                                                                    valid3
                                                                                                                                                                ) {
                                                                                                                                                                    if (
                                                                                                                                                                        data2.rx_presentation_policy !==
                                                                                                                                                                        undefined
                                                                                                                                                                    ) {
                                                                                                                                                                        let data30 =
                                                                                                                                                                            data2.rx_presentation_policy;
                                                                                                                                                                        const _errs64 =
                                                                                                                                                                            errors;
                                                                                                                                                                        if (
                                                                                                                                                                            typeof data30 !==
                                                                                                                                                                            'string'
                                                                                                                                                                        ) {
                                                                                                                                                                            validate25.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath:
                                                                                                                                                                                            instancePath +
                                                                                                                                                                                            '/result/' +
                                                                                                                                                                                            i0 +
                                                                                                                                                                                            '/rx_presentation_policy',
                                                                                                                                                                                        schemaPath:
                                                                                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/rx_presentation_policy/type',
                                                                                                                                                                                        keyword:
                                                                                                                                                                                            'type',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                        },
                                                                                                                                                                                        message:
                                                                                                                                                                                            'must be string',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        if (
                                                                                                                                                                            !(
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'ALLOW' ||
                                                                                                                                                                                data30 ===
                                                                                                                                                                                    'DENY'
                                                                                                                                                                            )
                                                                                                                                                                        ) {
                                                                                                                                                                            validate25.errors =
                                                                                                                                                                                [
                                                                                                                                                                                    {
                                                                                                                                                                                        instancePath:
                                                                                                                                                                                            instancePath +
                                                                                                                                                                                            '/result/' +
                                                                                                                                                                                            i0 +
                                                                                                                                                                                            '/rx_presentation_policy',
                                                                                                                                                                                        schemaPath:
                                                                                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/rx_presentation_policy/enum',
                                                                                                                                                                                        keyword:
                                                                                                                                                                                            'enum',
                                                                                                                                                                                        params: {
                                                                                                                                                                                            allowedValues:
                                                                                                                                                                                                schema37
                                                                                                                                                                                                    .properties
                                                                                                                                                                                                    .rx_presentation_policy
                                                                                                                                                                                                    .enum,
                                                                                                                                                                                        },
                                                                                                                                                                                        message:
                                                                                                                                                                                            'must be equal to one of the allowed values',
                                                                                                                                                                                    },
                                                                                                                                                                                ];
                                                                                                                                                                            return false;
                                                                                                                                                                        }
                                                                                                                                                                        var valid3 =
                                                                                                                                                                            _errs64 ===
                                                                                                                                                                            errors;
                                                                                                                                                                    } else {
                                                                                                                                                                        var valid3 =
                                                                                                                                                                            true;
                                                                                                                                                                    }
                                                                                                                                                                    if (
                                                                                                                                                                        valid3
                                                                                                                                                                    ) {
                                                                                                                                                                        if (
                                                                                                                                                                            data2.service_type !==
                                                                                                                                                                            undefined
                                                                                                                                                                        ) {
                                                                                                                                                                            let data31 =
                                                                                                                                                                                data2.service_type;
                                                                                                                                                                            const _errs66 =
                                                                                                                                                                                errors;
                                                                                                                                                                            if (
                                                                                                                                                                                typeof data31 !==
                                                                                                                                                                                'string'
                                                                                                                                                                            ) {
                                                                                                                                                                                validate25.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath:
                                                                                                                                                                                                instancePath +
                                                                                                                                                                                                '/result/' +
                                                                                                                                                                                                i0 +
                                                                                                                                                                                                '/service_type',
                                                                                                                                                                                            schemaPath:
                                                                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/service_type/type',
                                                                                                                                                                                            keyword:
                                                                                                                                                                                                'type',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                            },
                                                                                                                                                                                            message:
                                                                                                                                                                                                'must be string',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            if (
                                                                                                                                                                                !(
                                                                                                                                                                                    data31 ===
                                                                                                                                                                                        'connecting' ||
                                                                                                                                                                                    data31 ===
                                                                                                                                                                                        'waiting_room' ||
                                                                                                                                                                                    data31 ===
                                                                                                                                                                                        'ivr' ||
                                                                                                                                                                                    data31 ===
                                                                                                                                                                                        'conference' ||
                                                                                                                                                                                    data31 ===
                                                                                                                                                                                        'lecture' ||
                                                                                                                                                                                    data31 ===
                                                                                                                                                                                        'gateway' ||
                                                                                                                                                                                    data31 ===
                                                                                                                                                                                        'test_call'
                                                                                                                                                                                )
                                                                                                                                                                            ) {
                                                                                                                                                                                validate25.errors =
                                                                                                                                                                                    [
                                                                                                                                                                                        {
                                                                                                                                                                                            instancePath:
                                                                                                                                                                                                instancePath +
                                                                                                                                                                                                '/result/' +
                                                                                                                                                                                                i0 +
                                                                                                                                                                                                '/service_type',
                                                                                                                                                                                            schemaPath:
                                                                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/service_type/enum',
                                                                                                                                                                                            keyword:
                                                                                                                                                                                                'enum',
                                                                                                                                                                                            params: {
                                                                                                                                                                                                allowedValues:
                                                                                                                                                                                                    schema37
                                                                                                                                                                                                        .properties
                                                                                                                                                                                                        .service_type
                                                                                                                                                                                                        .enum,
                                                                                                                                                                                            },
                                                                                                                                                                                            message:
                                                                                                                                                                                                'must be equal to one of the allowed values',
                                                                                                                                                                                        },
                                                                                                                                                                                    ];
                                                                                                                                                                                return false;
                                                                                                                                                                            }
                                                                                                                                                                            var valid3 =
                                                                                                                                                                                _errs66 ===
                                                                                                                                                                                errors;
                                                                                                                                                                        } else {
                                                                                                                                                                            var valid3 =
                                                                                                                                                                                true;
                                                                                                                                                                        }
                                                                                                                                                                        if (
                                                                                                                                                                            valid3
                                                                                                                                                                        ) {
                                                                                                                                                                            if (
                                                                                                                                                                                data2.spotlight !==
                                                                                                                                                                                undefined
                                                                                                                                                                            ) {
                                                                                                                                                                                const _errs68 =
                                                                                                                                                                                    errors;
                                                                                                                                                                                if (
                                                                                                                                                                                    !(
                                                                                                                                                                                        typeof data2.spotlight ==
                                                                                                                                                                                        'number'
                                                                                                                                                                                    )
                                                                                                                                                                                ) {
                                                                                                                                                                                    validate25.errors =
                                                                                                                                                                                        [
                                                                                                                                                                                            {
                                                                                                                                                                                                instancePath:
                                                                                                                                                                                                    instancePath +
                                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                                    i0 +
                                                                                                                                                                                                    '/spotlight',
                                                                                                                                                                                                schemaPath:
                                                                                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/spotlight/type',
                                                                                                                                                                                                keyword:
                                                                                                                                                                                                    'type',
                                                                                                                                                                                                params: {
                                                                                                                                                                                                    type: 'number',
                                                                                                                                                                                                },
                                                                                                                                                                                                message:
                                                                                                                                                                                                    'must be number',
                                                                                                                                                                                            },
                                                                                                                                                                                        ];
                                                                                                                                                                                    return false;
                                                                                                                                                                                }
                                                                                                                                                                                var valid3 =
                                                                                                                                                                                    _errs68 ===
                                                                                                                                                                                    errors;
                                                                                                                                                                            } else {
                                                                                                                                                                                var valid3 =
                                                                                                                                                                                    true;
                                                                                                                                                                            }
                                                                                                                                                                            if (
                                                                                                                                                                                valid3
                                                                                                                                                                            ) {
                                                                                                                                                                                if (
                                                                                                                                                                                    data2.start_time !==
                                                                                                                                                                                    undefined
                                                                                                                                                                                ) {
                                                                                                                                                                                    const _errs70 =
                                                                                                                                                                                        errors;
                                                                                                                                                                                    if (
                                                                                                                                                                                        !(
                                                                                                                                                                                            typeof data2.start_time ==
                                                                                                                                                                                            'number'
                                                                                                                                                                                        )
                                                                                                                                                                                    ) {
                                                                                                                                                                                        validate25.errors =
                                                                                                                                                                                            [
                                                                                                                                                                                                {
                                                                                                                                                                                                    instancePath:
                                                                                                                                                                                                        instancePath +
                                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                                        i0 +
                                                                                                                                                                                                        '/start_time',
                                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/start_time/type',
                                                                                                                                                                                                    keyword:
                                                                                                                                                                                                        'type',
                                                                                                                                                                                                    params: {
                                                                                                                                                                                                        type: 'number',
                                                                                                                                                                                                    },
                                                                                                                                                                                                    message:
                                                                                                                                                                                                        'must be number',
                                                                                                                                                                                                },
                                                                                                                                                                                            ];
                                                                                                                                                                                        return false;
                                                                                                                                                                                    }
                                                                                                                                                                                    var valid3 =
                                                                                                                                                                                        _errs70 ===
                                                                                                                                                                                        errors;
                                                                                                                                                                                } else {
                                                                                                                                                                                    var valid3 =
                                                                                                                                                                                        true;
                                                                                                                                                                                }
                                                                                                                                                                                if (
                                                                                                                                                                                    valid3
                                                                                                                                                                                ) {
                                                                                                                                                                                    if (
                                                                                                                                                                                        data2.transfer_supported !==
                                                                                                                                                                                        undefined
                                                                                                                                                                                    ) {
                                                                                                                                                                                        let data34 =
                                                                                                                                                                                            data2.transfer_supported;
                                                                                                                                                                                        const _errs72 =
                                                                                                                                                                                            errors;
                                                                                                                                                                                        if (
                                                                                                                                                                                            typeof data34 !==
                                                                                                                                                                                            'string'
                                                                                                                                                                                        ) {
                                                                                                                                                                                            validate25.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath:
                                                                                                                                                                                                            instancePath +
                                                                                                                                                                                                            '/result/' +
                                                                                                                                                                                                            i0 +
                                                                                                                                                                                                            '/transfer_supported',
                                                                                                                                                                                                        schemaPath:
                                                                                                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/transfer_supported/type',
                                                                                                                                                                                                        keyword:
                                                                                                                                                                                                            'type',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            type: 'string',
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message:
                                                                                                                                                                                                            'must be string',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        if (
                                                                                                                                                                                            !(
                                                                                                                                                                                                data34 ===
                                                                                                                                                                                                    'YES' ||
                                                                                                                                                                                                data34 ===
                                                                                                                                                                                                    'NO'
                                                                                                                                                                                            )
                                                                                                                                                                                        ) {
                                                                                                                                                                                            validate25.errors =
                                                                                                                                                                                                [
                                                                                                                                                                                                    {
                                                                                                                                                                                                        instancePath:
                                                                                                                                                                                                            instancePath +
                                                                                                                                                                                                            '/result/' +
                                                                                                                                                                                                            i0 +
                                                                                                                                                                                                            '/transfer_supported',
                                                                                                                                                                                                        schemaPath:
                                                                                                                                                                                                            '#/definitions/participants/properties/200/definitions/Participant/properties/transfer_supported/enum',
                                                                                                                                                                                                        keyword:
                                                                                                                                                                                                            'enum',
                                                                                                                                                                                                        params: {
                                                                                                                                                                                                            allowedValues:
                                                                                                                                                                                                                schema37
                                                                                                                                                                                                                    .properties
                                                                                                                                                                                                                    .transfer_supported
                                                                                                                                                                                                                    .enum,
                                                                                                                                                                                                        },
                                                                                                                                                                                                        message:
                                                                                                                                                                                                            'must be equal to one of the allowed values',
                                                                                                                                                                                                    },
                                                                                                                                                                                                ];
                                                                                                                                                                                            return false;
                                                                                                                                                                                        }
                                                                                                                                                                                        var valid3 =
                                                                                                                                                                                            _errs72 ===
                                                                                                                                                                                            errors;
                                                                                                                                                                                    } else {
                                                                                                                                                                                        var valid3 =
                                                                                                                                                                                            true;
                                                                                                                                                                                    }
                                                                                                                                                                                    if (
                                                                                                                                                                                        valid3
                                                                                                                                                                                    ) {
                                                                                                                                                                                        if (
                                                                                                                                                                                            data2.uuid !==
                                                                                                                                                                                            undefined
                                                                                                                                                                                        ) {
                                                                                                                                                                                            const _errs74 =
                                                                                                                                                                                                errors;
                                                                                                                                                                                            if (
                                                                                                                                                                                                typeof data2.uuid !==
                                                                                                                                                                                                'string'
                                                                                                                                                                                            ) {
                                                                                                                                                                                                validate25.errors =
                                                                                                                                                                                                    [
                                                                                                                                                                                                        {
                                                                                                                                                                                                            instancePath:
                                                                                                                                                                                                                instancePath +
                                                                                                                                                                                                                '/result/' +
                                                                                                                                                                                                                i0 +
                                                                                                                                                                                                                '/uuid',
                                                                                                                                                                                                            schemaPath:
                                                                                                                                                                                                                '#/definitions/participants/properties/200/definitions/Participant/properties/uuid/type',
                                                                                                                                                                                                            keyword:
                                                                                                                                                                                                                'type',
                                                                                                                                                                                                            params: {
                                                                                                                                                                                                                type: 'string',
                                                                                                                                                                                                            },
                                                                                                                                                                                                            message:
                                                                                                                                                                                                                'must be string',
                                                                                                                                                                                                        },
                                                                                                                                                                                                    ];
                                                                                                                                                                                                return false;
                                                                                                                                                                                            }
                                                                                                                                                                                            var valid3 =
                                                                                                                                                                                                _errs74 ===
                                                                                                                                                                                                errors;
                                                                                                                                                                                        } else {
                                                                                                                                                                                            var valid3 =
                                                                                                                                                                                                true;
                                                                                                                                                                                        }
                                                                                                                                                                                        if (
                                                                                                                                                                                            valid3
                                                                                                                                                                                        ) {
                                                                                                                                                                                            if (
                                                                                                                                                                                                data2.uri !==
                                                                                                                                                                                                undefined
                                                                                                                                                                                            ) {
                                                                                                                                                                                                const _errs76 =
                                                                                                                                                                                                    errors;
                                                                                                                                                                                                if (
                                                                                                                                                                                                    typeof data2.uri !==
                                                                                                                                                                                                    'string'
                                                                                                                                                                                                ) {
                                                                                                                                                                                                    validate25.errors =
                                                                                                                                                                                                        [
                                                                                                                                                                                                            {
                                                                                                                                                                                                                instancePath:
                                                                                                                                                                                                                    instancePath +
                                                                                                                                                                                                                    '/result/' +
                                                                                                                                                                                                                    i0 +
                                                                                                                                                                                                                    '/uri',
                                                                                                                                                                                                                schemaPath:
                                                                                                                                                                                                                    '#/definitions/participants/properties/200/definitions/Participant/properties/uri/type',
                                                                                                                                                                                                                keyword:
                                                                                                                                                                                                                    'type',
                                                                                                                                                                                                                params: {
                                                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                                                },
                                                                                                                                                                                                                message:
                                                                                                                                                                                                                    'must be string',
                                                                                                                                                                                                            },
                                                                                                                                                                                                        ];
                                                                                                                                                                                                    return false;
                                                                                                                                                                                                }
                                                                                                                                                                                                var valid3 =
                                                                                                                                                                                                    _errs76 ===
                                                                                                                                                                                                    errors;
                                                                                                                                                                                            } else {
                                                                                                                                                                                                var valid3 =
                                                                                                                                                                                                    true;
                                                                                                                                                                                            }
                                                                                                                                                                                            if (
                                                                                                                                                                                                valid3
                                                                                                                                                                                            ) {
                                                                                                                                                                                                if (
                                                                                                                                                                                                    data2.vendor !==
                                                                                                                                                                                                    undefined
                                                                                                                                                                                                ) {
                                                                                                                                                                                                    const _errs78 =
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                    if (
                                                                                                                                                                                                        typeof data2.vendor !==
                                                                                                                                                                                                        'string'
                                                                                                                                                                                                    ) {
                                                                                                                                                                                                        validate25.errors =
                                                                                                                                                                                                            [
                                                                                                                                                                                                                {
                                                                                                                                                                                                                    instancePath:
                                                                                                                                                                                                                        instancePath +
                                                                                                                                                                                                                        '/result/' +
                                                                                                                                                                                                                        i0 +
                                                                                                                                                                                                                        '/vendor',
                                                                                                                                                                                                                    schemaPath:
                                                                                                                                                                                                                        '#/definitions/participants/properties/200/definitions/Participant/properties/vendor/type',
                                                                                                                                                                                                                    keyword:
                                                                                                                                                                                                                        'type',
                                                                                                                                                                                                                    params: {
                                                                                                                                                                                                                        type: 'string',
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                    message:
                                                                                                                                                                                                                        'must be string',
                                                                                                                                                                                                                },
                                                                                                                                                                                                            ];
                                                                                                                                                                                                        return false;
                                                                                                                                                                                                    }
                                                                                                                                                                                                    var valid3 =
                                                                                                                                                                                                        _errs78 ===
                                                                                                                                                                                                        errors;
                                                                                                                                                                                                } else {
                                                                                                                                                                                                    var valid3 =
                                                                                                                                                                                                        true;
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                validate25.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/result/' +
                                                            i0,
                                                        schemaPath:
                                                            '#/definitions/participants/properties/200/definitions/Participant/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'object',
                                                        },
                                                        message:
                                                            'must be object',
                                                    },
                                                ];
                                                return false;
                                            }
                                        }
                                        var valid1 = _errs6 === errors;
                                        if (!valid1) {
                                            break;
                                        }
                                    }
                                } else {
                                    validate25.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/properties/result/type',
                                            keyword: 'type',
                                            params: {type: 'array'},
                                            message: 'must be array',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate25.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate25.errors = vErrors;
    return errors === 0;
}
function validate24(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate25(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate25.errors
                : vErrors.concat(validate25.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate24.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate24.errors = vErrors;
    return errors === 0;
}
export const validateTransformLayoutBody = validate27;
const schema38 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                transforms: {
                    $ref: '#/definitions/transform_layout/properties/Body/definitions/Transforms',
                },
            },
            required: ['transforms'],
            title: 'TopLevel',
        },
        Transforms: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    description:
                        'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
                },
                host_layout: {
                    type: 'string',
                    description:
                        'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
                },
                guest_layout: {
                    type: 'string',
                    description:
                        'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
                },
                enable_extended_ac: {
                    type: 'boolean',
                    description:
                        'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
                },
                streaming_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
                },
                recording_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
                },
                enable_active_speaker_indication: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                enable_overlay_text: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                streaming: {
                    $ref: '#/definitions/transform_layout/properties/Body/definitions/Streaming',
                },
                free_form_overlay_text: {
                    type: 'array',
                    description:
                        'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
                    items: {type: 'string'},
                },
            },
            required: [],
            title: 'Transforms',
        },
        Streaming: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    description:
                        'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
                },
                waiting_screen_enabled: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
                },
                plus_n_pip_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
                },
                indicators_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
                },
            },
            required: [],
            title: 'Streaming',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/transform_layout/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema39 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        transforms: {
            $ref: '#/definitions/transform_layout/properties/Body/definitions/Transforms',
        },
    },
    required: ['transforms'],
    title: 'TopLevel',
};
const schema40 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            description:
                'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
        },
        host_layout: {
            type: 'string',
            description:
                'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
        },
        guest_layout: {
            type: 'string',
            description:
                'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
        },
        enable_extended_ac: {
            type: 'boolean',
            description:
                'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
        },
        streaming_indicator: {
            type: 'boolean',
            description:
                'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
        },
        recording_indicator: {
            type: 'boolean',
            description:
                'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
        },
        enable_active_speaker_indication: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        enable_overlay_text: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        streaming: {
            $ref: '#/definitions/transform_layout/properties/Body/definitions/Streaming',
        },
        free_form_overlay_text: {
            type: 'array',
            description:
                'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
            items: {type: 'string'},
        },
    },
    required: [],
    title: 'Transforms',
};
const schema41 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            description:
                'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
        },
        waiting_screen_enabled: {
            type: 'boolean',
            default: false,
            description:
                'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
        },
        plus_n_pip_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
        },
        indicators_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
        },
    },
    required: [],
    title: 'Streaming',
};
function validate29(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs1 = errors;
            for (const key0 in data) {
                if (!func2.call(schema40.properties, key0)) {
                    delete data[key0];
                }
            }
            if (_errs1 === errors) {
                if (data.layout !== undefined) {
                    const _errs2 = errors;
                    if (typeof data.layout !== 'string') {
                        validate29.errors = [
                            {
                                instancePath: instancePath + '/layout',
                                schemaPath: '#/properties/layout/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            },
                        ];
                        return false;
                    }
                    var valid0 = _errs2 === errors;
                } else {
                    var valid0 = true;
                }
                if (valid0) {
                    if (data.host_layout !== undefined) {
                        const _errs4 = errors;
                        if (typeof data.host_layout !== 'string') {
                            validate29.errors = [
                                {
                                    instancePath: instancePath + '/host_layout',
                                    schemaPath: '#/properties/host_layout/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs4 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.guest_layout !== undefined) {
                            const _errs6 = errors;
                            if (typeof data.guest_layout !== 'string') {
                                validate29.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_layout',
                                        schemaPath:
                                            '#/properties/guest_layout/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs6 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.enable_extended_ac !== undefined) {
                                const _errs8 = errors;
                                if (
                                    typeof data.enable_extended_ac !== 'boolean'
                                ) {
                                    validate29.errors = [
                                        {
                                            instancePath:
                                                instancePath +
                                                '/enable_extended_ac',
                                            schemaPath:
                                                '#/properties/enable_extended_ac/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs8 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.streaming_indicator !== undefined) {
                                    const _errs10 = errors;
                                    if (
                                        typeof data.streaming_indicator !==
                                        'boolean'
                                    ) {
                                        validate29.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/streaming_indicator',
                                                schemaPath:
                                                    '#/properties/streaming_indicator/type',
                                                keyword: 'type',
                                                params: {type: 'boolean'},
                                                message: 'must be boolean',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs10 === errors;
                                } else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (
                                        data.recording_indicator !== undefined
                                    ) {
                                        const _errs12 = errors;
                                        if (
                                            typeof data.recording_indicator !==
                                            'boolean'
                                        ) {
                                            validate29.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/recording_indicator',
                                                    schemaPath:
                                                        '#/properties/recording_indicator/type',
                                                    keyword: 'type',
                                                    params: {type: 'boolean'},
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs12 === errors;
                                    } else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (
                                            data.enable_active_speaker_indication !==
                                            undefined
                                        ) {
                                            const _errs14 = errors;
                                            if (
                                                typeof data.enable_active_speaker_indication !==
                                                'boolean'
                                            ) {
                                                validate29.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/enable_active_speaker_indication',
                                                        schemaPath:
                                                            '#/properties/enable_active_speaker_indication/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'boolean',
                                                        },
                                                        message:
                                                            'must be boolean',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs14 === errors;
                                        } else {
                                            var valid0 = true;
                                        }
                                        if (valid0) {
                                            if (
                                                data.enable_overlay_text !==
                                                undefined
                                            ) {
                                                const _errs16 = errors;
                                                if (
                                                    typeof data.enable_overlay_text !==
                                                    'boolean'
                                                ) {
                                                    validate29.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/enable_overlay_text',
                                                            schemaPath:
                                                                '#/properties/enable_overlay_text/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid0 = _errs16 === errors;
                                            } else {
                                                var valid0 = true;
                                            }
                                            if (valid0) {
                                                if (
                                                    data.streaming !== undefined
                                                ) {
                                                    let data8 = data.streaming;
                                                    const _errs18 = errors;
                                                    const _errs19 = errors;
                                                    if (errors === _errs19) {
                                                        if (
                                                            data8 &&
                                                            typeof data8 ==
                                                                'object' &&
                                                            !Array.isArray(
                                                                data8,
                                                            )
                                                        ) {
                                                            const _errs21 =
                                                                errors;
                                                            for (const key1 in data8) {
                                                                if (
                                                                    !(
                                                                        key1 ===
                                                                            'layout' ||
                                                                        key1 ===
                                                                            'waiting_screen_enabled' ||
                                                                        key1 ===
                                                                            'plus_n_pip_enabled' ||
                                                                        key1 ===
                                                                            'indicators_enabled'
                                                                    )
                                                                ) {
                                                                    delete data8[
                                                                        key1
                                                                    ];
                                                                }
                                                            }
                                                            if (
                                                                _errs21 ===
                                                                errors
                                                            ) {
                                                                if (
                                                                    data8.layout !==
                                                                    undefined
                                                                ) {
                                                                    const _errs22 =
                                                                        errors;
                                                                    if (
                                                                        typeof data8.layout !==
                                                                        'string'
                                                                    ) {
                                                                        validate29.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/streaming/layout',
                                                                                    schemaPath:
                                                                                        '#/definitions/transform_layout/properties/Body/definitions/Streaming/properties/layout/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid2 =
                                                                        _errs22 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                                if (valid2) {
                                                                    if (
                                                                        data8.waiting_screen_enabled !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs24 =
                                                                            errors;
                                                                        if (
                                                                            typeof data8.waiting_screen_enabled !==
                                                                            'boolean'
                                                                        ) {
                                                                            validate29.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/streaming/waiting_screen_enabled',
                                                                                        schemaPath:
                                                                                            '#/definitions/transform_layout/properties/Body/definitions/Streaming/properties/waiting_screen_enabled/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'boolean',
                                                                                        },
                                                                                        message:
                                                                                            'must be boolean',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid2 =
                                                                            _errs24 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid2 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid2
                                                                    ) {
                                                                        if (
                                                                            data8.plus_n_pip_enabled !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs26 =
                                                                                errors;
                                                                            if (
                                                                                typeof data8.plus_n_pip_enabled !==
                                                                                'boolean'
                                                                            ) {
                                                                                validate29.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/streaming/plus_n_pip_enabled',
                                                                                            schemaPath:
                                                                                                '#/definitions/transform_layout/properties/Body/definitions/Streaming/properties/plus_n_pip_enabled/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'boolean',
                                                                                            },
                                                                                            message:
                                                                                                'must be boolean',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid2 =
                                                                                _errs26 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid2 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid2
                                                                        ) {
                                                                            if (
                                                                                data8.indicators_enabled !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs28 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data8.indicators_enabled !==
                                                                                    'boolean'
                                                                                ) {
                                                                                    validate29.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/streaming/indicators_enabled',
                                                                                                schemaPath:
                                                                                                    '#/definitions/transform_layout/properties/Body/definitions/Streaming/properties/indicators_enabled/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'boolean',
                                                                                                },
                                                                                                message:
                                                                                                    'must be boolean',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid2 =
                                                                                    _errs28 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid2 =
                                                                                    true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            validate29.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/streaming',
                                                                        schemaPath:
                                                                            '#/definitions/transform_layout/properties/Body/definitions/Streaming/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'object',
                                                                        },
                                                                        message:
                                                                            'must be object',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                    }
                                                    var valid0 =
                                                        _errs18 === errors;
                                                } else {
                                                    var valid0 = true;
                                                }
                                                if (valid0) {
                                                    if (
                                                        data.free_form_overlay_text !==
                                                        undefined
                                                    ) {
                                                        let data13 =
                                                            data.free_form_overlay_text;
                                                        const _errs30 = errors;
                                                        if (
                                                            errors === _errs30
                                                        ) {
                                                            if (
                                                                Array.isArray(
                                                                    data13,
                                                                )
                                                            ) {
                                                                var valid3 =
                                                                    true;
                                                                const len0 =
                                                                    data13.length;
                                                                for (
                                                                    let i0 = 0;
                                                                    i0 < len0;
                                                                    i0++
                                                                ) {
                                                                    const _errs32 =
                                                                        errors;
                                                                    if (
                                                                        typeof data13[
                                                                            i0
                                                                        ] !==
                                                                        'string'
                                                                    ) {
                                                                        validate29.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/free_form_overlay_text/' +
                                                                                        i0,
                                                                                    schemaPath:
                                                                                        '#/properties/free_form_overlay_text/items/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs32 ===
                                                                        errors;
                                                                    if (
                                                                        !valid3
                                                                    ) {
                                                                        break;
                                                                    }
                                                                }
                                                            } else {
                                                                validate29.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/free_form_overlay_text',
                                                                            schemaPath:
                                                                                '#/properties/free_form_overlay_text/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'array',
                                                                            },
                                                                            message:
                                                                                'must be array',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                        }
                                                        var valid0 =
                                                            _errs30 === errors;
                                                    } else {
                                                        var valid0 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate29.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate29.errors = vErrors;
    return errors === 0;
}
function validate28(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.transforms === undefined && (missing0 = 'transforms')) {
                validate28.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'transforms')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.transforms !== undefined) {
                        if (
                            !validate29(data.transforms, {
                                instancePath: instancePath + '/transforms',
                                parentData: data,
                                parentDataProperty: 'transforms',
                                rootData,
                            })
                        ) {
                            vErrors =
                                vErrors === null
                                    ? validate29.errors
                                    : vErrors.concat(validate29.errors);
                            errors = vErrors.length;
                        }
                    }
                }
            }
        } else {
            validate28.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate28.errors = vErrors;
    return errors === 0;
}
function validate27(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate28(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate28.errors
                : vErrors.concat(validate28.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate27.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate27.errors = vErrors;
    return errors === 0;
}
export const validateTransformLayout200 = validate32;
function validate32(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate32.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate32.errors = vErrors;
    return errors === 0;
}
export const validateClearallbuzz200 = validate33;
function validate33(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate33.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate33.errors = vErrors;
    return errors === 0;
}
export const validateSilentVideoDetectionBody = validate34;
const schema46 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                config: {
                    $ref: '#/definitions/silent_video_detection/properties/Body/definitions/Config',
                },
            },
            required: ['config'],
            title: 'TopLevel',
        },
        Config: {
            type: 'object',
            additionalProperties: false,
            properties: {
                enable: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Determines whether silent video detection (no faces or movement) is disabled (false) or enabled (true).',
                },
                silent_after: {
                    type: 'number',
                    default: 15,
                    description:
                        "The minimum number of seconds the participant's video has to silent before it is considered for removal from the video mix (it may take longer than this before the video is actually removed). Values from 1-120.",
                },
                require_no_faces: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Determines whether or not detected faces in the video are taken into consideration:\ntrue: a participant cannot be marked as silent if a face is detected.\nfalse: face-detection is ignored.',
                },
                reactivate_after: {
                    type: 'number',
                    default: 2,
                    description:
                        'The minimum number of seconds of moving video that is required before marking the participant as no longer silent. Values 1-5.',
                },
            },
            required: [],
            title: 'Config',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/silent_video_detection/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema47 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        config: {
            $ref: '#/definitions/silent_video_detection/properties/Body/definitions/Config',
        },
    },
    required: ['config'],
    title: 'TopLevel',
};
const schema48 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        enable: {
            type: 'boolean',
            default: true,
            description:
                'Determines whether silent video detection (no faces or movement) is disabled (false) or enabled (true).',
        },
        silent_after: {
            type: 'number',
            default: 15,
            description:
                "The minimum number of seconds the participant's video has to silent before it is considered for removal from the video mix (it may take longer than this before the video is actually removed). Values from 1-120.",
        },
        require_no_faces: {
            type: 'boolean',
            default: true,
            description:
                'Determines whether or not detected faces in the video are taken into consideration:\ntrue: a participant cannot be marked as silent if a face is detected.\nfalse: face-detection is ignored.',
        },
        reactivate_after: {
            type: 'number',
            default: 2,
            description:
                'The minimum number of seconds of moving video that is required before marking the participant as no longer silent. Values 1-5.',
        },
    },
    required: [],
    title: 'Config',
};
function validate35(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.config === undefined && (missing0 = 'config')) {
                validate35.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'config')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.config !== undefined) {
                        let data0 = data.config;
                        const _errs3 = errors;
                        if (errors === _errs3) {
                            if (
                                data0 &&
                                typeof data0 == 'object' &&
                                !Array.isArray(data0)
                            ) {
                                const _errs5 = errors;
                                for (const key1 in data0) {
                                    if (
                                        !(
                                            key1 === 'enable' ||
                                            key1 === 'silent_after' ||
                                            key1 === 'require_no_faces' ||
                                            key1 === 'reactivate_after'
                                        )
                                    ) {
                                        delete data0[key1];
                                    }
                                }
                                if (_errs5 === errors) {
                                    if (data0.enable !== undefined) {
                                        const _errs6 = errors;
                                        if (typeof data0.enable !== 'boolean') {
                                            validate35.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/config/enable',
                                                    schemaPath:
                                                        '#/definitions/silent_video_detection/properties/Body/definitions/Config/properties/enable/type',
                                                    keyword: 'type',
                                                    params: {type: 'boolean'},
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid2 = _errs6 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                    if (valid2) {
                                        if (data0.silent_after !== undefined) {
                                            const _errs8 = errors;
                                            if (
                                                !(
                                                    typeof data0.silent_after ==
                                                    'number'
                                                )
                                            ) {
                                                validate35.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/config/silent_after',
                                                        schemaPath:
                                                            '#/definitions/silent_video_detection/properties/Body/definitions/Config/properties/silent_after/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'number',
                                                        },
                                                        message:
                                                            'must be number',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid2 = _errs8 === errors;
                                        } else {
                                            var valid2 = true;
                                        }
                                        if (valid2) {
                                            if (
                                                data0.require_no_faces !==
                                                undefined
                                            ) {
                                                const _errs10 = errors;
                                                if (
                                                    typeof data0.require_no_faces !==
                                                    'boolean'
                                                ) {
                                                    validate35.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/config/require_no_faces',
                                                            schemaPath:
                                                                '#/definitions/silent_video_detection/properties/Body/definitions/Config/properties/require_no_faces/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs10 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (
                                                    data0.reactivate_after !==
                                                    undefined
                                                ) {
                                                    const _errs12 = errors;
                                                    if (
                                                        !(
                                                            typeof data0.reactivate_after ==
                                                            'number'
                                                        )
                                                    ) {
                                                        validate35.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/config/reactivate_after',
                                                                schemaPath:
                                                                    '#/definitions/silent_video_detection/properties/Body/definitions/Config/properties/reactivate_after/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'number',
                                                                },
                                                                message:
                                                                    'must be number',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs12 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                validate35.errors = [
                                    {
                                        instancePath: instancePath + '/config',
                                        schemaPath:
                                            '#/definitions/silent_video_detection/properties/Body/definitions/Config/type',
                                        keyword: 'type',
                                        params: {type: 'object'},
                                        message: 'must be object',
                                    },
                                ];
                                return false;
                            }
                        }
                    }
                }
            }
        } else {
            validate35.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate35.errors = vErrors;
    return errors === 0;
}
function validate34(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate35(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate35.errors
                : vErrors.concat(validate35.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate34.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate34.errors = vErrors;
    return errors === 0;
}
export const validateSilentVideoDetection200 = validate37;
function validate37(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate37.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate37.errors = vErrors;
    return errors === 0;
}
export const validateTheme200 = validate38;
const schema51 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    type: 'object',
                    additionalProperties: false,
                    properties: {
                        direct_media_welcome: {
                            $ref: '#/definitions/theme/properties/200/definitions/Screen',
                        },
                        direct_media_waiting_for_host: {
                            $ref: '#/definitions/theme/properties/200/definitions/Screen',
                        },
                        direct_media_other_participants_audio_only: {
                            $ref: '#/definitions/theme/properties/200/definitions/Screen',
                        },
                        direct_media_escalate: {
                            $ref: '#/definitions/theme/properties/200/definitions/Screen',
                        },
                        direct_media_deescalate: {
                            $ref: '#/definitions/theme/properties/200/definitions/Screen',
                        },
                    },
                    required: [
                        'direct_media_welcome',
                        'direct_media_waiting_for_host',
                        'direct_media_other_participants_audio_only',
                        'direct_media_escalate',
                        'direct_media_deescalate',
                    ],
                    title: 'Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Screen: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout_type: {type: 'string', enum: ['direct_media']},
                background: {
                    $ref: '#/definitions/theme/properties/200/definitions/Background',
                },
                elements: {
                    type: 'array',
                    items: {
                        oneOf: [
                            {
                                $ref: '#/definitions/theme/properties/200/definitions/Text',
                            },
                        ],
                    },
                },
            },
            required: ['layout_type', 'background', 'elements'],
            title: 'Screen',
        },
        Background: {
            type: 'object',
            additionalProperties: false,
            properties: {path: {type: 'string'}},
            required: ['path'],
            title: 'Background',
        },
        Text: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: {type: 'string', enum: ['text']},
                color: {type: 'number'},
                text: {type: 'string'},
            },
            required: ['type', 'color', 'text'],
            title: 'Text',
        },
    },
    oneOf: [{$ref: '#/definitions/theme/properties/200/definitions/200'}],
};
const schema52 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                direct_media_welcome: {
                    $ref: '#/definitions/theme/properties/200/definitions/Screen',
                },
                direct_media_waiting_for_host: {
                    $ref: '#/definitions/theme/properties/200/definitions/Screen',
                },
                direct_media_other_participants_audio_only: {
                    $ref: '#/definitions/theme/properties/200/definitions/Screen',
                },
                direct_media_escalate: {
                    $ref: '#/definitions/theme/properties/200/definitions/Screen',
                },
                direct_media_deescalate: {
                    $ref: '#/definitions/theme/properties/200/definitions/Screen',
                },
            },
            required: [
                'direct_media_welcome',
                'direct_media_waiting_for_host',
                'direct_media_other_participants_audio_only',
                'direct_media_escalate',
                'direct_media_deescalate',
            ],
            title: 'Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema53 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout_type: {type: 'string', enum: ['direct_media']},
        background: {
            $ref: '#/definitions/theme/properties/200/definitions/Background',
        },
        elements: {
            type: 'array',
            items: {
                oneOf: [
                    {
                        $ref: '#/definitions/theme/properties/200/definitions/Text',
                    },
                ],
            },
        },
    },
    required: ['layout_type', 'background', 'elements'],
    title: 'Screen',
};
const schema54 = {
    type: 'object',
    additionalProperties: false,
    properties: {path: {type: 'string'}},
    required: ['path'],
    title: 'Background',
};
const schema55 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: {type: 'string', enum: ['text']},
        color: {type: 'number'},
        text: {type: 'string'},
    },
    required: ['type', 'color', 'text'],
    title: 'Text',
};
function validate40(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.layout_type === undefined &&
                    (missing0 = 'layout_type')) ||
                (data.background === undefined && (missing0 = 'background')) ||
                (data.elements === undefined && (missing0 = 'elements'))
            ) {
                validate40.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'layout_type' ||
                            key0 === 'background' ||
                            key0 === 'elements'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.layout_type !== undefined) {
                        let data0 = data.layout_type;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate40.errors = [
                                {
                                    instancePath: instancePath + '/layout_type',
                                    schemaPath: '#/properties/layout_type/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'direct_media')) {
                            validate40.errors = [
                                {
                                    instancePath: instancePath + '/layout_type',
                                    schemaPath: '#/properties/layout_type/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema53.properties.layout_type
                                                .enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.background !== undefined) {
                            let data1 = data.background;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        data1.path === undefined &&
                                        (missing1 = 'path')
                                    ) {
                                        validate40.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/background',
                                                schemaPath:
                                                    '#/definitions/theme/properties/200/definitions/Background/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (!(key1 === 'path')) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.path !== undefined) {
                                                if (
                                                    typeof data1.path !==
                                                    'string'
                                                ) {
                                                    validate40.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/background/path',
                                                            schemaPath:
                                                                '#/definitions/theme/properties/200/definitions/Background/properties/path/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate40.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/background',
                                            schemaPath:
                                                '#/definitions/theme/properties/200/definitions/Background/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.elements !== undefined) {
                                let data3 = data.elements;
                                const _errs10 = errors;
                                if (errors === _errs10) {
                                    if (Array.isArray(data3)) {
                                        var valid3 = true;
                                        const len0 = data3.length;
                                        for (let i0 = 0; i0 < len0; i0++) {
                                            let data4 = data3[i0];
                                            const _errs12 = errors;
                                            const _errs13 = errors;
                                            let valid4 = false;
                                            let passing0 = null;
                                            const _errs14 = errors;
                                            const _errs15 = errors;
                                            if (errors === _errs15) {
                                                if (
                                                    data4 &&
                                                    typeof data4 == 'object' &&
                                                    !Array.isArray(data4)
                                                ) {
                                                    let missing2;
                                                    if (
                                                        (data4.type ===
                                                            undefined &&
                                                            (missing2 =
                                                                'type')) ||
                                                        (data4.color ===
                                                            undefined &&
                                                            (missing2 =
                                                                'color')) ||
                                                        (data4.text ===
                                                            undefined &&
                                                            (missing2 = 'text'))
                                                    ) {
                                                        const err0 = {
                                                            instancePath:
                                                                instancePath +
                                                                '/elements/' +
                                                                i0,
                                                            schemaPath:
                                                                '#/definitions/theme/properties/200/definitions/Text/required',
                                                            keyword: 'required',
                                                            params: {
                                                                missingProperty:
                                                                    missing2,
                                                            },
                                                            message:
                                                                "must have required property '" +
                                                                missing2 +
                                                                "'",
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err0];
                                                        } else {
                                                            vErrors.push(err0);
                                                        }
                                                        errors++;
                                                    } else {
                                                        const _errs17 = errors;
                                                        for (const key2 in data4) {
                                                            if (
                                                                !(
                                                                    key2 ===
                                                                        'type' ||
                                                                    key2 ===
                                                                        'color' ||
                                                                    key2 ===
                                                                        'text'
                                                                )
                                                            ) {
                                                                delete data4[
                                                                    key2
                                                                ];
                                                            }
                                                        }
                                                        if (
                                                            _errs17 === errors
                                                        ) {
                                                            if (
                                                                data4.type !==
                                                                undefined
                                                            ) {
                                                                let data5 =
                                                                    data4.type;
                                                                const _errs18 =
                                                                    errors;
                                                                if (
                                                                    typeof data5 !==
                                                                    'string'
                                                                ) {
                                                                    const err1 =
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/elements/' +
                                                                                i0 +
                                                                                '/type',
                                                                            schemaPath:
                                                                                '#/definitions/theme/properties/200/definitions/Text/properties/type/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'string',
                                                                            },
                                                                            message:
                                                                                'must be string',
                                                                        };
                                                                    if (
                                                                        vErrors ===
                                                                        null
                                                                    ) {
                                                                        vErrors =
                                                                            [
                                                                                err1,
                                                                            ];
                                                                    } else {
                                                                        vErrors.push(
                                                                            err1,
                                                                        );
                                                                    }
                                                                    errors++;
                                                                }
                                                                if (
                                                                    !(
                                                                        data5 ===
                                                                        'text'
                                                                    )
                                                                ) {
                                                                    const err2 =
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/elements/' +
                                                                                i0 +
                                                                                '/type',
                                                                            schemaPath:
                                                                                '#/definitions/theme/properties/200/definitions/Text/properties/type/enum',
                                                                            keyword:
                                                                                'enum',
                                                                            params: {
                                                                                allowedValues:
                                                                                    schema55
                                                                                        .properties
                                                                                        .type
                                                                                        .enum,
                                                                            },
                                                                            message:
                                                                                'must be equal to one of the allowed values',
                                                                        };
                                                                    if (
                                                                        vErrors ===
                                                                        null
                                                                    ) {
                                                                        vErrors =
                                                                            [
                                                                                err2,
                                                                            ];
                                                                    } else {
                                                                        vErrors.push(
                                                                            err2,
                                                                        );
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid6 =
                                                                    _errs18 ===
                                                                    errors;
                                                            } else {
                                                                var valid6 =
                                                                    true;
                                                            }
                                                            if (valid6) {
                                                                if (
                                                                    data4.color !==
                                                                    undefined
                                                                ) {
                                                                    const _errs20 =
                                                                        errors;
                                                                    if (
                                                                        !(
                                                                            typeof data4.color ==
                                                                            'number'
                                                                        )
                                                                    ) {
                                                                        const err3 =
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/elements/' +
                                                                                    i0 +
                                                                                    '/color',
                                                                                schemaPath:
                                                                                    '#/definitions/theme/properties/200/definitions/Text/properties/color/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'number',
                                                                                },
                                                                                message:
                                                                                    'must be number',
                                                                            };
                                                                        if (
                                                                            vErrors ===
                                                                            null
                                                                        ) {
                                                                            vErrors =
                                                                                [
                                                                                    err3,
                                                                                ];
                                                                        } else {
                                                                            vErrors.push(
                                                                                err3,
                                                                            );
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    var valid6 =
                                                                        _errs20 ===
                                                                        errors;
                                                                } else {
                                                                    var valid6 =
                                                                        true;
                                                                }
                                                                if (valid6) {
                                                                    if (
                                                                        data4.text !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs22 =
                                                                            errors;
                                                                        if (
                                                                            typeof data4.text !==
                                                                            'string'
                                                                        ) {
                                                                            const err4 =
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/elements/' +
                                                                                        i0 +
                                                                                        '/text',
                                                                                    schemaPath:
                                                                                        '#/definitions/theme/properties/200/definitions/Text/properties/text/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                };
                                                                            if (
                                                                                vErrors ===
                                                                                null
                                                                            ) {
                                                                                vErrors =
                                                                                    [
                                                                                        err4,
                                                                                    ];
                                                                            } else {
                                                                                vErrors.push(
                                                                                    err4,
                                                                                );
                                                                            }
                                                                            errors++;
                                                                        }
                                                                        var valid6 =
                                                                            _errs22 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid6 =
                                                                            true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    const err5 = {
                                                        instancePath:
                                                            instancePath +
                                                            '/elements/' +
                                                            i0,
                                                        schemaPath:
                                                            '#/definitions/theme/properties/200/definitions/Text/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'object',
                                                        },
                                                        message:
                                                            'must be object',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err5];
                                                    } else {
                                                        vErrors.push(err5);
                                                    }
                                                    errors++;
                                                }
                                            }
                                            var _valid0 = _errs14 === errors;
                                            if (_valid0) {
                                                valid4 = true;
                                                passing0 = 0;
                                            }
                                            if (!valid4) {
                                                const err6 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/elements/' +
                                                        i0,
                                                    schemaPath:
                                                        '#/properties/elements/items/oneOf',
                                                    keyword: 'oneOf',
                                                    params: {
                                                        passingSchemas:
                                                            passing0,
                                                    },
                                                    message:
                                                        'must match exactly one schema in oneOf',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err6];
                                                } else {
                                                    vErrors.push(err6);
                                                }
                                                errors++;
                                                validate40.errors = vErrors;
                                                return false;
                                            } else {
                                                errors = _errs13;
                                                if (vErrors !== null) {
                                                    if (_errs13) {
                                                        vErrors.length =
                                                            _errs13;
                                                    } else {
                                                        vErrors = null;
                                                    }
                                                }
                                            }
                                            var valid3 = _errs12 === errors;
                                            if (!valid3) {
                                                break;
                                            }
                                        }
                                    } else {
                                        validate40.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/elements',
                                                schemaPath:
                                                    '#/properties/elements/type',
                                                keyword: 'type',
                                                params: {type: 'array'},
                                                message: 'must be array',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var valid0 = _errs10 === errors;
                            } else {
                                var valid0 = true;
                            }
                        }
                    }
                }
            }
        } else {
            validate40.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate40.errors = vErrors;
    return errors === 0;
}
function validate39(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate39.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate39.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate39.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema52.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            if (errors === _errs4) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.direct_media_welcome ===
                                            undefined &&
                                            (missing1 =
                                                'direct_media_welcome')) ||
                                        (data1.direct_media_waiting_for_host ===
                                            undefined &&
                                            (missing1 =
                                                'direct_media_waiting_for_host')) ||
                                        (data1.direct_media_other_participants_audio_only ===
                                            undefined &&
                                            (missing1 =
                                                'direct_media_other_participants_audio_only')) ||
                                        (data1.direct_media_escalate ===
                                            undefined &&
                                            (missing1 =
                                                'direct_media_escalate')) ||
                                        (data1.direct_media_deescalate ===
                                            undefined &&
                                            (missing1 =
                                                'direct_media_deescalate'))
                                    ) {
                                        validate39.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/properties/result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs6 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 ===
                                                        'direct_media_welcome' ||
                                                    key1 ===
                                                        'direct_media_waiting_for_host' ||
                                                    key1 ===
                                                        'direct_media_other_participants_audio_only' ||
                                                    key1 ===
                                                        'direct_media_escalate' ||
                                                    key1 ===
                                                        'direct_media_deescalate'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs6 === errors) {
                                            if (
                                                data1.direct_media_welcome !==
                                                undefined
                                            ) {
                                                const _errs7 = errors;
                                                if (
                                                    !validate40(
                                                        data1.direct_media_welcome,
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/direct_media_welcome',
                                                            parentData: data1,
                                                            parentDataProperty:
                                                                'direct_media_welcome',
                                                            rootData,
                                                        },
                                                    )
                                                ) {
                                                    vErrors =
                                                        vErrors === null
                                                            ? validate40.errors
                                                            : vErrors.concat(
                                                                  validate40.errors,
                                                              );
                                                    errors = vErrors.length;
                                                }
                                                var valid1 = _errs7 === errors;
                                            } else {
                                                var valid1 = true;
                                            }
                                            if (valid1) {
                                                if (
                                                    data1.direct_media_waiting_for_host !==
                                                    undefined
                                                ) {
                                                    const _errs8 = errors;
                                                    if (
                                                        !validate40(
                                                            data1.direct_media_waiting_for_host,
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/direct_media_waiting_for_host',
                                                                parentData:
                                                                    data1,
                                                                parentDataProperty:
                                                                    'direct_media_waiting_for_host',
                                                                rootData,
                                                            },
                                                        )
                                                    ) {
                                                        vErrors =
                                                            vErrors === null
                                                                ? validate40.errors
                                                                : vErrors.concat(
                                                                      validate40.errors,
                                                                  );
                                                        errors = vErrors.length;
                                                    }
                                                    var valid1 =
                                                        _errs8 === errors;
                                                } else {
                                                    var valid1 = true;
                                                }
                                                if (valid1) {
                                                    if (
                                                        data1.direct_media_other_participants_audio_only !==
                                                        undefined
                                                    ) {
                                                        const _errs9 = errors;
                                                        if (
                                                            !validate40(
                                                                data1.direct_media_other_participants_audio_only,
                                                                {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/result/direct_media_other_participants_audio_only',
                                                                    parentData:
                                                                        data1,
                                                                    parentDataProperty:
                                                                        'direct_media_other_participants_audio_only',
                                                                    rootData,
                                                                },
                                                            )
                                                        ) {
                                                            vErrors =
                                                                vErrors === null
                                                                    ? validate40.errors
                                                                    : vErrors.concat(
                                                                          validate40.errors,
                                                                      );
                                                            errors =
                                                                vErrors.length;
                                                        }
                                                        var valid1 =
                                                            _errs9 === errors;
                                                    } else {
                                                        var valid1 = true;
                                                    }
                                                    if (valid1) {
                                                        if (
                                                            data1.direct_media_escalate !==
                                                            undefined
                                                        ) {
                                                            const _errs10 =
                                                                errors;
                                                            if (
                                                                !validate40(
                                                                    data1.direct_media_escalate,
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/result/direct_media_escalate',
                                                                        parentData:
                                                                            data1,
                                                                        parentDataProperty:
                                                                            'direct_media_escalate',
                                                                        rootData,
                                                                    },
                                                                )
                                                            ) {
                                                                vErrors =
                                                                    vErrors ===
                                                                    null
                                                                        ? validate40.errors
                                                                        : vErrors.concat(
                                                                              validate40.errors,
                                                                          );
                                                                errors =
                                                                    vErrors.length;
                                                            }
                                                            var valid1 =
                                                                _errs10 ===
                                                                errors;
                                                        } else {
                                                            var valid1 = true;
                                                        }
                                                        if (valid1) {
                                                            if (
                                                                data1.direct_media_deescalate !==
                                                                undefined
                                                            ) {
                                                                const _errs11 =
                                                                    errors;
                                                                if (
                                                                    !validate40(
                                                                        data1.direct_media_deescalate,
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/result/direct_media_deescalate',
                                                                            parentData:
                                                                                data1,
                                                                            parentDataProperty:
                                                                                'direct_media_deescalate',
                                                                            rootData,
                                                                        },
                                                                    )
                                                                ) {
                                                                    vErrors =
                                                                        vErrors ===
                                                                        null
                                                                            ? validate40.errors
                                                                            : vErrors.concat(
                                                                                  validate40.errors,
                                                                              );
                                                                    errors =
                                                                        vErrors.length;
                                                                }
                                                                var valid1 =
                                                                    _errs11 ===
                                                                    errors;
                                                            } else {
                                                                var valid1 =
                                                                    true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate39.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/properties/result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate39.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate39.errors = vErrors;
    return errors === 0;
}
function validate38(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate39(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate39.errors
                : vErrors.concat(validate39.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate38.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate38.errors = vErrors;
    return errors === 0;
}
export const validateTheme204 = validate47;
const schema56 = {type: 'string'};
function validate47(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate47.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate47.errors = vErrors;
    return errors === 0;
}
export const validateAvailableLayouts200 = validate48;
const schema57 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'array',
                    description: 'Layout keys',
                    items: {type: 'string'},
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/available_layouts/properties/200/definitions/200',
        },
    ],
};
const schema58 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'array',
            description: 'Layout keys',
            items: {type: 'string'},
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate48(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/available_layouts/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/available_layouts/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/available_layouts/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema58.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (errors === _errs7) {
                                if (Array.isArray(data1)) {
                                    var valid3 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        const _errs9 = errors;
                                        if (typeof data1[i0] !== 'string') {
                                            const err3 = {
                                                instancePath:
                                                    instancePath +
                                                    '/result/' +
                                                    i0,
                                                schemaPath:
                                                    '#/definitions/available_layouts/properties/200/definitions/200/properties/result/items/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err3];
                                            } else {
                                                vErrors.push(err3);
                                            }
                                            errors++;
                                        }
                                        var valid3 = _errs9 === errors;
                                        if (!valid3) {
                                            break;
                                        }
                                    }
                                } else {
                                    const err4 = {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/definitions/available_layouts/properties/200/definitions/200/properties/result/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/available_layouts/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate48.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate48.errors = vErrors;
    return errors === 0;
}
export const validateLayoutSvgs200 = validate49;
const schema59 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/layout_svgs/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: {type: 'string'},
            title: 'Result',
        },
    },
    oneOf: [{$ref: '#/definitions/layout_svgs/properties/200/definitions/200'}],
};
const schema60 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/layout_svgs/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema61 = {
    type: 'object',
    additionalProperties: {type: 'string'},
    title: 'Result',
};
function validate50(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate50.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate50.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate50.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema60.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    const _errs7 = errors;
                                    for (const key1 in data1) {
                                        const _errs8 = errors;
                                        if (typeof data1[key1] !== 'string') {
                                            const err0 = {};
                                            if (vErrors === null) {
                                                vErrors = [err0];
                                            } else {
                                                vErrors.push(err0);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs8 === errors;
                                        if (!valid2) {
                                            errors = _errs7;
                                            if (vErrors !== null) {
                                                if (_errs7) {
                                                    vErrors.length = _errs7;
                                                } else {
                                                    vErrors = null;
                                                }
                                            }
                                            delete data1[key1];
                                        }
                                    }
                                } else {
                                    validate50.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/layout_svgs/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate50.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate50.errors = vErrors;
    return errors === 0;
}
function validate49(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate50(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate50.errors
                : vErrors.concat(validate50.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate49.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate49.errors = vErrors;
    return errors === 0;
}
export const validateGuestBreakoutBuzz200 = validate52;
function validate52(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate52.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate52.errors = vErrors;
    return errors === 0;
}
export const validateGuestClearBreakoutBuzz200 = validate53;
function validate53(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/lock/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/lock/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/lock/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/lock/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate53.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate53.errors = vErrors;
    return errors === 0;
}
