import React from 'react';
import {useTranslation} from 'react-i18next';

import {ConfirmationModal} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const OpenBreakoutRoomsErrorModal: React.FC<{
    isOpen: boolean;
    onConfirm: () => void;
}> = ({isOpen, onConfirm}) => {
    const {t} = useTranslation();

    return (
        <ConfirmationModal
            data-testid={TestId.ModalOpenRoomsError}
            isOpen={isOpen}
            onConfirm={onConfirm}
            confirmButtonText={t('common.ok', 'Ok')}
            title={t(
                'breakouts.error-on-open-rooms-title',
                'Could not open rooms',
            )}
            description={t(
                'breakouts.error-on-open-rooms-description',
                'An error occurred while trying to open the breakout rooms. Please try again.',
            )}
        />
    );
};
